/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground29(props) {
  const { nodes, materials } = useGLTF("/espressofincani.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(FBF8A060-AF32-4605-933C-FF848966B789)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(FB97E978-B7A2-4B76-8C4E-E85E909EE72E)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(EBDFFBB6-7808-42A7-A452-AA574AFE274D)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(E54DB15E-DA9F-4FF1-8ACE-1A0E7DBE26A8)"].geometry
        }
        material={materials["Material_0.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(E4EEBA07-728B-43B2-9005-9C7F70F66E10)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(C29830BB-F6D3-47E1-89FF-1626FE1B9E80)"].geometry
        }
        material={materials["Material_0.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(AD389807-0C82-45AE-8F3C-B7F1C0CC1706)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(AD019230-86E6-4880-9544-670C0ADFC86F)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(AC392326-24B7-4CDF-AFB6-75F45FDBA22A)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(A7A79B02-432A-4C58-B17B-2BFBBE8F26A9)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(9606E050-4312-43F9-9B53-B0B198ED5A82)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(8E0D7521-64FD-4633-BD97-BDD992EC6A31)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(788DE2F7-B001-430C-8E15-3BD8FBF3CF23)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(73A9EDA0-F4A1-49B3-BAF8-4813B4BA6C4B)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(5E8A4176-837F-43B3-A441-356DDCC2B0A3)"].geometry
        }
        material={materials["Material_0.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(4DFF19CD-5907-4125-916E-FA315969D1CD)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(46C69640-D38D-4516-8465-EC7A475AE475)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(3BE66449-C659-4118-BFD5-E79485771D5D)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(36848AF2-7DE8-461B-BD55-30D7F0C9D198)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(2D1DAB18-8AD7-4B66-9456-52385A01D6A2)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(125B08C2-510E-4CC9-9FE7-687FCAD5B200)"].geometry
        }
        material={materials["Material_0.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(06960B09-56AB-43E5-BFAA-40DCF3CBE759)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(0089BBA0-8C36-4BF3-A38F-36F40E86829E)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus35(FACAC5F0-6B61-43B2-A3DD-A244CC1E6F5F)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus35(D5EC7BD8-601E-4F23-941C-077F15EEA362)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus35(B0940EDD-491D-4B05-982D-AF0E026D50A7)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus35(AB7345DE-675E-4FAD-BF53-0DB75D8F7062)"].geometry
        }
        material={materials["Material_1.006"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus35(A63740F6-03DA-4E36-8D93-3BE7F58F48A1)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus35(79637D63-EB5A-4735-BB96-5CD915B0EAEA)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus35(66D1FB07-8E30-435E-9F93-0918D7924C79)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus35(37DA0172-C47D-45E8-B10F-1536277B706F)"].geometry
        }
        material={materials["Material_2.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus35(096D63B7-FA0C-4386-A79C-B47DA28AD5ED)"].geometry
        }
        material={materials["Material_0.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(5528B6C3-E19D-49F4-B809-0A39B92CC40A)"].geometry
        }
        material={materials.Material_3}
      />
    </group>
  );
}

useGLTF.preload("/espressofincani.glb");
