/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground23(props) {
  const { nodes, materials } = useGLTF("/ymt4.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(BF3F56DB-D8DC-40E6-82A3-E0BC33EFF7A3)"].geometry
        }
        material={materials["Material_2.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(93CF2658-8FFD-4E48-9F93-EE0B5FE17CFC)"].geometry
        }
        material={materials["Material_2.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(861AD22C-BFC6-49A6-8AFC-8AFC11001164)"].geometry
        }
        material={materials["Material_2.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(3D377015-252C-405D-B796-1DFCBE535FC6)"].geometry
        }
        material={materials["Material_2.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(2A3446B2-9F4F-41C9-A858-335F7EDFB856)"].geometry
        }
        material={materials["Material_1.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(29A8C729-4E31-4813-9277-2C112A19AD49)"].geometry
        }
        material={materials["Material_1.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(1EDC35C8-539C-4155-9FC2-E21B46A90344)"].geometry
        }
        material={materials["Material_2.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(8831D603-C5A7-4D28-B347-3B3A3B4A305B)"].geometry
        }
        material={materials["Material_2.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(5AFB18C3-BFDF-4CDE-8D7B-6DA06C9E9F08)"].geometry
        }
        material={materials["Material_1.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(4F71803A-9B62-47C3-B965-91C40DB73155)"].geometry
        }
        material={materials["Material_1.003"]}
      />
    </group>
  );
}

useGLTF.preload("/ymt4.glb");
