/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground28(props) {
  const { nodes, materials } = useGLTF("/kahvefincani1.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(F8253F50-7AE9-467C-99D2-8FECA9DCED42)"].geometry
        }
        material={materials["Material_0.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(E8CFAF35-E647-4C56-8E01-9E6CDC1396E7)"].geometry
        }
        material={materials["Material_0.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(DD178EFF-D85A-425A-B081-87A073FF8D52)"].geometry
        }
        material={materials["Material_2.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(BE922FCD-8299-45A8-BD44-97EAB486A23F)"].geometry
        }
        material={materials["Material_2.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(7FC52394-636C-4563-979D-A101176771C0)"].geometry
        }
        material={materials["Material_0.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(47AF48AA-C262-41EB-AF4A-C09726885DC5)"].geometry
        }
        material={materials["Material_1.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(26FEBC4E-00D4-4C00-8272-4BCC1ADC1FAC)"].geometry
        }
        material={materials["Material_0.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(ECDB8127-6AC9-441F-A173-5CA0054E4D61)"].geometry
        }
        material={materials["Material_2.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(8D19357D-71F1-4612-8CE2-EA04489A116F)"].geometry
        }
        material={materials["Material_3.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(6FC691A8-C268-4DBA-9AB7-3EFEF49EFC2A)"].geometry
        }
        material={materials["Material_2.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(3191B63A-F082-45BA-A3BB-0DDE509A3EA3)"].geometry
        }
        material={materials["Material_0.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(48380A9D-9100-4285-B715-43E5DC0BCD66)"].geometry
        }
        material={materials.Material_6}
      />
    </group>
  );
}

useGLTF.preload("/kahvefincani1.glb");
