/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground14(props) {
  const { nodes, materials } = useGLTF("/ArafYmt3.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus19(D4D2E71E-2C63-4591-8757-0557EB545387)"].geometry
        }
        material={materials["Material_1.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus19(3F6E7552-899B-4F84-9CE4-D615D472547D)"].geometry
        }
        material={materials["Material_4.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(6806B6E1-DCB5-45BD-A06B-36FD9EACCFF9)001"].geometry
        }
        material={materials["Material_0.005"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(E3FD0777-8952-44D2-8D91-8B6A4F7591CD)"].geometry
        }
        material={materials["Material_3.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(7FF5A936-8CC9-47BC-B243-70D16DE93985)"].geometry
        }
        material={materials["Material_1.004"]}
      />
    </group>
  );
}

useGLTF.preload("/ArafYmt3.glb");
