/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground13(props) {
  const { nodes, materials } = useGLTF("/ArafYmt4.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet3(BBE353C3-F535-4CDE-9357-A4DA2B90D894)"].geometry
        }
        material={materials["Material_1.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet3(68ADBEE8-DCF7-4FCB-959E-B03F73083782)"].geometry
        }
        material={materials["Material_2.012"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(A0FF369F-1E56-45F1-9145-7E56B5788DA5)"].geometry
        }
        material={materials["Material_1.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(416885EF-8820-4C22-91AE-9E377B00D1CD)"].geometry
        }
        material={materials["Material_6.001"]}
      />
    </group>
  );
}

useGLTF.preload("/ArafYmt4.glb");
