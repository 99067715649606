/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground19(props) {
  const { nodes, materials } = useGLTF("/PastaCayTabagi3.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale3(FB01A8AD-279E-422A-8F95-87717B705875)"].geometry
        }
        material={materials["Material_1.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale3(DEEAE153-ED53-43BA-B7D1-41A8D509C3B3)"].geometry
        }
        material={materials["Material_2.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale3(C4621676-7C51-4B47-B839-AF9FF910E86A)"].geometry
        }
        material={materials["Material_2.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale3(AE2C33A6-D5EE-4D7C-947E-FD1909161064)"].geometry
        }
        material={materials["Material_2.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale3(A4469CDE-9649-439F-A8D9-FC1D70CBC41E)"].geometry
        }
        material={materials["Material_2.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale3(5A686447-90E8-418B-8636-D215D453F48A)"].geometry
        }
        material={materials["Material_1.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale3(56B50409-A818-47DC-97B5-A0BCF4F7FDC2)"].geometry
        }
        material={materials["Material_2.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale3(1C7FC603-CC88-4E3C-B9C6-E2F74FA84F09)"].geometry
        }
        material={materials["Material_2.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(54026FD8-4EA8-4511-9F7A-E9C4C5B95185)"].geometry
        }
        material={materials["Material_1.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(48380A9D-9100-4285-B715-43E5DC0BCD66)002"].geometry
        }
        material={materials["Material_0.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(07D018EB-6759-47E3-9A1D-CD77681E25B4)"].geometry
        }
        material={materials["Material_1.008"]}
      />
    </group>
  );
}

useGLTF.preload("/PastaCayTabagi3.glb");
