/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
  const { nodes, materials } = useGLTF("/AlanWeb.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Old_book007.geometry}
        material={materials["Old book"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Old_book006.geometry}
        material={materials["Old book.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Old_book005.geometry}
        material={materials["Old book.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Old_book004.geometry}
        material={materials["Old book.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Old_book003.geometry}
        material={materials["Old book"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Old_book002.geometry}
        material={materials["Old book.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Old_book.geometry}
        material={materials["Old book.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17021.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17019.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17017.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17016.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17015.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17014.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17013.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17012.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17011.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17010.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17009.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17008.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17007.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17006.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17005.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17004.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17003.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17002.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17001.geometry}
        material={materials["Material_2.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_17.geometry}
        material={materials["Material_2.011"]}
      />
      <group position={[0, 0, 0.1009]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004.geometry}
          material={materials.metall}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004_1.geometry}
          material={materials["black-glass"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004_2.geometry}
          material={materials["white-plastic"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004_3.geometry}
          material={materials["Material.005"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube032.geometry}
        material={materials["Material.029"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube031.geometry}
        material={materials["Material.029"]}
        position={[-3.5399, 0.1886, 3.8591]}
        rotation={[Math.PI, 0, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube029.geometry}
        material={materials["Material.029"]}
        position={[0.4591, 0.1886, 3.8591]}
        rotation={[Math.PI, 0, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube028.geometry}
        material={materials["Material.029"]}
        position={[4.4797, 0.1886, -3.8552]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube027.geometry}
        material={materials["Material.029"]}
        position={[0.482, 0.1886, -3.8552]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube026.geometry}
        material={materials["Material.029"]}
        position={[-3.517, 0.1886, -3.8552]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube025.geometry}
        material={materials["Material.029"]}
        position={[0, 0.002, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3021.geometry}
        material={materials.yer}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube104.geometry}
        material={materials["Wood.001"]}
        position={[7.6245, 0.5741, -0.8588]}
        scale={1.6086}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube033.geometry}
        material={materials["Wood.001"]}
        position={[7.6313, 1.0946, -1.1908]}
        scale={1.6086}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube034.geometry}
        material={materials["Wood.001"]}
        position={[7.626, 0.3204, -0.8595]}
        scale={1.6086}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube035.geometry}
        material={materials["Wood.001"]}
        position={[7.626, 1.0939, -1.1876]}
        scale={1.6086}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube036.geometry}
        material={materials["Wood.001"]}
        position={[7.626, 0.5741, -0.8654]}
        scale={1.6086}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube037.geometry}
        material={materials["Wood.001"]}
        position={[7.626, 0.245, -0.5565]}
        scale={1.6086}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube038.geometry}
        material={materials["Wood.001"]}
        position={[7.626, 0.7741, -1.1907]}
        scale={1.6086}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube039.geometry}
        material={materials["Wood.001"]}
        position={[7.626, 0.6574, -0.8565]}
        scale={1.6086}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SM_Frame_Ornate_01009.geometry}
        material={materials.lambert1}
        position={[9.3897, 2.5261, -0.0642]}
        rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        scale={1.7361}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane056.geometry}
        material={materials["Material.016"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003.geometry}
        material={materials["Material.002"]}
        position={[2.3333, 7.0428, -3.8054]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane008.geometry}
        material={materials["Material.002"]}
        position={[2.7749, 7.0428, -3.8054]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane009.geometry}
        material={materials["Material.002"]}
        position={[2.1595, 7.0428, -3.8054]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane010.geometry}
        material={materials["Material.002"]}
        position={[2.3333, 7.0428, -0.3517]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane011.geometry}
        material={materials["Material.002"]}
        position={[2.3333, 7.0428, 0.5572]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane012.geometry}
        material={materials["Material.002"]}
        position={[2.3333, 7.0428, 2.327]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane013.geometry}
        material={materials["Material.002"]}
        position={[2.3333, 7.0428, -2.0766]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane014.geometry}
        material={materials["Material.002"]}
        position={[-2.6772, 7.0428, -3.8054]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane015.geometry}
        material={materials["Material.002"]}
        position={[-2.2356, 7.0428, -3.8054]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane016.geometry}
        material={materials["Material.002"]}
        position={[-2.851, 7.0428, -3.8054]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane017.geometry}
        material={materials["Material.002"]}
        position={[-2.6772, 7.0428, -0.3517]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane018.geometry}
        material={materials["Material.002"]}
        position={[-2.6772, 7.0428, 0.5572]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane019.geometry}
        material={materials["Material.002"]}
        position={[-2.6772, 7.0428, 2.327]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane020.geometry}
        material={materials["Material.002"]}
        position={[-2.6772, 7.0428, -2.0766]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane021.geometry}
        material={materials["Material.002"]}
        position={[9.3682, 7.2951, -3.8093]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane022.geometry}
        material={materials["Material.002"]}
        position={[9.3682, 7.2951, -3.8093]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane023.geometry}
        material={materials["Material.002"]}
        position={[9.3682, 7.2951, -3.8093]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane024.geometry}
        material={materials["Material.002"]}
        position={[9.3682, 7.2951, -3.8093]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane025.geometry}
        material={materials["Material.002"]}
        position={[9.4053, 7.2951, -3.8093]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane026.geometry}
        material={materials["Material.002"]}
        position={[9.3678, 7.2951, -3.7819]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane027.geometry}
        material={materials["Material.002"]}
        position={[9.724, 7.2951, -3.8093]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane028.geometry}
        material={materials["Material.002"]}
        position={[9.3682, 7.2951, -3.7838]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane029.geometry}
        material={materials["Material.002"]}
        position={[9.4053, 7.2951, -3.8328]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001.geometry}
        material={materials["Material.002"]}
        position={[-3.032, 7.2951, -3.8093]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane030.geometry}
        material={materials["Material.002"]}
        position={[-3.032, 7.2951, -3.8093]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane031.geometry}
        material={materials["Material.002"]}
        position={[-3.032, 7.2951, -3.8093]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane032.geometry}
        material={materials["Material.002"]}
        position={[-3.032, 7.2951, -3.8093]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane033.geometry}
        material={materials["Material.002"]}
        position={[-2.9948, 7.2951, -3.8093]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane034.geometry}
        material={materials["Material.002"]}
        position={[-3.0323, 7.2951, -3.7819]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane035.geometry}
        material={materials["Material.002"]}
        position={[-2.6762, 7.2951, -3.8093]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane036.geometry}
        material={materials["Material.002"]}
        position={[-3.032, 7.2951, -3.7838]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane037.geometry}
        material={materials["Material.002"]}
        position={[-2.9948, 7.2951, -3.8328]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane038.geometry}
        material={materials["Material.002"]}
        position={[-2.2379, 7.2967, -3.8105]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane039.geometry}
        material={materials["Material.002"]}
        position={[-2.2379, 7.2967, -3.8105]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane040.geometry}
        material={materials["Material.002"]}
        position={[-2.2379, 7.2967, -3.8105]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane041.geometry}
        material={materials["Material.002"]}
        position={[-2.2379, 7.2967, -3.8105]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane042.geometry}
        material={materials["Material.002"]}
        position={[-2.2379, 7.2967, -3.8105]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane044.geometry}
        material={materials["Material.002"]}
        position={[-2.2379, 7.2967, -3.8105]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane046.geometry}
        material={materials["Material.002"]}
        position={[-2.2379, 7.2967, -3.8105]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane048.geometry}
        material={materials["Material.002"]}
        position={[-2.2379, 7.2967, -3.8105]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane050.geometry}
        material={materials["Material.002"]}
        position={[-2.2379, 7.2967, -3.8105]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane051.geometry}
        material={materials["Material.002"]}
        position={[9.5488, 7.4925, -3.7838]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane052.geometry}
        material={materials["Material.002"]}
        position={[9.5488, 7.4925, -3.7838]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane043.geometry}
        material={materials["Material.002"]}
        position={[9.5488, 7.4925, -3.7838]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane045.geometry}
        material={materials["Material.002"]}
        position={[9.5488, 7.4925, -3.7838]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane047.geometry}
        material={materials["Material.002"]}
        position={[-3.1745, 7.4981, -3.7879]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane049.geometry}
        material={materials["Material.002"]}
        position={[-3.1745, 7.4981, -3.7879]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane053.geometry}
        material={materials["Material.002"]}
        position={[-3.1745, 7.4981, -3.7879]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane054.geometry}
        material={materials["Material.002"]}
        position={[-3.1745, 7.4981, -3.7879]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.0866}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane081.geometry}
        material={materials["Material.014"]}
        position={[9.463, 5.8543, -1.6492]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.9934, 1, 0.2412]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane080.geometry}
        material={materials["Material.014"]}
        position={[9.463, 5.8543, 1.6571]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.9934, 1, 0.2412]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane079.geometry}
        material={materials["Material.014"]}
        position={[9.463, 5.8543, 3.5614]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.9934, 1, 0.2412]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane078.geometry}
        material={materials["Material.014"]}
        position={[9.1213, 5.8543, 3.8997]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={[0.9934, 1, 0.2412]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane077.geometry}
        material={materials["Material.014"]}
        position={[2.4634, 5.8543, 3.8997]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={[0.9934, 1, 0.2412]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane076.geometry}
        material={materials["Material.014"]}
        position={[-2.5455, 5.8543, 3.8997]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={[0.9934, 1, 0.2412]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane075.geometry}
        material={materials["Material.014"]}
        position={[-9.1761, 5.8543, 3.8997]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={[0.9934, 1, 0.2412]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane074.geometry}
        material={materials["Material.014"]}
        position={[-9.5273, 5.8543, 3.5532]}
        rotation={[Math.PI, 0, Math.PI / 2]}
        scale={[0.9934, 1, 0.2412]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane073.geometry}
        material={materials["Material.014"]}
        position={[-9.5273, 5.8543, -1.6388]}
        rotation={[Math.PI, 0, Math.PI / 2]}
        scale={[0.9934, 1, 0.2412]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane072.geometry}
        material={materials["Material.014"]}
        position={[-9.5273, 5.8543, 1.6627]}
        rotation={[Math.PI, 0, Math.PI / 2]}
        scale={[0.9934, 1, 0.2412]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane071.geometry}
        material={materials["Material.014"]}
        position={[-9.5273, 5.8543, -3.5577]}
        rotation={[Math.PI, 0, Math.PI / 2]}
        scale={[0.9934, 1, 0.2412]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane070.geometry}
        material={materials["Material.014"]}
        position={[-9.1751, 5.8543, -3.8956]}
        rotation={[-Math.PI / 2, 1.5705, 0]}
        scale={[0.9934, 1, 0.2412]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane069.geometry}
        material={materials["Material.014"]}
        position={[9.1344, 5.8543, -3.8956]}
        rotation={[-Math.PI / 2, 1.5705, 0]}
        scale={[0.9934, 1, 0.2412]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane068.geometry}
        material={materials["Material.014"]}
        position={[2.4778, 5.8543, -3.8956]}
        rotation={[-Math.PI / 2, 1.5705, 0]}
        scale={[0.9934, 1, 0.2412]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane067.geometry}
        material={materials["Material.014"]}
        position={[9.4506, 5.8543, -3.5441]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.9934, 1, 0.2412]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane066.geometry}
        material={materials["Material.014"]}
        position={[-2.5231, 5.8543, -3.8956]}
        rotation={[-Math.PI / 2, 1.5705, 0]}
        scale={[0.9934, 1, 0.2412]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3020.geometry}
        material={materials["Material.034"]}
        position={[-0.0771, 8.2367, 2.0101]}
        scale={0.0781}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3019.geometry}
        material={materials["Material.033"]}
        position={[-0.0644, 8.3306, 2.0099]}
        scale={0.7812}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3018.geometry}
        material={materials["Material.034"]}
        position={[-0.0771, 8.2367, -2.0107]}
        scale={0.0781}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3017.geometry}
        material={materials["Material.033"]}
        position={[-0.0644, 8.3306, -2.011]}
        scale={0.7812}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3016.geometry}
        material={materials["Material.034"]}
        position={[-6.134, 8.6964, -0.0058]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3015.geometry}
        material={materials["Material.033"]}
        position={[-6.1178, 8.8166, -0.0061]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3014.geometry}
        material={materials["Material.034"]}
        position={[2.5939, 4.1211, 0]}
        scale={0.1}
      />
      <group position={[-6.1274, 7.4797, -0.0017]} scale={1.0651}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3009_1.geometry}
          material={materials["Material.030"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3009_2.geometry}
          material={materials["Material.031"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3009_3.geometry}
          material={materials["Material.032"]}
        />
      </group>
      <group position={[-0.0333, 7.2852, 2.0026]} scale={0.7893}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3008_1.geometry}
          material={materials["Material.030"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3008_2.geometry}
          material={materials["Material.031"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3008_3.geometry}
          material={materials["Material.032"]}
        />
      </group>
      <group position={[-0.0333, 7.2852, -1.994]} scale={0.7893}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3005_1.geometry}
          material={materials["Material.030"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3005_2.geometry}
          material={materials["Material.031"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3005_3.geometry}
          material={materials["Material.032"]}
        />
      </group>
      <group position={[6.0634, 7.4797, -0.0017]} scale={1.0651}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3007_1.geometry}
          material={materials["Material.030"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3007_2.geometry}
          material={materials["Material.031"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3007_3.geometry}
          material={materials["Material.032"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3009.geometry}
        material={materials["Material.021"]}
        position={[-5.53, 1.6518, -4.7751]}
        scale={[1.0225, 1, 1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3008.geometry}
        material={materials["Material.033"]}
        position={[2.583, 4.0041, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3007.geometry}
        material={materials["Material.020"]}
        position={[-5.5347, 1.6518, 4.7698]}
        scale={[1.0225, 1, 1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3006.geometry}
        material={materials["Material.024"]}
        position={[6.4662, 1.6518, -4.7751]}
        scale={[1.0225, 1, 1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3005.geometry}
        material={materials["Material.023"]}
        position={[2.4717, 1.6518, -4.7751]}
        scale={[1.0225, 1, 1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3004.geometry}
        material={materials["Material.022"]}
        position={[-1.532, 1.6518, -4.7751]}
        scale={[1.0225, 1, 1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3003.geometry}
        material={materials["Material.019"]}
        position={[-1.5353, 1.6518, 4.7698]}
        scale={[1.0225, 1, 1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3002.geometry}
        material={materials["Material.018"]}
        position={[2.4646, 1.6518, 4.7698]}
        scale={[1.0225, 1, 1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3001.geometry}
        material={materials["Material.015"]}
        position={[6.4646, 1.6518, 4.7698]}
        scale={[1.0225, 1, 1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.IMG_0770004.geometry}
        material={materials["Material.035"]}
        position={[9.4604, 7.0988, 3.741]}
        rotation={[Math.PI / 2, 0, -1.5706]}
        scale={3.7311}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.IMG_0770003.geometry}
        material={materials["Material.035"]}
        position={[9.3259, 7.0956, -3.8892]}
        rotation={[Math.PI / 2, 0, 0.0002]}
        scale={3.7311}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.IMG_0770002.geometry}
        material={materials["Material.035"]}
        position={[-9.5327, 7.0988, 3.741]}
        rotation={[Math.PI / 2, 0, -1.5706]}
        scale={3.7311}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.IMG_0770001.geometry}
        material={materials["Material.035"]}
        position={[9.3259, 7.0956, 3.9012]}
        rotation={[Math.PI / 2, 0, 0.0002]}
        scale={3.7311}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.IMG_0752010.geometry}
        material={materials["Material.030"]}
        position={[-2.5439, 7.0491, -2.9818]}
        rotation={[0, 0, -Math.PI]}
        scale={23.8277}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.IMG_0752009.geometry}
        material={materials["Material.030"]}
        position={[-2.5439, 7.0491, 3.1086]}
        rotation={[0, 0, -Math.PI]}
        scale={23.8277}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.IMG_0752008.geometry}
        material={materials["Material.030"]}
        position={[-2.5439, 7.0491, 1.4656]}
        rotation={[0, 0, -Math.PI]}
        scale={23.8277}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.IMG_0752007.geometry}
        material={materials["Material.030"]}
        position={[-2.5439, 7.0491, -1.2278]}
        rotation={[0, 0, -Math.PI]}
        scale={23.8277}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.IMG_0752006.geometry}
        material={materials["Material.030"]}
        position={[2.461, 7.038, -2.9818]}
        rotation={[0, 0, -Math.PI]}
        scale={23.8277}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.IMG_0752005.geometry}
        material={materials["Material.030"]}
        position={[2.461, 7.038, 3.1086]}
        rotation={[0, 0, -Math.PI]}
        scale={23.8277}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.IMG_0752004.geometry}
        material={materials["Material.030"]}
        position={[2.461, 7.038, 1.4656]}
        rotation={[0, 0, -Math.PI]}
        scale={23.8277}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.IMG_0752003.geometry}
        material={materials["Material.030"]}
        position={[2.461, 7.038, -1.2278]}
        rotation={[0, 0, -Math.PI]}
        scale={23.8277}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.IMG_0752002.geometry}
        material={materials["Material.030"]}
        position={[-1.2987, 7.299, 0.0069]}
        rotation={[-Math.PI, 0.0342, 0]}
        scale={21.4095}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.IMG_0752001.geometry}
        material={materials["Material.030"]}
        position={[1.2376, 7.299, 0.0069]}
        rotation={[0, -0.0342, -Math.PI]}
        scale={21.4095}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube030.geometry}
        material={materials["Material.029"]}
        position={[4.4568, 0.1886, 3.8591]}
        rotation={[Math.PI, 0, Math.PI]}
      />
      <group position={[1.0434, 5.7643, 4.0915]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube003_1.geometry}
          material={materials.vernished}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube003_2.geometry}
          material={materials["Material.011"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube003_3.geometry}
          material={materials["Material.013"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6048"].geometry}
        material={materials["Material.030"]}
        position={[-7.7673, 6.6882, 3.9926]}
        rotation={[-1.8375, -1.4939, -0.2674]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6047"].geometry}
        material={materials["Material.030"]}
        position={[-8.6859, 6.6834, 3.9867]}
        rotation={[1.5692, 0.0762, -3.1212]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6046"].geometry}
        material={materials["Material.030"]}
        position={[-5.3714, 6.6882, 3.9924]}
        rotation={[0, -1.5692, 1.5709]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6045"].geometry}
        material={materials["Material.030"]}
        position={[-6.8459, 6.6882, 3.9949]}
        rotation={[Math.PI / 2, 0, 3.1399]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6044"].geometry}
        material={materials["Material.030"]}
        position={[-3.2282, 6.6882, 3.9887]}
        rotation={[0, -1.5692, 1.5709]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6043"].geometry}
        material={materials["Material.030"]}
        position={[-4.7027, 6.6882, 3.9912]}
        rotation={[Math.PI / 2, 0, 3.1399]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6042"].geometry}
        material={materials["Material.030"]}
        position={[8.6214, 6.6882, 3.9792]}
        rotation={[-1.5478, -1.4967, 0.0231]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6041"].geometry}
        material={materials["Material.030"]}
        position={[7.7028, 6.6834, 3.9936]}
        rotation={[1.5709, 0.0762, 3.1399]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6040"].geometry}
        material={materials["Material.030"]}
        position={[6.7633, 6.6882, 3.9716]}
        rotation={[0, -1.5692, 1.5709]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6039"].geometry}
        material={materials["Material.030"]}
        position={[5.2888, 6.6882, 3.9741]}
        rotation={[Math.PI / 2, 0, 3.1399]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6038"].geometry}
        material={materials["Material.030"]}
        position={[4.6228, 6.6882, 3.9752]}
        rotation={[0, -1.5692, 1.5709]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6037"].geometry}
        material={materials["Material.030"]}
        position={[3.1483, 6.6882, 3.9778]}
        rotation={[Math.PI / 2, 0, 3.1399]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6036"].geometry}
        material={materials["Material.030"]}
        position={[1.7793, 6.6882, 3.9801]}
        rotation={[0, -1.5692, 1.5709]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6035"].geometry}
        material={materials["Material.030"]}
        position={[0.3048, 6.6882, 3.9827]}
        rotation={[Math.PI / 2, 0, 3.1399]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6034"].geometry}
        material={materials["Material.030"]}
        position={[-0.3714, 6.6882, 3.9838]}
        rotation={[0, -1.5692, 1.5709]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6033"].geometry}
        material={materials["Material.030"]}
        position={[-1.8458, 6.6882, 3.9863]}
        rotation={[Math.PI / 2, 0, 3.1399]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6032"].geometry}
        material={materials["Material.030"]}
        position={[-9.6128, 6.6918, -2.1307]}
        rotation={[-3.0675, 0, -Math.PI / 2]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6031"].geometry}
        material={materials["Material.030"]}
        position={[-9.6256, 6.687, -3.0493]}
        rotation={[1.4946, 0, -Math.PI / 2]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6030"].geometry}
        material={materials["Material.030"]}
        position={[-9.6128, 6.6918, -0.2278]}
        rotation={[-3.0675, 0, -Math.PI / 2]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6029"].geometry}
        material={materials["Material.030"]}
        position={[-9.6256, 6.687, -1.1464]}
        rotation={[1.4946, 0, -Math.PI / 2]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6028"].geometry}
        material={materials["Material.030"]}
        position={[-9.6128, 6.6918, 1.1673]}
        rotation={[-3.0675, 0, -Math.PI / 2]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6027"].geometry}
        material={materials["Material.030"]}
        position={[-9.6256, 6.687, 0.2488]}
        rotation={[1.4946, 0, -Math.PI / 2]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6026"].geometry}
        material={materials["Material.030"]}
        position={[-9.6128, 6.6918, 3.0623]}
        rotation={[-3.0675, 0, -Math.PI / 2]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6025"].geometry}
        material={materials["Material.030"]}
        position={[-9.6256, 6.687, 2.1437]}
        rotation={[1.4946, 0, -Math.PI / 2]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6024"].geometry}
        material={materials["Material.030"]}
        position={[9.5578, 6.687, 2.1477]}
        rotation={[-0.0741, 0, Math.PI / 2]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6023"].geometry}
        material={materials["Material.030"]}
        position={[9.545, 6.6918, 3.0662]}
        rotation={[1.647, 0, Math.PI / 2]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6022"].geometry}
        material={materials["Material.030"]}
        position={[9.5578, 6.687, 0.2448]}
        rotation={[-0.0741, 0, Math.PI / 2]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6021"].geometry}
        material={materials["Material.030"]}
        position={[9.545, 6.6918, 1.1633]}
        rotation={[1.647, 0, Math.PI / 2]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6020"].geometry}
        material={materials["Material.030"]}
        position={[9.5578, 6.687, -1.1503]}
        rotation={[-0.0741, 0, Math.PI / 2]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6019"].geometry}
        material={materials["Material.030"]}
        position={[9.545, 6.6918, -0.2318]}
        rotation={[1.647, 0, Math.PI / 2]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6018"].geometry}
        material={materials["Material.030"]}
        position={[9.5578, 6.687, -3.0453]}
        rotation={[-0.0741, 0, Math.PI / 2]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6017"].geometry}
        material={materials["Material.030"]}
        position={[9.545, 6.6918, -2.1268]}
        rotation={[1.647, 0, Math.PI / 2]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6016"].geometry}
        material={materials["Material.030"]}
        position={[7.7093, 6.687, -3.9732]}
        rotation={[-Math.PI / 2, 1.4967, Math.PI]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6015"].geometry}
        material={materials["Material.030"]}
        position={[8.6278, 6.6918, -3.9604]}
        rotation={[Math.PI / 2, -0.0762, 0]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6014"].geometry}
        material={materials["Material.030"]}
        position={[5.3133, 6.687, -3.984]}
        rotation={[Math.PI / 2, Math.PI / 2, 0]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6013"].geometry}
        material={materials["Material.030"]}
        position={[6.7878, 6.687, -3.984]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6012"].geometry}
        material={materials["Material.030"]}
        position={[3.1702, 6.687, -3.984]}
        rotation={[Math.PI / 2, Math.PI / 2, 0]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6011"].geometry}
        material={materials["Material.030"]}
        position={[4.6446, 6.687, -3.984]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6010"].geometry}
        material={materials["Material.030"]}
        position={[-8.6978, 6.687, -3.9732]}
        rotation={[-Math.PI / 2, 1.4967, Math.PI]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6009"].geometry}
        material={materials["Material.030"]}
        position={[-7.7793, 6.6918, -3.9604]}
        rotation={[Math.PI / 2, -0.0762, 0]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6008"].geometry}
        material={materials["Material.030"]}
        position={[-6.8438, 6.687, -3.984]}
        rotation={[Math.PI / 2, Math.PI / 2, 0]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6007"].geometry}
        material={materials["Material.030"]}
        position={[-5.3693, 6.687, -3.984]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6006"].geometry}
        material={materials["Material.030"]}
        position={[-4.7013, 6.687, -3.984]}
        rotation={[Math.PI / 2, Math.PI / 2, 0]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6005"].geometry}
        material={materials["Material.030"]}
        position={[-3.2269, 6.687, -3.984]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6004"].geometry}
        material={materials["Material.030"]}
        position={[-1.8374, 6.687, -3.984]}
        rotation={[Math.PI / 2, Math.PI / 2, 0]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6003"].geometry}
        material={materials["Material.030"]}
        position={[-0.3629, 6.687, -3.984]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6002"].geometry}
        material={materials["Material.030"]}
        position={[0.3133, 6.687, -3.984]}
        rotation={[Math.PI / 2, Math.PI / 2, 0]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_6001"].geometry}
        material={materials["Material.030"]}
        position={[1.7878, 6.687, -3.984]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.0356}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_10008"].geometry}
        material={materials["Material.030"]}
        position={[-4.248, 7.4827, 2.5904]}
        rotation={[0, 0.7926, Math.PI]}
        scale={0.0762}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_10007"].geometry}
        material={materials["Material.030"]}
        position={[-8.2165, 7.4827, 2.5904]}
        rotation={[0, -0.7782, -Math.PI]}
        scale={0.0762}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_10006"].geometry}
        material={materials["Material.030"]}
        position={[-4.2248, 7.4827, -2.5339]}
        rotation={[Math.PI, 0.7782, 0]}
        scale={0.0762}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_10005"].geometry}
        material={materials["Material.030"]}
        position={[-8.2165, 7.4827, -2.5339]}
        rotation={[Math.PI, -0.7926, 0]}
        scale={0.0762}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_10004"].geometry}
        material={materials["Material.030"]}
        position={[8.1501, 7.4827, 2.5904]}
        rotation={[0, 0.7926, Math.PI]}
        scale={0.0762}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_10003"].geometry}
        material={materials["Material.030"]}
        position={[4.1817, 7.4827, 2.5904]}
        rotation={[0, -0.7782, -Math.PI]}
        scale={0.0762}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_10002"].geometry}
        material={materials["Material.030"]}
        position={[8.1733, 7.4827, -2.5339]}
        rotation={[Math.PI, 0.7782, 0]}
        scale={0.0762}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2_10001"].geometry}
        material={materials["Material.030"]}
        position={[4.1817, 7.4827, -2.5339]}
        rotation={[Math.PI, -0.7926, 0]}
        scale={0.0762}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane060.geometry}
        material={materials["Material.043"]}
        position={[9.3897, 2.5223, -0.0642]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.7755, 1, 1.1455]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve048.geometry}
        material={materials["Material.028"]}
        position={[-9.5104, 2.2167, -0.0014]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={19.4886}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane_1.geometry}
        material={materials["Material.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane_2.geometry}
        material={materials["Material.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003_1.geometry}
        material={materials.metall}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003_2.geometry}
        material={materials["black-glass"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003_3.geometry}
        material={materials["white-plastic"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003_4.geometry}
        material={materials["Material.005"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3_1.geometry}
        material={materials["Material.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3_2.geometry}
        material={materials["Material.010"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3_3.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_3_4.geometry}
        material={materials["Material.026"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001_1.geometry}
        material={materials["Material.026"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001_2.geometry}
        material={materials["Material.027"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube040.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube040_1.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube040_2.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube024_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube024_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube024_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube023_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube023_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube023_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube022_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube022_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube022_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube021_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube021_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube021_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube020_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube020_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube020_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube019_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube019_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube019_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube018_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube018_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube018_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube017_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube017_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube017_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube016_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube016_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube016_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube015_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube015_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube015_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube014_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube014_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube014_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube013_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube013_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube013_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube012_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube012_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube012_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube011_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube011_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube011_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube010_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube010_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube010_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube009_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube009_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube009_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube008_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube008_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube008_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube007_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube007_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube007_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube006_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube006_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube006_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube005_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube005_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube005_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube004_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube004_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube004_3.geometry}
        material={materials["Material.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube002_1.geometry}
        material={materials.vernished}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube002_2.geometry}
        material={materials["Material.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube002_3.geometry}
        material={materials["Material.013"]}
      />
    </group>
  );
}

useGLTF.preload("/AlanWeb.glb");
