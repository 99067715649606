/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground36(props) {
  const { nodes, materials } = useGLTF("/tablo4.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[-5.9675, 0, -0.8957]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh003.geometry}
          material={materials.lambert1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh003_1.geometry}
          material={materials["Material.006"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh003_2.geometry}
          material={materials["Material.007"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh003_3.geometry}
          material={materials["Material_2.015"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane055.geometry}
        material={materials["Material.039"]}
        position={[-5.9675, 0, -0.8957]}
      />
    </group>
  );
}

useGLTF.preload("/tablo4.glb");
