/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground37(props) {
  const { nodes, materials } = useGLTF("/tablo5.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[-1.0813, 0, 0.9069]} rotation={[Math.PI, 0, Math.PI]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh007.geometry}
          material={materials.lambert1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh007_1.geometry}
          material={materials["Material.006"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh007_2.geometry}
          material={materials["Material.007"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh007_3.geometry}
          material={materials["Material_2.015"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane063.geometry}
        material={materials["Material.040"]}
        position={[-1.0813, 0, 0.9069]}
        rotation={[Math.PI, 0, Math.PI]}
      />
    </group>
  );
}

useGLTF.preload("/tablo5.glb");
