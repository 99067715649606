/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground33(props) {
  const { nodes, materials } = useGLTF("/tablo1.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[6.0192, 0, -0.8957]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh004.geometry}
          material={materials.lambert1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh004_1.geometry}
          material={materials["Material.006"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh004_2.geometry}
          material={materials["Material.007"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh004_3.geometry}
          material={materials["Material_2.015"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane057.geometry}
        material={materials["Material.036"]}
        position={[6.0192, 0, -0.8957]}
      />
    </group>
  );
}

useGLTF.preload("/tablo1.glb");
