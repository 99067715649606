/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground7(props) {
  const { nodes, materials } = useGLTF("/Pecetetutacagi.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(14BA5B16-5D6E-4905-BDE6-242D8857638C)002"]
            .geometry
        }
        material={materials["Material_0.001"]}
        position={[6.0553, 1.0887, -3.7107]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(CC2AF9DA-7496-4ED5-9D18-AFF808681409)002"]
            .geometry
        }
        material={materials["Material_0.001"]}
        position={[6.0512, 1.0705, -3.7106]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(7EB5019B-1A7C-4CC9-964E-C164A9B9861E)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0608, 1.0492, -3.72]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(FA107E5A-B531-4D91-953C-388206EBC0BB)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0758, 1.0638, -3.7073]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D609A419-5933-4474-B641-827720B0A2EC)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0762, 1.0734, -3.7073]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B6F310F2-EB80-40D9-9AB9-43C40A773384)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.072, 1.0843, -3.7123]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(05F4DB81-3756-416E-B1C9-701AB835D990)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0642, 1.0891, -3.721]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(962EC7F4-D031-4CDE-9F95-BD193119D92D)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0557, 1.0858, -3.7299]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B2A7393C-F71F-4DD8-93A1-52311D1788E1)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0501, 1.0759, -3.7354]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(A196D3D7-A7F7-4F23-B35B-900CDC3B56A8)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0496, 1.0632, -3.7353]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(8B2DDB96-7CFC-4BBF-8C1D-84C2196155BE)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.075, 1.0608, -3.708]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(2F4B40A9-98C8-4385-BD62-E2CBC4BF1058)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0764, 1.0702, -3.7069]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D45095F1-06E5-49A3-8A6D-B0776DA2F0A3)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0735, 1.0821, -3.7106]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(3A3B53F8-B189-4C43-A47C-0DB81141AA03)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0704, 1.0862, -3.7142]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(EF524E53-8CE2-4566-A783-B5E1E7449EC6)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0538, 1.0838, -3.7317]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(01A7C2C6-0E02-4026-80EC-0B61FE3A2BF3)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.051, 1.0788, -3.7345]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(89076BF7-30B9-4D60-96D5-1C86554F27EF)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0495, 1.0728, -3.7359]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(AB0A3E69-2CDA-46B7-A081-A0743A702112)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0492, 1.0663, -3.7358]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B43AEB5B-3FB8-48CE-B83C-9718447038CA)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0492, 1.0696, -3.7361]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(ED1AD1B3-2A8A-475D-AE66-62D5AD0C9320)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0524, 1.0815, -3.7332]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(AEE6BF9F-B7EE-486D-8CE1-16F6DD406587)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0597, 1.0885, -3.7257]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(5F26BA99-B420-485E-9969-C283AEF0DE30)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0747, 1.0794, -3.7092]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(67AE50A5-4C45-4CD3-A32C-364F57D4E4CD)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0531, 1.0538, -3.7298]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(A07DCE2E-CF27-4AD1-B6D0-69BFD6EDD7FF)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0606, 1.0489, -3.7197]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(79609B09-7764-46DD-B657-4436AE025D08)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0763, 1.0671, -3.7069]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(3BCC8717-D9FB-4009-BECA-832C8017B1AB)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0756, 1.0766, -3.708]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(0CFEE9E4-134E-4C77-9DBE-374684F305A9)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0662, 1.0886, -3.7187]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(8554DE46-0976-429B-82D2-1458CE4E960B)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0619, 1.0891, -3.7234]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(F1381221-98F5-47DA-A99E-4A0CB66B972B)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0576, 1.0874, -3.7279]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D5FD270C-9B82-42F4-B2AA-28AC77AAE4EC)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0503, 1.0602, -3.7343]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(EC95E601-B041-4181-AB3F-785D072B1459)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0683, 1.0877, -3.7164]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(2B83B833-1A5B-4A93-B921-1472E7B3DA23)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0703, 1.0538, -3.7113]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(9C364569-6BA0-48AA-B012-6CA9A6616E19)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0416, 1.0903, -3.7011]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(1A3CD992-A68A-4D35-BB87-963307CA26BC)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0321, 1.0858, -3.7099]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(163400BE-DAB0-4D0B-903F-3CE1B503E97D)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0493, 1.0857, -3.6915]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(3A9BAB07-7FB5-4A3A-BBF4-7C5129648169)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0277, 1.06, -3.7121]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(6DD569CA-762B-49EC-83CE-671AF8951CDD)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0343, 1.0518, -3.7048]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(806DF416-0FEC-4592-9032-BC3B1404B438)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0426, 1.051, -3.6956]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B2F63E04-A1A0-4A0A-B20B-C5F23630CBDB)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0502, 1.0583, -3.6881]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(F5827C0E-D114-487F-BF0C-6733457F5332)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0532, 1.0699, -3.6852]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B1F11100-D163-42DB-9C33-D05C1A5C0137)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.052, 1.0793, -3.687]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(6DE0C7F6-73D1-4280-B90D-E79EEAD1A96F)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0532, 1.0734, -3.6857]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(326D8BAC-A62C-4093-99E7-056FDBEC0E1E)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0529, 1.0667, -3.6854]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(008A56BA-D103-47CC-8B18-BFD87A5C8ABF)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0513, 1.0606, -3.6868]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(AF7013C3-45FF-431B-A713-C30FA3A0A094)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0485, 1.0557, -3.6896]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(3F5A65DA-C1A9-4035-AC6F-53CB81FF421D)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0447, 1.052, -3.6934]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(92D35703-45F2-4C68-8DE0-EEC794AB5050)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0404, 1.0504, -3.6979]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(C1FE9FCC-7E5E-40B8-828B-2ED2D832BC7A)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.036, 1.0508, -3.7026]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(FCF0B941-73FB-4D98-924D-A2E6542191E6)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.032, 1.0533, -3.707]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(FBF69504-F480-4E3C-B65E-4F1DF9036583)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0289, 1.0574, -3.7107]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(2B64E770-CB52-4B6C-B925-4922C66E96DD)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.027, 1.0627, -3.7132]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(23D0744D-8F51-44A9-808F-8BFF3A77B1F7)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0262, 1.0689, -3.7144]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(2B904AA7-1058-4E4D-9003-69D11251AB82)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0275, 1.0783, -3.7134]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(6239C76E-BF3F-4788-B6EF-B48CA675FC36)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0261, 1.0725, -3.7144]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(A7F724FA-2298-430C-8329-E79E3EE2D4D0)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0527, 1.0763, -3.686]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(66961D8C-24C1-4937-BA6D-4F705F26C6F5)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0522, 1.0636, -3.6859]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B02C41B7-593F-45CC-A7D1-DF8E9ACB8205)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0468, 1.0537, -3.6914]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(694B12DB-0D3A-40BD-9644-983A778BB19A)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0382, 1.0504, -3.7003]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(55421D6C-D396-46D8-B563-B8D6B6246058)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0304, 1.0551, -3.709]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(EA70AA23-AA25-4C28-ADDA-CB4351E9513D)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0264, 1.0657, -3.714]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(4313BFE8-C78B-4CD2-B261-100FCE0FC9F9)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0266, 1.0757, -3.714]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(AB91B86E-78D4-4213-AE36-97B88B005B31)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0417, 1.0906, -3.7016]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(7324A8FD-1110-4654-AC4C-6800AE3F1FA3)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0606, 1.0484, -3.7202]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(57E8B164-2CC0-4D91-A5F2-7157670B1FF3)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0499, 1.0777, -3.7344]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(42347D01-F08F-474B-BC91-DF2C64E03A34)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0486, 1.065, -3.7351]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(21A0692E-D1B2-45E3-AEF4-F15B008799B0)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0288, 1.0802, -3.7131]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(99519B43-5C23-450A-847B-ED861CB45233)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0276, 1.0803, -3.7119]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(0B755609-DEAF-4706-9D89-49CAFE9E2A93)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0428, 1.0516, -3.6942]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(868931CF-2371-48EB-822A-2208E004FAF8)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0521, 1.0803, -3.6882]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(8F42681B-9F0A-459A-9F67-9B63A4E427FB)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.051, 1.0619, -3.686]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(AE134908-0B31-400F-94A0-64CEFBA83D2C)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0449, 1.0529, -3.6921]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(2F6118B1-961A-4B9C-96FF-8B55EBC83B57)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0407, 1.0509, -3.6965]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(8658D8C0-88D2-48D9-9147-BEBD9D8A64EF)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0419, 1.0508, -3.6976]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(88F4B886-D98E-45D5-92E9-A65626F8C1AC)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0323, 1.0529, -3.7056]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(FA5C97EC-3641-498C-913D-06542F0765A3)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0335, 1.0528, -3.7067]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(155B89E4-289F-4755-97C8-778F92EA5870)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0266, 1.0619, -3.7121]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D49C6418-736A-4CEE-96A1-7B4A87091C0E)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0268, 1.0712, -3.7148]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(50DD6B21-5D2B-41F2-89D4-E17822D3A659)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0752, 1.0651, -3.7067]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D6F249CB-B183-4DBD-A14F-A37A19A3DCFC)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0752, 1.0746, -3.7072]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(4B7FD260-14DB-4E17-ADBD-AE2C321C30D2)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0764, 1.0745, -3.7083]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(1787686E-33B6-45FA-950A-4AFD10E5E65C)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0627, 1.0889, -3.7214]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(9DD7AAB6-5CD7-4140-AD3A-62627177FAA8)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0494, 1.0746, -3.7349]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(57802507-99F2-4EB9-BB19-06E1CBF9A3D0)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0505, 1.0746, -3.736]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(3445A0BD-4CE3-4B29-B602-EC6384CE5CCD)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0494, 1.0621, -3.7342]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(8326215B-2733-4B99-B47F-62C94123C302)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0506, 1.0621, -3.7353]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(A7CA7D97-7B2F-4442-AF06-108FDB97B971)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0768, 1.0682, -3.7076]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(C56C8658-E441-4C8E-A2C1-47CFDB49DCEE)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0745, 1.0621, -3.7073]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(9E39CD03-D794-4816-AA23-B74F6F004943)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0756, 1.0715, -3.7067]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(C7EF6C00-107A-4825-BBA6-AD0ABC006872)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0757, 1.0775, -3.7092]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(7C1931FD-70DD-4C4A-9656-D21B6B56F39B)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0689, 1.0866, -3.7146]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B252EAB6-F923-4C49-9AD6-7647182F1C5F)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0701, 1.0866, -3.7157]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(2DEB63E9-43CE-45F7-9EE3-DF4F06279282)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.066, 1.0886, -3.7201]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D083C604-8F4C-4D3B-BB99-FFA31BD7CAE0)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0575, 1.0866, -3.7291]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(ED2145F9-D4BF-4299-BEF5-8E9AE6449FED)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0489, 1.0715, -3.7352]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(7C9F4121-0C4D-4F48-9484-92D6532C7CA2)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.05, 1.0714, -3.7364]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B5CCDEE8-29B2-4EEF-928D-7A0264195081)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0501, 1.0651, -3.736]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(24D6B2D1-2FEC-4852-842A-0A0C48C17C78)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0515, 1.0592, -3.7342]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(F30E68AB-12F9-405D-AA6C-A015B7223D32)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0487, 1.0683, -3.7353]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(7C8A302B-AD9D-4ABF-BD29-63436184828C)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0513, 1.0803, -3.733]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(3CCFCF78-6FC7-413F-AA2F-C08C192A84CD)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0595, 1.0878, -3.727]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(CD188D06-4F70-4C95-A220-4AEE56051C06)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0681, 1.0878, -3.7179]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(20569F88-008F-47DC-82D2-884AA0A0AC1B)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0747, 1.0592, -3.7094]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(7AE384FF-BEC9-44AD-BAE8-70307225DD4A)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0343, 1.0517, -3.7034]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(C8E6B575-19B1-42D1-84CD-91E8DE384D9C)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.044, 1.0516, -3.6954]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(0FA323E2-21AE-446A-B653-36380F884CD3)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0525, 1.0712, -3.6849]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(9D7C6137-F57B-4EFD-98AA-84CD8B430AC8)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0537, 1.0712, -3.686]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(F34919FF-E37C-4A5E-A372-854AE196E876)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0509, 1.0803, -3.6871]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(8CFF3333-4552-4CC9-944C-3E76C623CA9E)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0523, 1.0744, -3.6853]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D3BC85F5-CA82-44CA-AB20-0C7A975330BF)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0523, 1.068, -3.6849]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(626E75C3-E1FE-4861-8412-A21F85E6E26E)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0535, 1.068, -3.686]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(E8BF26E4-84C2-4AF3-B40A-29213E04297D)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0521, 1.0619, -3.6872]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(C10FE602-CAF9-4115-9C81-9FE619BA531B)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.046, 1.0528, -3.6933]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(A758AFFD-DAEA-4D1A-9D7E-DA96B928F78C)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0363, 1.0509, -3.7011]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(558BB615-0057-47C8-A0AE-3D877332BC84)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0375, 1.0508, -3.7022]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(91FCF3FB-5A37-4ED9-AA98-4C97022FD272)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0256, 1.068, -3.7135]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B7B0FA80-F3E4-4553-BC02-BB877BB6A5E8)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0266, 1.0774, -3.7129]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(ED6EDCBD-9328-4DB3-95D0-27AD15125946)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0256, 1.0712, -3.7137]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(C0A01C69-56C7-4A3E-8263-D04F98920BF7)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0517, 1.0774, -3.686]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D50D2ADA-E722-435B-9929-EC73173A8C41)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0529, 1.0773, -3.6871]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(522C6904-801D-40AA-B693-8CD8730DAB51)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0518, 1.0649, -3.6853]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(E76719B5-1C0B-4C6A-9760-F257C2BC3DF2)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.053, 1.0649, -3.6864]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(EAB4161B-003B-4FD1-AC07-E8DC121E3B3E)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0385, 1.0506, -3.6988]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(41DDAB3B-9801-450F-B260-C04E1973F107)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0397, 1.0506, -3.6999]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(60B66D9A-837F-4CD2-A8FC-DE3C94CCD7F1)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0259, 1.0649, -3.713]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(F6A1645C-6E89-48B5-9A30-975E925F3198)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0259, 1.0744, -3.7135]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(8A431188-5930-423D-BE85-C6B1F0225767)002"]
            .geometry
        }
        material={materials.Material_1}
        position={[6.0271, 1.0743, -3.7146]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(94F1ED5A-014F-4466-B7B2-B5CDF3360552)002"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[6.0519, 1.0889, -3.7116]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(00314211-DDF0-4760-8158-2F38BDE21B09)002"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[6.0496, 1.0882, -3.7161]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D8C4E076-617B-49B3-B92E-9664CE0A8900)002"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[6.0517, 1.0886, -3.7156]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(ED138F06-DC88-49D5-8574-4156E107DC9D)002"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[6.0517, 1.0888, -3.7141]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(8CEE95FC-58BB-4E76-B57C-4DD83EF232B6)002"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[6.0553, 1.0887, -3.7107]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(A1272CCF-71FA-4B7B-A7B4-B89BE32221A2)002"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[6.0531, 1.0889, -3.7125]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D44FD2CE-2E34-40F7-BC97-DDFC258DEF93)002"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[6.0564, 1.0883, -3.7092]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(C9310E8D-2A90-447C-82A7-DCC5CD458307)002"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[6.0565, 1.0884, -3.7103]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(29894A8D-E973-4880-A164-AAE505CD1638)002"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[6.0534, 1.0885, -3.7063]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(3747A0A6-8753-4A87-9585-A36F7F96B746)002"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[6.0488, 1.0887, -3.7097]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(F27DAEEE-3333-46B3-8DB7-E66F227F4288)002"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[6.0481, 1.0884, -3.7141]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B3BD921F-29BD-4550-BBB1-E65F97A6F711)002"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[6.0491, 1.0887, -3.713]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(98FE35F7-6A0A-4BD7-B5B4-6304FCECF097)002"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[6.0484, 1.0889, -3.7111]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(9C12668D-37CE-4D48-A5DE-2065E461720B)002"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[6.0514, 1.0889, -3.7081]}
        rotation={[-1.5766, 0.0415, 2.322]}
      />
      <group
        position={[6.1475, 1.0965, -3.8261]}
        rotation={[-3.0985, -0.6528, -0.0261]}
        scale={[0.0227, 0.0164, 0.0174]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh010.geometry}
          material={materials["Material.016"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh010_1.geometry}
          material={materials["Material.012"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/Pecetetutacagi.glb");
