/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground20(props) {
  const { nodes, materials } = useGLTF("/tuluTabagi2.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Split_Line3(9F54D731-8191-40B0-8287-74B331F742FC)"].geometry
        }
        material={materials["Material_3.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Split_Line3(63B65204-834F-45D6-A678-81EADD2FC27D)"].geometry
        }
        material={materials["Material_0.010"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Split_Line3(1B3A804F-6C7E-4E02-B091-8907B3B2254F)"].geometry
        }
        material={materials["Material_1.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(48380A9D-9100-4285-B715-43E5DC0BCD66)003"].geometry
        }
        material={materials["Material_4.001"]}
      />
    </group>
  );
}

useGLTF.preload("/tuluTabagi2.glb");
