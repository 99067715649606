/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground22(props) {
  const { nodes, materials } = useGLTF("/ymt5.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale13(E43BC568-B495-4E28-9220-4A5A38E2CDAF)"].geometry
        }
        material={materials["Material_2.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale13(2BB084F1-0471-46D2-A7EF-B3AF496C7CD0)"].geometry
        }
        material={materials["Material_1.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale13(28EC2A54-DA94-4315-8F53-FF6B6BA09487)"].geometry
        }
        material={materials["Material_1.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Mirror25(5E9BAB6B-02C9-4025-B15B-74994034952F)"].geometry
        }
        material={materials["Material_1.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Mirror24(207F82BF-220B-40B3-9E9E-E558840B4263)"].geometry
        }
        material={materials["Material_1.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Mirror23(17C8E42D-1AC0-4871-AD7D-FFC74310F797)"].geometry
        }
        material={materials["Material_1.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Mirror22(B0FC4945-D4E1-4740-8334-29179A6CA508)"].geometry
        }
        material={materials["Material_1.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet94(7E364B2A-1EE6-456D-A649-A9CEDA394DB5)"].geometry
        }
        material={materials["Material_2.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet94(7C286113-8F9A-4F41-A284-DE98DD13CA48)"].geometry
        }
        material={materials["Material_1.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet94(3F55E80B-CE54-42E7-A24A-CFEEF40B2B93)"].geometry
        }
        material={materials["Material_2.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet63(9B25C3E6-55C1-4FB6-AA25-94EFC113F0CD)"].geometry
        }
        material={materials["Material_2.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet51(DC7D802F-14D5-4314-A852-BFF5AD633191)"].geometry
        }
        material={materials["Material_1.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet51(55F6A16F-974E-457E-A59B-C5AC3AB4E044)"].geometry
        }
        material={materials["Material_2.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet104(F6D9819B-A7D3-42CF-8D0B-8A366BEA64DB)"].geometry
        }
        material={materials["Material_1.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet104(DD86CE5F-F3B3-499F-804C-CBDCC20D7241)"].geometry
        }
        material={materials["Material_2.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet104(D58CE582-D7A0-4049-B77C-90F11143EB5A)"].geometry
        }
        material={materials["Material_1.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(B6CF421F-60E5-46D7-9B95-12054CB45183)"].geometry
        }
        material={materials["Material_1.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(963B99AA-2876-4BC1-A46D-61F90EEA0E83)"].geometry
        }
        material={materials["Material_0.004"]}
        position={[-1.4791, 0.5993, 3.5261]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.001, 0.001, 0.0005]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Body-MoveCopy3(243BC035-89C3-49EE-9F92-35845CD03C07)"].geometry
        }
        material={materials["Material_1.003"]}
      />
    </group>
  );
}

useGLTF.preload("/ymt5.glb");
