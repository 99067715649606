/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground31(props) {
  const { nodes, materials } = useGLTF("/AtaMasa.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(14BA5B16-5D6E-4905-BDE6-242D8857638C)001"]
            .geometry
        }
        material={materials["Material_0.001"]}
        position={[7.8703, 1.0903, -0.2121]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(CC2AF9DA-7496-4ED5-9D18-AFF808681409)001"]
            .geometry
        }
        material={materials["Material_0.001"]}
        position={[7.8668, 1.0722, -0.2099]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(7EB5019B-1A7C-4CC9-964E-C164A9B9861E)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8703, 1.0508, -0.223]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(FA107E5A-B531-4D91-953C-388206EBC0BB)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8896, 1.0655, -0.2198]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D609A419-5933-4474-B641-827720B0A2EC)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.89, 1.075, -0.22]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B6F310F2-EB80-40D9-9AB9-43C40A773384)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8838, 1.086, -0.2221]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(05F4DB81-3756-416E-B1C9-701AB835D990)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8726, 1.0908, -0.2256]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(962EC7F4-D031-4CDE-9F95-BD193119D92D)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8607, 1.0875, -0.2288]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B2A7393C-F71F-4DD8-93A1-52311D1788E1)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8532, 1.0776, -0.2307]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(A196D3D7-A7F7-4F23-B35B-900CDC3B56A8)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8528, 1.0649, -0.2303]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(8B2DDB96-7CFC-4BBF-8C1D-84C2196155BE)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8885, 1.0624, -0.22]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(2F4B40A9-98C8-4385-BD62-E2CBC4BF1058)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8904, 1.0719, -0.2198]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D45095F1-06E5-49A3-8A6D-B0776DA2F0A3)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.886, 1.0837, -0.2214]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(3A3B53F8-B189-4C43-A47C-0DB81141AA03)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8814, 1.0879, -0.2229]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(EF524E53-8CE2-4566-A783-B5E1E7449EC6)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8582, 1.0855, -0.2294]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(01A7C2C6-0E02-4026-80EC-0B61FE3A2BF3)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8544, 1.0804, -0.2303]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(89076BF7-30B9-4D60-96D5-1C86554F27EF)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8523, 1.0744, -0.2308]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(AB0A3E69-2CDA-46B7-A081-A0743A702112)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8521, 1.0679, -0.2305]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B43AEB5B-3FB8-48CE-B83C-9718447038CA)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.852, 1.0712, -0.2308]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(ED1AD1B3-2A8A-475D-AE66-62D5AD0C9320)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8562, 1.0832, -0.23]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(AEE6BF9F-B7EE-486D-8CE1-16F6DD406587)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8664, 1.0901, -0.2272]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(5F26BA99-B420-485E-9969-C283AEF0DE30)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8877, 1.0811, -0.2208]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(67AE50A5-4C45-4CD3-A32C-364F57D4E4CD)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8586, 1.0554, -0.2273]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(A07DCE2E-CF27-4AD1-B6D0-69BFD6EDD7FF)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8702, 1.0505, -0.2226]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(79609B09-7764-46DD-B657-4436AE025D08)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8902, 1.0687, -0.2196]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(3BCC8717-D9FB-4009-BECA-832C8017B1AB)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8891, 1.0782, -0.2203]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(0CFEE9E4-134E-4C77-9DBE-374684F305A9)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8756, 1.0903, -0.2246]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(8554DE46-0976-429B-82D2-1458CE4E960B)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8694, 1.0907, -0.2264]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(F1381221-98F5-47DA-A99E-4A0CB66B972B)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8634, 1.089, -0.2281]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D5FD270C-9B82-42F4-B2AA-28AC77AAE4EC)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8539, 1.0618, -0.2298]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(EC95E601-B041-4181-AB3F-785D072B1459)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8785, 1.0893, -0.2237]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(2B83B833-1A5B-4A93-B921-1472E7B3DA23)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8829, 1.0555, -0.2204]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(9C364569-6BA0-48AA-B012-6CA9A6616E19)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8635, 1.092, -0.1969]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(1A3CD992-A68A-4D35-BB87-963307CA26BC)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8508, 1.0874, -0.1995]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(163400BE-DAB0-4D0B-903F-3CE1B503E97D)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8751, 1.0874, -0.1926]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(3A9BAB07-7FB5-4A3A-BBF4-7C5129648169)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8459, 1.0617, -0.1991]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(6DD569CA-762B-49EC-83CE-671AF8951CDD)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8554, 1.0534, -0.1963]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(806DF416-0FEC-4592-9032-BC3B1404B438)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8672, 1.0526, -0.1927]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B2F63E04-A1A0-4A0A-B20B-C5F23630CBDB)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8776, 1.0599, -0.1902]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(F5827C0E-D114-487F-BF0C-6733457F5332)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8816, 1.0715, -0.1892]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B1F11100-D163-42DB-9C33-D05C1A5C0137)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8797, 1.0809, -0.1901]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(6DE0C7F6-73D1-4280-B90D-E79EEAD1A96F)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8814, 1.0751, -0.1896]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(326D8BAC-A62C-4093-99E7-056FDBEC0E1E)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8813, 1.0683, -0.1892]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(008A56BA-D103-47CC-8B18-BFD87A5C8ABF)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8792, 1.0623, -0.1896]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(AF7013C3-45FF-431B-A713-C30FA3A0A094)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8753, 1.0573, -0.1905]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(3F5A65DA-C1A9-4035-AC6F-53CB81FF421D)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8701, 1.0537, -0.1919]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(92D35703-45F2-4C68-8DE0-EEC794AB5050)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8641, 1.0521, -0.1935]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(C1FE9FCC-7E5E-40B8-828B-2ED2D832BC7A)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8579, 1.0525, -0.1953]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(FCF0B941-73FB-4D98-924D-A2E6542191E6)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8522, 1.0549, -0.197]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(FBF69504-F480-4E3C-B65E-4F1DF9036583)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8477, 1.059, -0.1985]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(2B64E770-CB52-4B6C-B925-4922C66E96DD)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8447, 1.0644, -0.1998]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(23D0744D-8F51-44A9-808F-8BFF3A77B1F7)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8434, 1.0706, -0.2003]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(2B904AA7-1058-4E4D-9003-69D11251AB82)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8451, 1.08, -0.2002]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(6239C76E-BF3F-4788-B6EF-B48CA675FC36)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8434, 1.0742, -0.2003]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(A7F724FA-2298-430C-8329-E79E3EE2D4D0)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8808, 1.0779, -0.1897]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(66961D8C-24C1-4937-BA6D-4F705F26C6F5)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8804, 1.0652, -0.1893]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B02C41B7-593F-45CC-A7D1-DF8E9ACB8205)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8729, 1.0553, -0.1912]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(694B12DB-0D3A-40BD-9644-983A778BB19A)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.861, 1.052, -0.1944]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(55421D6C-D396-46D8-B563-B8D6B6246058)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8498, 1.0568, -0.1978]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(EA70AA23-AA25-4C28-ADDA-CB4351E9513D)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8438, 1.0674, -0.2001]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(4313BFE8-C78B-4CD2-B261-100FCE0FC9F9)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.844, 1.0773, -0.2002]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(AB91B86E-78D4-4213-AE36-97B88B005B31)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8634, 1.0923, -0.1974]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(57E8B164-2CC0-4D91-A5F2-7157670B1FF3)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8535, 1.0793, -0.2297]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(42347D01-F08F-474B-BC91-DF2C64E03A34)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.852, 1.0667, -0.2296]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(77D7BE6F-B02F-4A33-9FA3-6365664DEA56)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8649, 1.0899, -0.2268]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(21A0692E-D1B2-45E3-AEF4-F15B008799B0)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8464, 1.0819, -0.2006]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(99519B43-5C23-450A-847B-ED861CB45233)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.846, 1.0819, -0.199]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(0B755609-DEAF-4706-9D89-49CAFE9E2A93)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8681, 1.0533, -0.1916]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(868931CF-2371-48EB-822A-2208E004FAF8)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8791, 1.0819, -0.1912]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(8F42681B-9F0A-459A-9F67-9B63A4E427FB)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8793, 1.0636, -0.1887]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(AE134908-0B31-400F-94A0-64CEFBA83D2C)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8709, 1.0545, -0.1908]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(2F6118B1-961A-4B9C-96FF-8B55EBC83B57)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8651, 1.0525, -0.1924]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(8658D8C0-88D2-48D9-9147-BEBD9D8A64EF)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8656, 1.0525, -0.194]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(88F4B886-D98E-45D5-92E9-A65626F8C1AC)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8532, 1.0545, -0.1959]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(FA5C97EC-3641-498C-913D-06542F0765A3)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8537, 1.0545, -0.1975]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(155B89E4-289F-4755-97C8-778F92EA5870)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.845, 1.0636, -0.1986]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D49C6418-736A-4CEE-96A1-7B4A87091C0E)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8437, 1.0728, -0.201]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(50DD6B21-5D2B-41F2-89D4-E17822D3A659)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8894, 1.0668, -0.219]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D6F249CB-B183-4DBD-A14F-A37A19A3DCFC)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8892, 1.0763, -0.2194]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(4B7FD260-14DB-4E17-ADBD-AE2C321C30D2)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8897, 1.0762, -0.221]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(1787686E-33B6-45FA-950A-4AFD10E5E65C)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8711, 1.0906, -0.2251]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(9DD7AAB6-5CD7-4140-AD3A-62627177FAA8)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8528, 1.0763, -0.2298]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(57802507-99F2-4EB9-BB19-06E1CBF9A3D0)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8532, 1.0762, -0.2314]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(3445A0BD-4CE3-4B29-B602-EC6384CE5CCD)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8532, 1.0638, -0.2293]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(8326215B-2733-4B99-B47F-62C94123C302)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8536, 1.0637, -0.2308]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(A7CA7D97-7B2F-4442-AF06-108FDB97B971)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8903, 1.0699, -0.2205]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(C56C8658-E441-4C8E-A2C1-47CFDB49DCEE)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8885, 1.0638, -0.2191]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(9E39CD03-D794-4816-AA23-B74F6F004943)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8898, 1.0731, -0.2191]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(C7EF6C00-107A-4825-BBA6-AD0ABC006872)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8886, 1.0792, -0.2214]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(7C1931FD-70DD-4C4A-9656-D21B6B56F39B)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8799, 1.0883, -0.2225]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B252EAB6-F923-4C49-9AD6-7647182F1C5F)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8804, 1.0882, -0.224]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(2DEB63E9-43CE-45F7-9EE3-DF4F06279282)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8746, 1.0902, -0.2258]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D083C604-8F4C-4D3B-BB99-FFA31BD7CAE0)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8627, 1.0882, -0.2291]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(ED2145F9-D4BF-4299-BEF5-8E9AE6449FED)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8522, 1.0732, -0.2299]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(7C9F4121-0C4D-4F48-9484-92D6532C7CA2)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8526, 1.0731, -0.2315]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B5CCDEE8-29B2-4EEF-928D-7A0264195081)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8528, 1.0668, -0.2312]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(24D6B2D1-2FEC-4852-842A-0A0C48C17C78)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8549, 1.0609, -0.2303]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(F30E68AB-12F9-405D-AA6C-A015B7223D32)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.852, 1.07, -0.2298]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(7C8A302B-AD9D-4ABF-BD29-63436184828C)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8554, 1.082, -0.2293]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(3CCFCF78-6FC7-413F-AA2F-C08C192A84CD)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8655, 1.0895, -0.2283]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(CD188D06-4F70-4C95-A220-4AEE56051C06)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8776, 1.0895, -0.2249]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(20569F88-008F-47DC-82D2-884AA0A0AC1B)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8876, 1.0608, -0.221]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(7AE384FF-BEC9-44AD-BAE8-70307225DD4A)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.856, 1.0533, -0.1951]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(C8E6B575-19B1-42D1-84CD-91E8DE384D9C)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8685, 1.0532, -0.1932]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(0FA323E2-21AE-446A-B653-36380F884CD3)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8811, 1.0729, -0.1886]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(9D7C6137-F57B-4EFD-98AA-84CD8B430AC8)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8816, 1.0728, -0.1901]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(F34919FF-E37C-4A5E-A372-854AE196E876)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8787, 1.0819, -0.1896]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(8CFF3333-4552-4CC9-944C-3E76C623CA9E)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8808, 1.076, -0.1888]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D3BC85F5-CA82-44CA-AB20-0C7A975330BF)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.881, 1.0697, -0.1885]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(626E75C3-E1FE-4861-8412-A21F85E6E26E)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8815, 1.0696, -0.1901]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(E8BF26E4-84C2-4AF3-B40A-29213E04297D)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8797, 1.0635, -0.1903]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(C10FE602-CAF9-4115-9C81-9FE619BA531B)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8713, 1.0545, -0.1924]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(A758AFFD-DAEA-4D1A-9D7E-DA96B928F78C)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.859, 1.0525, -0.1942]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(558BB615-0057-47C8-A0AE-3D877332BC84)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8594, 1.0525, -0.1958]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(91FCF3FB-5A37-4ED9-AA98-4C97022FD272)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8434, 1.0697, -0.1993]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B7B0FA80-F3E4-4553-BC02-BB877BB6A5E8)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8446, 1.0791, -0.1993]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(ED6EDCBD-9328-4DB3-95D0-27AD15125946)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8433, 1.0729, -0.1994]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(C0A01C69-56C7-4A3E-8263-D04F98920BF7)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.88, 1.0791, -0.1891]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D50D2ADA-E722-435B-9929-EC73173A8C41)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8804, 1.079, -0.1907]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(522C6904-801D-40AA-B693-8CD8730DAB51)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8804, 1.0666, -0.1886]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(E76719B5-1C0B-4C6A-9760-F257C2BC3DF2)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8808, 1.0665, -0.1901]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(EAB4161B-003B-4FD1-AC07-E8DC121E3B3E)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.862, 1.0523, -0.1933]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(60B66D9A-837F-4CD2-A8FC-DE3C94CCD7F1)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.844, 1.0666, -0.199]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(F6A1645C-6E89-48B5-9A30-975E925F3198)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8437, 1.076, -0.1994]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(8A431188-5930-423D-BE85-C6B1F0225767)001"]
            .geometry
        }
        material={materials.Material_1}
        position={[7.8442, 1.076, -0.201]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(94F1ED5A-014F-4466-B7B2-B5CDF3360552)001"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[7.867, 1.0906, -0.2112]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(00314211-DDF0-4760-8158-2F38BDE21B09)001"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[7.8626, 1.0899, -0.2138]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D8C4E076-617B-49B3-B92E-9664CE0A8900)001"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[7.8647, 1.0902, -0.2145]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(ED138F06-DC88-49D5-8574-4156E107DC9D)001"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[7.8655, 1.0904, -0.2132]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(8CEE95FC-58BB-4E76-B57C-4DD83EF232B6)001"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[7.8703, 1.0903, -0.2122]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(A1272CCF-71FA-4B7B-A7B4-B89BE32221A2)001"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[7.8675, 1.0905, -0.2126]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(D44FD2CE-2E34-40F7-BC97-DDFC258DEF93)001"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[7.872, 1.09, -0.2114]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(C9310E8D-2A90-447C-82A7-DCC5CD458307)001"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[7.8715, 1.09, -0.2125]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(29894A8D-E973-4880-A164-AAE505CD1638)001"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[7.8709, 1.0901, -0.2074]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(3747A0A6-8753-4A87-9585-A36F7F96B746)001"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[7.8653, 1.0903, -0.2079]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(F27DAEEE-3333-46B3-8DB7-E66F227F4288)001"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[7.8624, 1.0901, -0.2114]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(B3BD921F-29BD-4550-BBB1-E65F97A6F711)001"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[7.8638, 1.0904, -0.2109]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(98FE35F7-6A0A-4BD7-B5B4-6304FCECF097)001"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[7.8642, 1.0906, -0.2089]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Kes-Ekstr�zyon3(9C12668D-37CE-4D48-A5DE-2065E461720B)001"]
            .geometry
        }
        material={materials["Material_2.001"]}
        position={[7.8683, 1.0906, -0.2079]}
        rotation={[-1.5971, 0.0326, 2.8627]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(47737528-621A-4FA5-BB56-3A92CBC8B2A4)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.4423, 1.0716, -0.5621]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(85510E9B-7425-4A3D-A486-168836EB1281)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.4746, 1.0716, -0.5732]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(37012D16-150C-409E-AFD0-8A595B58BE73)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.4641, 1.0719, -0.5743]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(BC0E5087-DA68-4378-9511-DDD21CE45110)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.4657, 1.0717, -0.5679]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(F5213F9D-508A-48DE-B09C-D44D42C4AF40)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.4527, 1.0717, -0.5636]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(0169432D-94C0-4BFA-8C7A-CD230ACFD15A)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.4573, 1.0711, -0.5689]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(DB215891-7FC4-4409-8E6D-8B259991F2B8)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.6537, 1.0711, -0.4853]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(2E2B050C-7BA7-4212-8203-74443CEAF8F9)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.6647, 1.071, -0.4531]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(27D31AFC-E090-4046-8D1D-6C0620F53812)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.5757, 1.0703, -0.5529]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(A0524E09-9805-4690-979A-095CCB6D4E8B)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.6659, 1.0713, -0.4636]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(461E3F5A-82BE-423A-9329-2993E93132BC)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.6595, 1.0711, -0.462]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(6C777D95-A7CA-4EA2-9066-556C1006C779)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.6552, 1.0712, -0.4749]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(A2EDF978-FC2A-457C-BE40-5B17CBFBA03A)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.6605, 1.0706, -0.4704]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(BF2E15CA-2D8E-46CF-A9D7-A7F3EFD69AE2)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.5769, 1.0705, -0.274]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(65011318-637F-4252-A0DD-0170CADDCF19)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.5446, 1.0705, -0.2629]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(7A1C91B5-5ECF-42B8-8151-59B56C444B8D)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.6446, 1.0697, -0.3521]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(1F972823-8F6A-4DFD-93D9-315B2ABFF147)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.5551, 1.0708, -0.2618]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(14008FA6-B577-4C6D-A1CD-8E9D5F55BAED)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.5535, 1.0706, -0.2681]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(0243FBFA-92CB-4249-A908-60DB575351A7)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.5665, 1.0707, -0.2725]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(97DDC65F-7329-4A3C-A5DD-432BA5DA7C69)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.5618, 1.07, -0.2672]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(93B58529-5C3C-425D-96EA-FC387E260596)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.3655, 1.071, -0.3508]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(CC08F74C-9478-4586-B52D-DD26A9C3B767)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.3545, 1.0711, -0.383]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(4CC5B3AD-1790-448F-80B9-372D895765E6)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.3746, 1.0703, -0.4839]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(6A70D1C7-080A-4B0F-A037-F747F3B60D87)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.4433, 1.0697, -0.2832]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(E02660EC-40C9-426C-9C43-7E51AB93B71F)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.3533, 1.0714, -0.3725]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(9BFE73CD-05F2-461A-ABC7-0F9386FE9999)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.3597, 1.0712, -0.3741]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(C4C5DEEF-9DC4-4FDB-9943-656E03E0EC2D)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.364, 1.0712, -0.3612]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(9BE44378-5ECF-41D6-9ACE-0417CD7E13A9)001"].geometry
        }
        material={materials["Material_2.002"]}
        position={[7.3587, 1.0706, -0.3657]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(2A7DFF1D-D8F7-4022-BD97-494CBD19AA67)001"].geometry
        }
        material={materials["Material_0.016"]}
        position={[7.5096, 1.0678, -0.4184]}
        rotation={[-0.758, 0.2433, 0.2242]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet108(70B8EA15-B218-4C2A-ACA8-C73B1C45F5CF)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[7.5064, 1.0739, -0.4157]}
        rotation={[1.0199, -0.1565, -0.1006]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet108(2ECD31B8-5FEE-4551-B666-4067C21A731D)001"].geometry
        }
        material={materials["Material_2.004"]}
        position={[7.5556, 1.0794, -0.4132]}
        rotation={[1.0199, -0.1565, -0.1006]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Combine23(7FB77A60-391A-4A75-8215-85660CBCFC10)001"].geometry
        }
        material={materials["Material_2.004"]}
        position={[7.6065, 1.0892, -0.4315]}
        rotation={[0.8292, -0.131, -0.2208]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Combine23(0216F9C3-5D53-48E0-8825-5038FED36DBF)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[7.483, 1.085, -0.4143]}
        rotation={[0.8292, -0.131, -0.2208]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Combine23(00AB2F9A-EC65-414E-92EB-205A4905D540)001"].geometry
        }
        material={materials["Material_2.004"]}
        position={[7.4508, 1.0884, -0.4118]}
        rotation={[0.8292, -0.131, -0.2208]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(BF3F56DB-D8DC-40E6-82A3-E0BC33EFF7A3)001"].geometry
        }
        material={materials["Material_2.004"]}
        position={[9.2944, 0.356, -4.319]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(93CF2658-8FFD-4E48-9F93-EE0B5FE17CFC)001"].geometry
        }
        material={materials["Material_2.004"]}
        position={[9.2944, 0.356, -4.319]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(861AD22C-BFC6-49A6-8AFC-8AFC11001164)001"].geometry
        }
        material={materials["Material_2.004"]}
        position={[9.2944, 0.356, -4.319]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(3D377015-252C-405D-B796-1DFCBE535FC6)001"].geometry
        }
        material={materials["Material_2.004"]}
        position={[9.2944, 0.356, -4.319]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(2A3446B2-9F4F-41C9-A858-335F7EDFB856)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[9.2944, 0.356, -4.319]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(29A8C729-4E31-4813-9277-2C112A19AD49)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[9.2944, 0.356, -4.319]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(1EDC35C8-539C-4155-9FC2-E21B46A90344)001"].geometry
        }
        material={materials["Material_2.004"]}
        position={[9.2944, 0.356, -4.319]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(8831D603-C5A7-4D28-B347-3B3A3B4A305B)001"].geometry
        }
        material={materials["Material_2.004"]}
        position={[9.2944, 0.356, -4.319]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(5AFB18C3-BFDF-4CDE-8D7B-6DA06C9E9F08)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[9.2944, 0.356, -4.319]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(4F71803A-9B62-47C3-B965-91C40DB73155)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[9.2944, 0.356, -4.319]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Y�zSil4(E0C48E55-6C2C-4079-BA2D-9E8BA2AB01A9)001"].geometry
        }
        material={materials["Material_2.006"]}
        position={[7.1997, 1.057, -0.4897]}
        rotation={[-2.1578, 0.0149, -3.1249]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Y�zSil4(D5121E08-21C8-4CC4-AC36-C4F24D66344E)001"].geometry
        }
        material={materials["Material_1.005"]}
        position={[7.2029, 1.0565, -0.3758]}
        rotation={[-2.1578, 0.0149, -3.1249]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Y�zSil4(C3BD654E-523F-449D-8DD2-C0C597AE6820)001"].geometry
        }
        material={materials["Material_1.005"]}
        position={[7.1995, 1.0547, -0.5093]}
        rotation={[-2.1578, 0.0149, -3.1249]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Y�zSil4(71F9C4C4-8A5D-457A-8494-521EBDEA3CA7)001"].geometry
        }
        material={materials["Material_1.005"]}
        position={[7.1996, 1.0593, -0.5109]}
        rotation={[-2.1578, 0.0149, -3.1249]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus33(759CA8BF-DCDE-47AF-9688-4A85A6F40941)001"].geometry
        }
        material={materials["Material_2.006"]}
        position={[7.2586, 1.0575, -0.4844]}
        rotation={[-2.1575, 0.0262, 3.1413]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus33(280A44CB-DC59-4A3A-9088-61A4A5BFEDF3)001"].geometry
        }
        material={materials["Material_1.005"]}
        position={[7.2572, 1.0608, -0.3858]}
        rotation={[-2.1575, 0.0262, 3.1413]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus33(05FAEB39-36DF-4582-ACFD-FD345296116D)001"].geometry
        }
        material={materials["Material_1.005"]}
        position={[7.2582, 1.0571, -0.4761]}
        rotation={[-2.1575, 0.0262, 3.1413]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus2(F38AAE79-AED9-4627-B9D4-FFCEDEB003B4)001"].geometry
        }
        material={materials["Material_1.005"]}
        position={[7.7334, 1.0578, -0.4741]}
        rotation={[-2.1449, -0.0033, 3.1353]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus2(AFF06B3B-70F0-4119-A41C-5236E3A40C59)001"].geometry
        }
        material={materials["Material_1.005"]}
        position={[7.7335, 1.0572, -0.4573]}
        rotation={[-2.1449, -0.0033, 3.1353]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus2(69F66079-A962-47DA-8123-8FBDDF42239D)001"].geometry
        }
        material={materials["Material_1.005"]}
        position={[7.7331, 1.0569, -0.3858]}
        rotation={[-2.1449, -0.0033, 3.1353]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus2(4D0932F8-1EB5-4674-80AF-6550C2279CB2)001"].geometry
        }
        material={materials["Material_2.006"]}
        position={[7.7337, 1.0583, -0.4823]}
        rotation={[-2.1449, -0.0033, 3.1353]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(ECE96E59-0DF3-4D06-AA7E-7A7F052D041C)001"].geometry
        }
        material={materials["Material_0.006"]}
        position={[7.197, 1.0575, -0.3676]}
        rotation={[-2.1578, 0.0149, -3.1249]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Split_Line3(9F54D731-8191-40B0-8287-74B331F742FC)001"].geometry
        }
        material={materials["Material_3.002"]}
        position={[7.706, 1.0644, -0.1425]}
        rotation={[1.283, 0.3261, 0.0757]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Split_Line3(63B65204-834F-45D6-A678-81EADD2FC27D)001"].geometry
        }
        material={materials["Material_0.010"]}
        position={[7.706, 1.0621, -0.1427]}
        rotation={[1.283, 0.3261, 0.0757]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Split_Line3(1B3A804F-6C7E-4E02-B091-8907B3B2254F)001"].geometry
        }
        material={materials["Material_1.009"]}
        position={[7.7058, 1.0586, -0.1426]}
        rotation={[1.283, 0.3261, 0.0757]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(48380A9D-9100-4285-B715-43E5DC0BCD66)004"].geometry
        }
        material={materials["Material_4.001"]}
        position={[7.6998, 1.0537, -0.1429]}
        rotation={[1.283, 0.3261, 0.0757]}
      />
      <group
        position={[7.89, 1.0982, -0.3585]}
        rotation={[-3.049, -1.1922, 0.0339]}
        scale={[0.0227, 0.0164, 0.0174]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh008.geometry}
          material={materials["Material.016"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh008_1.geometry}
          material={materials["Material.012"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus5(F42225C3-D367-457D-AA6C-FE4472F0D406)001"].geometry
        }
        material={materials["Material_0.012"]}
        position={[7.2968, 1.0652, -0.1467]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(FFB8B0BE-F4D0-46D9-88BF-047A97522"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.254, 1.2163, -0.1989]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(FF8018E7-E6AE-4867-9020-05806CE62"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2531, 1.2163, -0.0954]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(FD25C836-5B28-4D04-949A-948E67258"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2977, 1.2167, -0.2185]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(FB6A4CC4-4B79-46D1-8410-87066BF37"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3445, 1.2163, -0.1924]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(FAB7461C-8682-4E49-8E7F-0928B60DE"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3524, 1.2161, -0.1481]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(F88CF69D-5B1D-4D0D-BC80-7CD7DC7E9"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3511, 1.2163, -0.1464]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(F87FC04E-B85B-47B9-8E29-D0F401C66"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2632, 1.2163, -0.2087]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(F7A650BD-23CB-430C-AB1B-114DB4DCF"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2425, 1.2163, -0.177]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(F60FA288-85A1-492F-8468-DFBDB697A"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2389, 1.2163, -0.1491]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(F60430B6-9AED-4175-A279-F886DAF8A"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3335, 1.2163, -0.2066]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(F402A69C-E2E1-4832-B7B4-B1DE7488B"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.304, 1.2168, -0.2206]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(F3B9DA30-F0A3-47B5-80BD-BB509D43E"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3022, 1.2166, -0.0782]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(F3A80ABA-4E79-4CC2-8271-801C89995"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3189, 1.2163, -0.217]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(F38E544D-3CEA-40D7-A9F9-E8E931CD2"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2435, 1.2163, -0.1132]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(EE2893E5-C044-4C43-ADFE-B9230A61C"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2673, 1.2163, -0.0811]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(EC7D1A50-99CB-4809-8B7A-231D20DB1"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3248, 1.2163, -0.2136]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(EC7969B6-1BF6-4965-BD3E-0D39E33FC"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2381, 1.2168, -0.1535]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(EB5F77D8-B337-423D-8F2F-C98464DB8"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2584, 1.2163, -0.2041]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(E7C00C81-3A32-41A2-BD48-DE4AB0E80"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3209, 1.2163, -0.216]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(E5935D09-9B7E-49DF-8739-E7CAE4D74"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2667, 1.2163, -0.2116]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(E4E00AE2-B23F-435F-9A10-CF814D36F"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2743, 1.2163, -0.2164]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(E4CB6703-9707-425F-908B-6FFC0FB42"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.347, 1.2163, -0.1061]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(E3C4577B-4E1D-49A4-8FCD-3888087F9"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2502, 1.2163, -0.1933]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(E025FD7F-7316-4D71-8D03-AFD0F29BA"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2914, 1.2168, -0.2207]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(DAF2DCE2-F4CA-4667-B44E-7279D296C"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2518, 1.2163, -0.0972]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(DADEFA4B-B76B-4125-845D-67A4BF64E"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2403, 1.2167, -0.1473]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(D9F83F3E-60D6-4F3F-A1D3-F24CA752A"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2411, 1.2163, -0.1726]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(D808DE60-A70E-47BB-A89E-8D60F922A"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.337, 1.2163, -0.0911]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(D7739166-C89E-4EB9-9E2F-669FCB048"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3525, 1.2164, -0.1608]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(D3BB8353-AD1A-46DF-AD34-E704B7876"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3467, 1.2163, -0.1884]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(D3ACAE65-59F7-4779-8DA8-99ADD1A76"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3229, 1.2163, -0.2148]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(CD8189A6-D169-4D44-B0CF-C77FD8BEC"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3384, 1.2163, -0.0928]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(CA3FDF0A-817C-4E90-B425-59CF9A16D"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2416, 1.2164, -0.1618]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(C9508CB7-032B-43AD-8CF8-1A0CFDDA5"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3339, 1.2163, -0.0878]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(C93A56E7-3A83-4EAB-B6A8-7F5F110F0"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.275, 1.2163, -0.0764]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(C70DE581-8257-4AAB-BEC8-07760665D"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.292, 1.2166, -0.2154]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(C5DDAC5B-27D7-4087-8FE1-F2831A89D"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3235, 1.2163, -0.0793]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(C2A52C32-8607-4A20-AE62-2156B7DDC"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2692, 1.2163, -0.0798]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(BD3A1FCC-CB0E-49E6-B04E-BB5D54976"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3489, 1.2163, -0.1102]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(BD0491E8-7166-40D2-B851-FB23B9997"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3538, 1.2163, -0.1694]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(BC0B46BB-ECEF-40F7-9E5B-A2A396211"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3504, 1.2163, -0.1802]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B82E6C7E-1517-483E-A3F2-08B9D5289"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3355, 1.2163, -0.0894]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B741C77B-1ED6-4F26-9D35-8F167DB1D"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3559, 1.2168, -0.1524]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B67540DB-2880-4784-873A-3382553BD"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3307, 1.2163, -0.0847]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B6050740-1335-4309-992E-5240D19CA"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3498, 1.2163, -0.1123]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B447E590-E4D6-4DEE-BB64-18CA7E83E"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3536, 1.2167, -0.1462]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B1C1A436-4B07-445A-8F5F-DEFAFAE41"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2414, 1.2164, -0.1326]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B10478BB-44A3-4698-996B-50BFCB9EB"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2957, 1.2161, -0.2173]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B0FEF356-59F8-4ADC-B05D-6279F1378"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3424, 1.2163, -0.0983]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B06C1947-92D4-4725-890E-9DC092267"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3365, 1.2163, -0.2033]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B00A4398-F351-4E88-A28E-EF5C73E56"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2435, 1.2166, -0.1414]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(AE65DDF3-1DD0-42AD-A213-62C844634"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2962, 1.2163, -0.0775]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(AE07D9D3-6FCB-4138-80A9-0DF4C5D03"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3412, 1.2163, -0.0964]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(AD502295-F7D4-41E7-AFA4-B90202E31"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.355, 1.2163, -0.1481]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(AB8AE811-C496-4C98-9038-FA183DA69"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2621, 1.2163, -0.0853]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(AB894669-2D4A-4AE9-8CA3-2784C2E5B"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.265, 1.2163, -0.2102]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(A7D6CFBD-51D1-4277-80D3-FE2D27744"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3253, 1.2163, -0.0805]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(A617CC74-6E5D-4AF5-88FF-86B1849C0"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3456, 1.2163, -0.1904]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(A541C931-68EA-4E46-999D-550A617EC"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2435, 1.2167, -0.1529]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(A213B773-749E-4685-8F36-FEB68AB0E"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3495, 1.2163, -0.1823]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(A08A9EAC-C0BA-4E3D-881F-619721331"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3176, 1.2163, -0.076]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(A058D275-E3FA-4518-B854-FD826FA1F"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2978, 1.2163, -0.0745]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(9BA9161A-9372-48F2-967B-79D6A8D7C"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2638, 1.2163, -0.0839]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(996FD289-D0A1-4EE3-B89E-D29E223D7"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2494, 1.2163, -0.1011]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(990543CC-4B10-457B-93B3-34D2A9EDD"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2901, 1.2168, -0.0728]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(968CE753-F8C1-40D1-A914-5BBF0B5AA"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2428, 1.2163, -0.1474]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(92C9202B-5DBB-49E8-B275-1491A10A1"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3526, 1.2163, -0.1738]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(90B93271-F514-4B8A-82D2-D83012D59"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3216, 1.2163, -0.0781]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(9008A3E9-B9A1-4338-92D0-726638A22"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2415, 1.2161, -0.1492]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(89CA7D12-CF7C-43F5-9B29-EDB71DC06"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3035, 1.2166, -0.2152]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(8966FB2E-3BF7-412C-A256-41EE87EE6"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2418, 1.2163, -0.1748]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(88A38DA9-C3CA-419E-B905-B98626983"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2605, 1.2163, -0.0869]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(87C22762-9E01-4C45-90F6-490959ED9"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3113, 1.2164, -0.2169]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(7C0B0D9E-9066-4A65-A547-12B2EB847"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3487, 1.2163, -0.1844]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(79F04002-1221-4E10-83B2-4A5CB9A2D"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3477, 1.2163, -0.1864]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(77D10E3B-B847-43C6-B144-B1925B791"].geometry
        }
        material={materials["Material_0.012"]}
        position={[7.2969, 1.2112, -0.1476]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(76D85474-F521-4C27-941D-94E4F3519"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3521, 1.2163, -0.1186]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(76169719-C74A-4CAB-8D5C-9339F746A"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2433, 1.2163, -0.1791]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(76088D9B-CB47-470C-852F-B65D56DF0"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2441, 1.2163, -0.1812]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(74E6E6C9-F28C-45DA-A448-4AA2BB1E6"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3519, 1.2163, -0.1759]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(725931F7-1256-414A-A8EA-17B54E63F"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2405, 1.2163, -0.1705]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(725058D9-D6AD-4C6D-9996-751D5C64A"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2491, 1.2163, -0.1913]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(6EF06707-C28D-47A7-AFA7-E63FACDD9"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3506, 1.2163, -0.1143]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(6D05F382-F43D-4A39-A9F0-1C80838BD"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2589, 1.2163, -0.0885]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(6BB9BA05-1C02-4EFD-97AA-DADE44FFC"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.238, 1.2168, -0.141]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(69DDBA15-9797-45D1-926B-C9094A62A"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2413, 1.2163, -0.1197]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(681DA72C-1499-4D49-9854-618D7684E"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.348, 1.2163, -0.1081]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(67E68F5A-1F0A-4226-8D02-96602F540"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3026, 1.2168, -0.0727]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(67E641AD-3F8A-4905-BB40-672B93662"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3421, 1.2163, -0.1962]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(6758ACA4-E201-47E0-9FD3-A2438F671"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2992, 1.2162, -0.2189]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(65C1CA6B-F5BE-4F9E-B89C-86C4B7359"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3433, 1.2163, -0.1943]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(65BCCB28-9BEB-475F-8FFF-9744A2D1B"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2472, 1.2163, -0.105]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(6547E8A5-563D-41B6-8577-E317BA019"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2545, 1.2163, -0.0936]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(647EC6D9-3F48-4CB2-AFE1-9702F09D0"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2528, 1.2163, -0.197]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(61BBC253-BED2-428A-818E-4256520DA"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2763, 1.2163, -0.2174]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(60E3FA2F-DCFB-4E9F-8EDD-BFE8684EE"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.26, 1.2163, -0.2057]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(6063F898-CB56-49E0-B19E-343706F93"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3318, 1.2163, -0.2081]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(5DC72CAA-489A-4642-880D-5E8C8830F"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3503, 1.2166, -0.1404]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(5D442929-C545-4E05-98FF-7F47D9758"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2656, 1.2163, -0.0824]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(5B0FA5A9-48F3-4A6B-AEDE-CEFDACE1B"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.248, 1.2163, -0.1893]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(59621F86-B69A-4178-A5FC-A915FDF4C"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2515, 1.2163, -0.1951]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(558A3240-D9F0-4147-8429-6450AD234"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2963, 1.2167, -0.075]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(5471E68C-D899-4BC8-AE31-F32CD3AAD"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2407, 1.2163, -0.1218]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(5342275B-A5AA-4D42-A9F1-B9A84955E"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2427, 1.2163, -0.1154]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(508BDF56-867D-4570-A8C3-322A277DC"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2452, 1.2163, -0.1091]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(4DD7F6D7-6E25-4754-9C30-B90B0ED51"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.338, 1.2163, -0.2016]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(4DD294B0-CAFF-4B37-B81D-0E1BD0694"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.335, 1.2163, -0.2049]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(4CB8287C-7C55-4685-A6AE-F5EA3E2C6"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2506, 1.2163, -0.0992]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(4C841EB3-3400-4240-B2AD-6F49179BA"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3266, 1.2163, -0.2124]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(4909EAC3-8AC1-4A2F-AADC-2CA06ABA6"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3514, 1.2163, -0.1165]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(4651445D-26FE-4B9A-A549-DCC647A04"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3459, 1.2163, -0.1041]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(45753CB5-BBCD-47FF-8767-0253D59A0"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3523, 1.2164, -0.1316]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(43206BA9-7FCB-4EA5-BF03-8F964BD25"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2944, 1.2161, -0.0762]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(42AF01DE-AFA5-42EF-BCA7-4EF7D4091"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2685, 1.2163, -0.2129]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(426AD4D9-267A-4D6A-BE4A-53AF89654"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2704, 1.2163, -0.2142]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(414D6FD4-3CD6-4EFD-BB02-15B8FCBF0"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2723, 1.2163, -0.2153]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(4092CC71-82E5-4B12-B8AA-6BBCD080F"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2711, 1.2163, -0.0786]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(3EE3FFFF-4CDA-4165-BD1F-98DCD91D8"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3541, 1.2163, -0.1447]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(3E4FA5A5-78CD-445F-8405-1E16F1343"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3289, 1.2163, -0.0832]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(3C6E0F43-6D57-499F-89B6-281B02802"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2444, 1.2163, -0.1111]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(3C002042-3737-46AA-969D-BE52D572C"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3301, 1.2163, -0.2096]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(3947CDD7-CAE5-4DEC-BA36-6D022FEE8"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2827, 1.2164, -0.0766]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(35C3F65D-E124-4B34-B9D2-796CB55D4"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3272, 1.2163, -0.0819]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(34638BCD-D786-4B2F-BD92-D7093DDE0"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2616, 1.2163, -0.2073]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(34264DA1-ABDF-48AF-A197-3055CFB31"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.242, 1.2163, -0.1175]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(338AB265-4323-4F8D-AA40-915F7A8B7"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3436, 1.2163, -0.1002]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(32A0101C-8AF3-4363-B821-A770C9BA8"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2401, 1.2163, -0.124]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(3280376B-7E92-4113-9FB6-8C1BA6800"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2462, 1.2163, -0.107]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(2FD336CC-9736-4F43-B2B4-43909692F"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3532, 1.2163, -0.1716]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(2B1C95B6-52F2-4FBF-864C-E7CACEC41"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.284, 1.2164, -0.2171]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(2AB990AB-D231-484F-94D6-9BE5D0207"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3448, 1.2163, -0.1021]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(29BF5612-0DC2-46DD-87D9-5F26FDE57"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3512, 1.2163, -0.1781]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(27FF319A-0080-4A58-B4D8-1FB3BE433"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.245, 1.2163, -0.1833]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(271996D3-4EF5-4E6E-BC45-BA47CBE34"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2574, 1.2163, -0.0901]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(26F1C36E-57BB-41E6-AF81-C41873C1B"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2958, 1.2163, -0.2199]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(24F69D76-B0EF-4D76-AB07-3608D7D88"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2459, 1.2163, -0.1853]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(24B11500-A6B7-408D-AEB2-CBCA97F68"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3324, 1.2163, -0.0862]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(22F41B5D-6DDC-43C4-B4A4-495FE7679"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3527, 1.2163, -0.1208]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(210CB983-2152-4F3E-8E1B-61D435843"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2907, 1.2167, -0.0782]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(20B10967-D739-44F3-91A5-AF11D004D"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2398, 1.2163, -0.1457]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(1EE75A1F-1849-4A6C-920C-0B39F030F"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3196, 1.2163, -0.077]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(17D58EE0-C960-46A6-ABFD-987E31BBB"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3284, 1.2163, -0.211]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(1764F9DD-BD6F-4C9C-A3AA-361E476AE"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2559, 1.2163, -0.0919]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(15DD86EB-13E3-4C80-B133-EF9C4382B"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2469, 1.2163, -0.1874]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(156D3C9F-C888-4DB8-8692-5E1965E65"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3408, 1.2163, -0.198]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(13F7D5C6-F375-4D1E-A4DA-C4636AE02"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3398, 1.2163, -0.0946]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(12E60577-9644-406F-9B31-364BB9E21"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3558, 1.2168, -0.1399]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(1183F193-331E-4E28-BB6B-C895A5C8A"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3394, 1.2163, -0.1998]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(118197F2-F2EF-45BE-B49B-B207E7A80"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.273, 1.2163, -0.0775]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(0E6586FF-C2DD-401E-B4B4-F03AA179A"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2555, 1.2163, -0.2006]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(08A8F88F-4E0D-4220-9E99-2C4CC4976"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2944, 1.2163, -0.0736]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(080954F3-0AF0-4D5B-B683-F203DCC3A"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3505, 1.2167, -0.1519]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(068B2D51-1162-45FC-9C6F-DBA9230D0"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2483, 1.2163, -0.103]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(0514FA7C-D1E2-4216-9070-CD9F24191"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2569, 1.2163, -0.2024]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(04230616-55FC-47C3-9AC2-F45146A02"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3534, 1.2163, -0.123]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(02449AA7-079F-46AC-BF01-93464AC19"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2975, 1.2163, -0.216]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(00FE2B44-FF49-4B76-9C87-614FB81DA"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3101, 1.2164, -0.0763]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["D�nd�r2(209AB329-14EA-46BC-8BC2-8A7F3A08F082)001"].geometry
        }
        material={materials["Material_0.012"]}
        position={[7.2969, 1.2531, -0.1467]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["D�nd�r1(40EA021B-6036-426C-A80F-2A53397C08D1)001"].geometry
        }
        material={materials["Material_0.012"]}
        position={[7.297, 1.1441, -0.1468]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(FE8EEEFF-5F9E-4762-959A-4B3EAC303FB6)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2262, 1.1332, -0.1474]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(FE2E5ECB-A395-40C8-84F4-3B0A385B9CCC)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3663, 1.1326, -0.1485]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(FCD8CE3F-27D9-440F-BB67-9F6B4DAEC49E)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2312, 1.1328, -0.1023]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(FC281527-79B5-431B-8464-9CB3873F7069)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3665, 1.1328, -0.1805]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(FBAEC10A-F3AA-4E26-B514-789B185AE463)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2266, 1.1328, -0.1157]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(F9F9864E-3244-4A1F-B275-8BFEDDA0832E)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2548, 1.1328, -0.2243]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(F9A0C037-0E69-4529-B8B5-6C58AE53AEE1)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2892, 1.1332, -0.061]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(F6039A41-9892-4E20-B2D2-801008BEDD34)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3673, 1.1328, -0.1778]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(F197D9EF-00FE-4DA2-83D2-72047EBF1B77)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2577, 1.1328, -0.0664]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(F0CF75B6-3062-468E-9594-DF016CF5D095)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2272, 1.1328, -0.1791]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(EFCA813F-EAE6-4BB6-A755-81D06884D8B7)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2622, 1.1328, -0.0631]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(EEE9771B-F3E3-4A81-8257-3C6FDF9C31EB)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.337, 1.1328, -0.0674]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(ED88880A-7BBC-4B85-9155-2B0B82B9161B)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3604, 1.1328, -0.1963]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(ED31ED1F-890B-4578-8C3B-AA7A5C54F57E)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2954, 1.1326, -0.2349]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(ED2FEBC3-240A-4206-9110-038138CF189F)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2599, 1.1328, -0.0647]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(EC7DCE7C-417D-4894-9813-791E2F42FAE2)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3607, 1.1328, -0.0985]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(EC33DA92-29D3-42C5-94F4-D8CE7A82BB61)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2282, 1.1328, -0.1102]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(EA87755E-42C7-427F-9A72-0C518BEF2269)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2527, 1.1328, -0.2224]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E9CB35D0-1798-4F04-968B-F5E05A9812E1)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2592, 1.1328, -0.2278]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E8D807E4-0D0F-4B04-8787-1730DEC705A8)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.304, 1.1334, -0.0542]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E5E28EAE-F815-4A1D-A054-F56BB2A398A4)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3684, 1.1327, -0.1442]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E2E39C30-2708-4F5B-8EDE-2319516BF017)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2638, 1.1328, -0.231]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E0F4EBA2-D0F5-45B4-8108-F1D5E8CB088C)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3522, 1.1328, -0.0839]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E0A4BAB9-D5D6-47AA-8B10-AF6B0B6B9C48)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2401, 1.1328, -0.2073]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E02132F5-3526-4336-A42B-AA74B7AA8A9E)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3488, 1.1328, -0.0794]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(DFBE3A88-3569-4C0D-9456-75C9C029FCBA)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3696, 1.1327, -0.1484]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(DF0FEDAF-8C15-44A6-B6FC-340281293C11)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2344, 1.1328, -0.1975]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(DD5EB998-C304-4B1B-BC18-D52436B5728A)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3464, 1.1328, -0.2175]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(DB4DB503-1FCB-4EFA-9503-EB26C89E08D9)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3518, 1.1328, -0.2109]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(DAF636D6-635F-46E3-AB4B-2A527EB94470)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3426, 1.1328, -0.2215]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D498B9D4-0956-4A02-8DC9-C974AD3D1C5B)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2977, 1.1328, -0.2333]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D4403DB6-A8A4-43F4-B37C-A720ECE44CB1)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3035, 1.1332, -0.061]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D0416C46-8FDD-4FCE-A12D-2A632A8B4A0D)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.228, 1.1328, -0.1818]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(CDCA10C2-DC9B-4A7E-934B-3B83E319D965)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2469, 1.1328, -0.2163]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(CDC51892-080D-4CE4-83AF-1F8F77318C6E)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3661, 1.1329, -0.1279]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(CBD1EFC1-A837-49EA-A0F9-B5C574497894)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2534, 1.1328, -0.07]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(CA68650E-83D3-4550-9240-0F8DB99D33BF)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3667, 1.1328, -0.1144]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C824F660-63A6-4278-A762-B340EB1CD3CE)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2439, 1.1328, -0.0803]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C7C8E8E2-8B28-4466-B1D9-7C064403A732)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.257, 1.1328, -0.2261]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C6F9A7CE-89B3-440B-BCC5-29939A9DB19D)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2386, 1.1328, -0.2049]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C6CEDE12-ABB5-40D9-961C-E5C36DAD9480)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2981, 1.1327, -0.0564]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C6AF202C-793F-410C-8131-415168268203)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3639, 1.1332, -0.1531]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C65AA5EF-48C5-4D5C-9850-64E212107A8B)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2661, 1.1328, -0.2325]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C594665A-7CC7-4A05-8C87-1BD5277978E5)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2274, 1.1329, -0.129]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C57B50A8-08C3-49A0-A27D-10484A815F05)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3051, 1.1332, -0.2323]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C4BF2B74-6BCE-4499-9B43-0B89018E5AF9)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3564, 1.1328, -0.2038]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C4A4B397-2691-40B9-B463-10C439357ED8)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2955, 1.1327, -0.2382]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(BE509062-AFBF-4874-A2A2-29592B3F7D54)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2507, 1.1328, -0.2204]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(BBDC13D4-D3FA-4D44-830F-AD329CFC1248)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3228, 1.1328, -0.0584]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(BB3769FF-A807-4E7E-AFFE-21E26A3A2229)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3553, 1.1328, -0.0886]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B63692B4-28CF-415F-BD51-161EEBCF55BA)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2301, 1.1328, -0.1049]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B3BE53F7-CFEE-4095-9C2E-9B2108B4D2F7)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3293, 1.1328, -0.2319]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B23628E8-62E4-4130-BAB1-E3476FBAB5AA)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2614, 1.1328, -0.2294]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B1374676-C96B-4DBE-B054-514C0E9B45B6)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3648, 1.1328, -0.1859]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B0771807-DA60-41EF-9ADA-D36C7DAE2797)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2475, 1.1328, -0.076]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(AF93B00D-14DA-4F1C-BC8C-A1C447A3511C)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3627, 1.1328, -0.1912]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(ADEB5637-E080-40C3-AD1F-ECCB74C6792B)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3595, 1.1328, -0.0959]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(AC8BA4EB-E868-4FB8-B8A1-12E46267FD04)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3363, 1.1328, -0.2271]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(AC3D0365-16F3-4695-BE08-6E26AE00470B)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3348, 1.1328, -0.0657]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(AB1B94D3-4762-47F1-86B4-0EC382F1C7CC)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.368, 1.1328, -0.1751]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(A8184014-465E-4115-B5BE-6FC57D826DD9)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2301, 1.1332, -0.1544]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(A617EC08-81F8-4990-9637-106DC26D91D0)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2375, 1.1328, -0.0897]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(A58F117A-4659-4ADD-95D0-14DD44D14D6B)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2301, 1.1332, -0.14]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(9DE599E1-7CFA-40E0-AB64-9C934A259A3B)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.239, 1.1328, -0.0873]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(9DC05779-9D0B-4C85-B5C8-EF639DB2FA63)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.365, 1.1328, -0.1089]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(9CDCF3A6-8814-4D10-93A1-41E6776A44DA)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3591, 1.1328, -0.1988]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(9825F525-CECB-4365-A7F5-C846D777A397)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3638, 1.1328, -0.1886]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(97E6BB8A-472D-4B98-95DF-A6B15C6E7E7F)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2883, 1.1334, -0.0543]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(97A2C239-968D-4ADE-B08D-947E40D5E94E)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2938, 1.1327, -0.0553]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(9788064C-198D-4149-9843-1283FEBE38FF)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.232, 1.1328, -0.1924]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(96DE0CFA-CE18-4E6D-81C6-A743D1D17F5D)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3678, 1.1332, -0.1461]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(96063551-5B46-43A3-A29F-1B5E0F95A272)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3325, 1.1328, -0.064]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(93CC825E-B255-4EB2-A37B-3CF334A0A61B)001"].geometry
        }
        material={materials["Material_0.012"]}
        position={[7.2969, 1.1266, -0.1468]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(92D38805-7F35-4C1D-BB56-8437537B0AC8)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2276, 1.1326, -0.1498]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(905814B3-5251-47AA-924C-4E8AEA910A26)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3149, 1.1329, -0.2345]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(90018D32-F770-4E92-9331-703CA7855466)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3445, 1.1328, -0.2195]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(8AD57CE5-6FD9-430C-9880-EFD5A4CB304E)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.334, 1.1328, -0.2288]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(884BC440-FB38-4FDF-A368-4DBE87D5F5BB)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3659, 1.1328, -0.1116]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(85690BD2-2D8A-4B6F-9C30-786BA165C7D3)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2371, 1.1328, -0.2025]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(80FA77A4-729A-4904-BA5F-40D55E0AB658)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.29, 1.1334, -0.2393]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7FB295FB-0B6C-452E-9262-66B0DDAE6095)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2686, 1.1328, -0.2338]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7F560C20-E700-44C7-A8E3-7E25D2E78DE7)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2292, 1.1328, -0.1475]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7F4FF1BA-9767-44FF-8313-357CEC5044B4)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2555, 1.1328, -0.0682]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7F1957FA-1B0C-41D0-8F35-49AD2CE57CFA)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2451, 1.1328, -0.2141]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7EA8E6C5-0C82-48E3-9964-D64E736720EB)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2978, 1.1332, -0.2364]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7C873E96-9ADA-4D59-B258-C1131E6F7FEE)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3582, 1.1328, -0.0934]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7ACF37D7-22EE-488E-AF3D-BA7B85C55AA6)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2291, 1.1328, -0.1075]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7A60620E-B5BF-4438-89EE-EE01DB6E245C)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3412, 1.1328, -0.0711]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7A600864-4027-4CD8-88A3-ED6A093D7661)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2938, 1.1326, -0.0586]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7847B453-AAB7-49FE-9328-4FE9324BB7CE)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2233, 1.1334, -0.1396]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(75E274C3-1562-4BB7-9EEA-C55349FDC8F3)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3432, 1.1328, -0.0731]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(710CF3F5-2C5E-425D-84A1-7E160D704AE7)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3483, 1.1328, -0.2153]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7018FA15-C988-4A91-9EA2-1DE446EDB2D7)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.363, 1.1328, -0.1037]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6EBBDACA-EAAB-4248-81B7-D86962AC24FD)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3302, 1.1328, -0.0624]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6E1703CD-6A0B-42CD-8827-1ED999A6835F)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2274, 1.1328, -0.1129]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6DDEF424-223A-4ED1-BD37-0FD1E75B239F)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3657, 1.1328, -0.1832]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(67DAC46A-02FA-4DDE-8347-B796255B81BF)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2309, 1.1328, -0.1898]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(654805AF-C405-4DC5-A289-F9014C2125B1)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3706, 1.1334, -0.1539]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(64873429-C654-4098-BD0C-2C85B681DA53)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2264, 1.1328, -0.1764]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(636C987C-D0B0-418D-B74F-D341768C2F93)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2792, 1.1329, -0.059]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6305F1F5-94E0-4202-ACAF-ACF5B4F21F96)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2494, 1.1328, -0.0739]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(617DB0EF-DC65-4546-B89F-A9760FC9432F)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2908, 1.1332, -0.2325]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(608EE3F7-0753-45F2-931B-86DCB52BB118)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3405, 1.1328, -0.2235]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(5CBD0813-A9DD-440E-998A-5D88F26AE388)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3133, 1.1329, -0.0587]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(5A7912C9-A677-485F-BD34-1B35FE63C68B)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2962, 1.1333, -0.0571]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(58F50368-D813-40DC-A254-E2A1713DD128)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2332, 1.1328, -0.195]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(58038A52-9F08-4AE3-96AD-522AB260A3BA)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3549, 1.1328, -0.2062]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(57E36721-3170-41DA-A749-9130A7DF604D)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3568, 1.1328, -0.091]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(56F2BD32-51CB-4321-94D0-EF55726A3494)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2348, 1.1328, -0.0946]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(5603B455-0BA9-4FEE-BCA6-E6AD7D0CB01F)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3705, 1.1334, -0.1383]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(53072285-B534-4CDB-B861-CC44BFA12974)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2417, 1.1328, -0.2096]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(529DE5C1-C02E-44D9-8B04-56A874932EFC)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3506, 1.1328, -0.0816]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(51E5DAC6-2091-4D96-BF08-629148B24A40)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3501, 1.1328, -0.2131]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(51CACBF9-56AA-4847-B7FE-F8F7DEBC4DFB)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3384, 1.1328, -0.2253]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(511F9212-50E4-4F67-9D32-216008D346E4)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2234, 1.1334, -0.1552]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(48EFF6DC-1E3F-4630-9260-978DFC0CA165)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2513, 1.1328, -0.072]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(48C2A74F-93BA-481A-9C75-165DBE69E575)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.347, 1.1328, -0.0772]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(481F2094-193D-4F5C-A0F9-26A0357A6B4F)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3616, 1.1328, -0.1938]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(477D4999-6C03-47EA-A401-D72BF69C8AEE)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2422, 1.1328, -0.0826]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(454958E9-F961-4B99-A1A3-5C77410061CE)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2289, 1.1328, -0.1845]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(45007197-F8AB-4F65-9A18-FE2F8A92820A)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2645, 1.1328, -0.0616]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(4473AAD9-870D-4F1B-BDA2-E1F27878E14E)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2695, 1.1328, -0.0589]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(43287E64-0952-4C09-BECD-E2F671ECA96F)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3578, 1.1328, -0.2013]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(41F3A411-1AD5-401C-A2B3-15EA1B5EFA53)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3637, 1.1332, -0.1388]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(407E7FFE-CF2E-4A59-85BD-CF60D0491619)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2244, 1.1327, -0.1498]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(3F08D7D3-D964-4EC1-AFFD-F3F074703907)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2299, 1.1328, -0.1871]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(3E93FDC2-12E7-49CD-AC48-BC4CE00811BB)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3534, 1.1328, -0.2086]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(3E443E09-EAED-4891-89DD-5366CECE38A9)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2335, 1.1328, -0.0971]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(3AC8C3EC-CE5D-456C-963D-3B3EACCF62F0)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2807, 1.1329, -0.2348]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(39383EC6-2051-4A3F-B179-77C91D7CA443)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2357, 1.1328, -0.2]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(38C06B96-A9B0-4117-9218-CE1CD62B1DB4)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3675, 1.1328, -0.1171]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(36B941B6-DA8A-48A8-8439-2FBCDA8A0D06)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2405, 1.1328, -0.0849]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(358B1457-BDC8-479C-A651-12CE2100F470)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3317, 1.1328, -0.2304]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(348B58EE-AD39-45F7-A312-6D928154F7B6)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2456, 1.1328, -0.0781]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(347DA58B-561A-4787-A195-F15561299F25)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2255, 1.1327, -0.1455]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(2A6DC66E-2DF6-45D3-8715-AD091CF7158B)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3244, 1.1328, -0.2346]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(2A60CE94-DB75-4882-9230-5AA806D0F2BC)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3253, 1.1328, -0.0597]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(25993D90-E7BC-4D7A-996B-90F78231653F)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2323, 1.1328, -0.0997]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(250FD97B-245D-4436-9373-3462C6AE0B52)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.296, 1.1328, -0.0602]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(1FFAB004-2CED-4450-B713-688B5B24F003)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3664, 1.1329, -0.1643]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(1D644BC6-83B5-4098-B999-FF99876FCC89)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2278, 1.1329, -0.1656]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(1C6B61DC-1143-4476-9DC3-4D5A3A629B8D)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2711, 1.1328, -0.2351]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(1B6B5984-618A-46C8-9853-C05289B91564)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2997, 1.1327, -0.237]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(199BA0F1-1A29-4F43-853F-DFCD989C8957)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3278, 1.1328, -0.061]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(199062D5-2135-4951-A240-039086304CF3)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.364, 1.1328, -0.1063]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(18CF5D8F-218A-4052-8A52-E2E456FFB689)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2259, 1.1328, -0.1184]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(17FD5804-DB07-44ED-ABC8-4F36055DDC64)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3647, 1.1328, -0.1463]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(144E061E-1BF2-459D-A750-0344AD0B1EEE)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3057, 1.1334, -0.2391]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(12FB1BDD-E8C3-49EA-8E66-D2E9D295EB12)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3391, 1.1328, -0.0692]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(11D38F1A-BE25-4491-967F-A7291814540B)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.267, 1.1328, -0.0602]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(10633D66-5BA7-4258-AC0E-B62EE976B744)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2361, 1.1328, -0.0921]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(0DC72141-0673-4F4B-80DC-19B727764909)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2487, 1.1328, -0.2184]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(0C09AF94-CD0E-4D8B-90D6-3CC5F86D280C)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3451, 1.1328, -0.0751]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(0ADEDA52-540E-48A1-B617-4340E360C762)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.2434, 1.1328, -0.2119]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(0690C294-1C73-41C1-B78E-3ABC8809E86D)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3269, 1.1328, -0.2333]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(031CF3B9-7486-43FB-AB9D-B1A61FBD1F94)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3539, 1.1328, -0.0862]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(01D0218D-91E6-4CBE-B8DD-7E232CC08E8C)001"].geometry
        }
        material={materials["Material_1.011"]}
        position={[7.3619, 1.1328, -0.1011]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Ay�r3(86A3B0D0-0C78-4ED3-A59E-5AEDA9E5ED4C)001"].geometry
        }
        material={materials["Material_0.012"]}
        position={[7.297, 1.3117, -0.1462]}
        rotation={[Math.PI, -1.5615, Math.PI]}
      />
      <group
        position={[7.0668, 1.0922, 0.3182]}
        rotation={[0, 0.6126, -1.8151]}
        scale={0.6701}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001.geometry}
          material={materials["Material.008"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001_1.geometry}
          material={materials.Material}
        />
      </group>
      <group
        position={[7.7927, 1.1313, 0.1248]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[0.0113, 0.0113, 0.024]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.CandleSet01_Stick03_geo_Candles_MAT_0002.geometry}
          material={materials.Candles_MAT}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.CandleSet01_Stick03_geo_Candles_MAT_0002_1.geometry}
          material={materials.Wick_MAT}
        />
      </group>
      <group
        position={[7.1827, 1.2202, 0.0774]}
        rotation={[-Math.PI / 2, 0, 1.9177]}
        scale={[0.0113, 0.0113, 0.024]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.CandleSet01_Stick03_geo_Candles_MAT_0003.geometry}
          material={materials.Candles_MAT}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.CandleSet01_Stick03_geo_Candles_MAT_0003_1.geometry}
          material={materials.Wick_MAT}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus2(96CBFDF1-2F8C-44B7-A281-C31F323C64D0)002"].geometry
        }
        material={materials.Material_0}
        position={[0.9126, 0.074, 3.9033]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus2(96CBFDF1-2F8C-44B7-A281-C31F323C64D0)003"].geometry
        }
        material={materials.Material_0}
        position={[7.1816, 1.092, 0.0769]}
        rotation={[-Math.PI, 1.2239, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane056.geometry}
        material={materials["Material.016"]}
        position={[7.4974, 1.0825, 0]}
        scale={0.7889}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.TableMesh1.geometry}
        material={materials.TableType_A_Mat}
        position={[7.4974, 0.0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.0116}
      />
    </group>
  );
}

useGLTF.preload("/AtaMasa.glb");
