import { Suspense, useState, useEffect, useMemo} from "react";
import { Canvas, useFrame, useLoader, extend, useThree } from "@react-three/fiber";
import { Loader, Environment, OrbitControls, Sky, Cloud, BakeShadows} from "@react-three/drei";


// import { Perf } from 'r3f-perf';
import { Ground2 } from "./Ground2"
import { Ground3 } from "./Ground3"
import { Ground4 } from "./Ground4"
import { Ground5 } from "./Ground5"
import { Ground6 } from "./Ground6"
import { Ground7 } from "./Ground7"
import { Ground8 } from "./Ground8"
import { Ground9 } from "./Ground9"
import { Ground10 } from "./Ground10"
import { Ground11 } from "./Ground11"
import { Ground12 } from "./Ground12"
import { Ground13 } from "./Ground13"
import { Ground14 } from "./Ground14"
import { Ground15 } from "./Ground15"
import { Ground16 } from "./Ground16"
import { Ground17 } from "./Ground17"
import { Ground18 } from "./Ground18"
import { Ground19 } from "./Ground19"
import { Ground20 } from "./Ground20"
import { Ground21 } from "./Ground21"
import { Ground22 } from "./Ground22"
import { Ground23 } from "./Ground23"
import { Ground24 } from "./Ground24"
import { Ground25 } from "./Ground25"
import { Ground26 } from "./Ground26"
import { Ground27 } from "./Ground27"
import { Ground28 } from "./Ground28"
import { Ground29 } from "./Ground29"
import { Ground30 } from "./Ground30"
import { Ground31 } from "./Ground31"

import { Ground33 } from "./Ground33"
import { Ground34 } from "./Ground34"
import { Ground35 } from "./Ground35"
import { Ground36 } from "./Ground36"
import { Ground37 } from "./Ground37"
import { Ground38 } from "./Ground38"
import { Ground39 } from "./Ground39"
import { Ground40 } from "./Ground40"

import MainStage from "./MainStage";
import * as THREE from 'three'
import React, {  useRef } from "react";
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { Water } from 'three-stdlib'

extend({ Water })

function Ocean() {
  const ref = useRef()
  const gl = useThree((state) => state.gl)
  const waterNormals = useLoader(THREE.TextureLoader, '/waternormals.jpeg')
  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping
  const geom = useMemo(() => new THREE.PlaneGeometry( 10000, 10000 ), [])
  const config = useMemo(
    () => ({
      textureWidth: 512,
      textureHeight: 512,
      waterNormals,
      sunDirection: new THREE.Vector3(),
      sunColor: 0xFF0000,
      waterColor: "#ADD8E6",
      distortionScale: 7,
      fog: false,
      format: gl.encoding,
      
    }),
    [waterNormals]
  )
  useFrame((state, delta) => (ref.current.material.uniforms.time.value += delta*0.2))
  return <water ref={ref}  scale={0.02} position-y =  {0.1}   position-z =  {104.2}  position-x =  {0}        args={[geom, config]} rotation-x={-Math.PI / 2} />
}




export default function Viewer() {



const directionalLight = useRef()
  

    const ref = useRef()
    const marker = useRef()
    const marker2 = useRef()
    const [lerping, setLerping] = useState(false)
    const [to, setTo] = useState(new THREE.Vector3(0, 2, 0))
    const [target, setTarget] = useState(new THREE.Vector3(0, 2, 0))

    const [target2, setTarget2] = useState(new THREE.Vector3(0, 0.3, 0))
    
   
   
   const sunPosition= new THREE.Vector3(1, 0, 3)       


    const vec = new THREE.Vector3()
    const texture = useLoader(TextureLoader, 'marker2.png')


 
    
    function Animate({ controls, lerping, to, target}) {
    useFrame(state => {
      

      if(lerping){
  state.camera.position.lerp(to, .020)  
    controls.current.target.lerp(target, .022)
    
      }
  
    })
  }

  function Animate2({ target2}) {
    useFrame(state => {
      marker.current.position.lerp(target2, 0.2)

    })
  }
  
  function gotoAnnotation(e) {

    setTo(new THREE.Vector3(e.point.x, 1.5, e.point.z) )
    setTarget(new THREE.Vector3(e.point.x, 1.5, e.point.z));
    setLerping(true)

    document.getElementById("product-buttons-container").style.display="block";
    document.getElementById("product-popup-container").style.visibility="hidden";
    document.getElementById("product-container-close-button").style.display="block";
  }

  function gotoAnnotation3(e) {
    setTo(new THREE.Vector3(0, 1.5, 0) )
    setTarget(new THREE.Vector3(0, 1.507, 0))
   
    setLerping(true)

    
  
    
    
  }

  function gotoAnnotation4(e) {
    setTo(new THREE.Vector3(-5.50, 1.5, -2.8) )
    setTarget(new THREE.Vector3(-5.50, 1.2, -3.4))
  
    setLerping(true)

    
  }
 
  function gotoAnnotation5(e) {
    setTo(new THREE.Vector3(-1.5, 1.5, -2.5) )
    setTarget(new THREE.Vector3(-1.5, 1.2, -3.1))
    
    setLerping(true)

    
    
    
    
  }

 
  function gotoAnnotation6(e) {
    setTo(new THREE.Vector3(2.48, 1.8, -1.4) )
    setTarget(new THREE.Vector3(2.48, 1.5, -2.0))
    
    
    setLerping(true)

  }

  function gotoAnnotation7(e) {
    setTo(new THREE.Vector3(  6.45, 1.8, -1.5) )
    setTarget(new THREE.Vector3(  6.45, 1.5, -2.1))
    
    setLerping(true)

   
    
    
    
  }

  function gotoAnnotation8(e) {
    setTo(new THREE.Vector3(-5.55, 1.7, 2.5) )
    setTarget(new THREE.Vector3(-5.55, 1.4, 3.1))
    
    setLerping(true)

    
 
    
    
  }

  function gotoAnnotation9(e) {
    setTo(new THREE.Vector3(-1.55, 1.7, 2.0) )
    setTarget(new THREE.Vector3(-1.55, 1.4, 2.6))
    
    setLerping(true)

    
   
    
    
  }

  
  function gotoAnnotation10(e) {
    setTo(new THREE.Vector3(2.48, 1.7, 2.3) )
    setTarget(new THREE.Vector3(2.48, 1.4, 2.9))
    
    setLerping(true)


    
    
  }

  function gotoAnnotation11(e) {
    setTo(new THREE.Vector3(6.5, 1.7, 2.3) )
    setTarget(new THREE.Vector3(6.5, 1.4, 2.9))
    
    setLerping(true)

    
    
  }
{/* //100YılYMT// */}
  
  function gotoAnnotation12(e) {
    setTo(new THREE.Vector3(-1.49, 0.5, 3.5) )
    setTarget(new THREE.Vector3(-1.49, 0.5, 3.5))
    
    setLerping(true)

    document.getElementById("qrimage").src="qr/p3-c1.png"
    document.getElementById("product-buttons-container").style.display="none";
    document.getElementById("main-buttons-container").style.display="block";
    document.getElementById("product-popup-container").style.visibility="visible";
    document.getElementById("product-atam-masa-info-container").style.display="none";
    document.getElementById("product-ana-masa-info-container").style.display="none";
    document.getElementById("product-1-info-container").style.display="none";
    document.getElementById("product-2-info-container").style.display="none";
    document.getElementById("product-3-info-container").style.display="block";
    document.getElementById("product-4-info-container").style.display="none";
    document.getElementById("product-5-info-container").style.display="none";
    document.getElementById("product-6-info-container").style.display="none";
    document.getElementById("product-7-info-container").style.display="none";
    document.getElementById("product-8-info-container").style.display="none";
    document.getElementById("product-container-close-button").style.display="none";
  

  }

  function gotoAnnotation13(e) {
    setTo(new THREE.Vector3(-1.78, 0.5, 3.5) )
    setTarget(new THREE.Vector3(-1.78, 0.5, 3.5))
    
    setLerping(true)

    document.getElementById("qrimage").src="qr/p3-c1.png"
    document.getElementById("product-buttons-container").style.display="none";
    document.getElementById("main-buttons-container").style.display="block";
    document.getElementById("product-popup-container").style.visibility="visible";
    document.getElementById("product-atam-masa-info-container").style.display="none";
    document.getElementById("product-ana-masa-info-container").style.display="none";
    document.getElementById("product-1-info-container").style.display="none";
    document.getElementById("product-2-info-container").style.display="none";
    document.getElementById("product-3-info-container").style.display="block";
    document.getElementById("product-4-info-container").style.display="none";
    document.getElementById("product-5-info-container").style.display="none";
    document.getElementById("product-6-info-container").style.display="none";
    document.getElementById("product-7-info-container").style.display="none";
    document.getElementById("product-8-info-container").style.display="none";
    document.getElementById("product-container-close-button").style.display="none";
  

  }


  function gotoAnnotation14(e) {
    
    
    setTo(new THREE.Vector3(-1.26, 0.9, 3.9) )
    setTarget(new THREE.Vector3(-1.26, 0.9, 3.9))
    setLerping(true)

    document.getElementById("qrimage").src="qr/p3-c1.png"
    document.getElementById("product-buttons-container").style.display="none";
    document.getElementById("main-buttons-container").style.display="block";
    document.getElementById("product-popup-container").style.visibility="visible";
    document.getElementById("product-atam-masa-info-container").style.display="none";
    document.getElementById("product-ana-masa-info-container").style.display="none";
    document.getElementById("product-1-info-container").style.display="none";
    document.getElementById("product-2-info-container").style.display="none";
    document.getElementById("product-3-info-container").style.display="block";
    document.getElementById("product-4-info-container").style.display="none";
    document.getElementById("product-5-info-container").style.display="none";
    document.getElementById("product-6-info-container").style.display="none";
    document.getElementById("product-7-info-container").style.display="none";
    document.getElementById("product-8-info-container").style.display="none";
    document.getElementById("product-container-close-button").style.display="none";
  

  }

  function gotoAnnotation15(e) {
    setTo(new THREE.Vector3(-1.80, 0.7, 3.9) )
    setTarget(new THREE.Vector3(-1.80, 0.7, 3.9))
    
    setLerping(true)

    document.getElementById("qrimage").src="qr/p3-c1.png"
    document.getElementById("product-buttons-container").style.display="none";
    document.getElementById("main-buttons-container").style.display="block";
    document.getElementById("product-popup-container").style.visibility="visible";
    document.getElementById("product-atam-masa-info-container").style.display="none";
    document.getElementById("product-ana-masa-info-container").style.display="none";
    document.getElementById("product-1-info-container").style.display="none";
    document.getElementById("product-2-info-container").style.display="none";
    document.getElementById("product-3-info-container").style.display="block";
    document.getElementById("product-4-info-container").style.display="none";
    document.getElementById("product-5-info-container").style.display="none";
    document.getElementById("product-6-info-container").style.display="none";
    document.getElementById("product-7-info-container").style.display="none";
    document.getElementById("product-8-info-container").style.display="none";
    document.getElementById("product-container-close-button").style.display="none";
  

  }


  function gotoAnnotation16(e) {
    setTo(new THREE.Vector3(-1.33, 1.1, 4.3) )
    setTarget(new THREE.Vector3(-1.33, 1.1, 4.3))
    
    setLerping(true)

    document.getElementById("qrimage").src="qr/p3-c1.png"
    document.getElementById("product-buttons-container").style.display="none";
    document.getElementById("main-buttons-container").style.display="block";
    document.getElementById("product-popup-container").style.visibility="visible";
    document.getElementById("product-atam-masa-info-container").style.display="none";
    document.getElementById("product-ana-masa-info-container").style.display="none";
    document.getElementById("product-1-info-container").style.display="none";
    document.getElementById("product-2-info-container").style.display="none";
    document.getElementById("product-3-info-container").style.display="block";
    document.getElementById("product-4-info-container").style.display="none";
    document.getElementById("product-5-info-container").style.display="none";
    document.getElementById("product-6-info-container").style.display="none";
    document.getElementById("product-7-info-container").style.display="none";
    document.getElementById("product-8-info-container").style.display="none";
    document.getElementById("product-container-close-button").style.display="none";
  

    
  }


  function gotoAnnotation17(e) {
    setTo(new THREE.Vector3(-1.92, 1.0, 4.32) )
    setTarget(new THREE.Vector3(-1.92, 1.0, 4.32))
    
    setLerping(true)

    
    document.getElementById("qrimage").src="qr/p3-c1.png"
    document.getElementById("product-buttons-container").style.display="none";
    document.getElementById("main-buttons-container").style.display="block";
    document.getElementById("product-popup-container").style.visibility="visible";
    document.getElementById("product-atam-masa-info-container").style.display="none";
    document.getElementById("product-ana-masa-info-container").style.display="none";
    document.getElementById("product-1-info-container").style.display="none";
    document.getElementById("product-2-info-container").style.display="none";
    document.getElementById("product-3-info-container").style.display="block";
    document.getElementById("product-4-info-container").style.display="none";
    document.getElementById("product-5-info-container").style.display="none";
    document.getElementById("product-6-info-container").style.display="none";
    document.getElementById("product-7-info-container").style.display="none";
    document.getElementById("product-8-info-container").style.display="none";
    document.getElementById("product-container-close-button").style.display="none";
  
    
    
  }


   {/* //100YılYMT// */}

 {/* //ilke// */}
   function gotoAnnotation18(e) {
    setTo(new THREE.Vector3(  -5.65, 1.1, -4.0) )
    setTarget(new THREE.Vector3( -5.65, 1.1, -4.0))
    
    setLerping(true)

    document.getElementById("qrimage").src="qr/p4-c1.png"
    document.getElementById("product-buttons-container").style.display="none";
    document.getElementById("main-buttons-container").style.display="block";
    document.getElementById("product-popup-container").style.visibility="visible";
    document.getElementById("product-atam-masa-info-container").style.display="none";
    document.getElementById("product-ana-masa-info-container").style.display="none";
    document.getElementById("product-1-info-container").style.display="none";
    document.getElementById("product-2-info-container").style.display="none";
    document.getElementById("product-3-info-container").style.display="none";
    document.getElementById("product-4-info-container").style.display="block";
    document.getElementById("product-5-info-container").style.display="none";
    document.getElementById("product-6-info-container").style.display="none";
    document.getElementById("product-7-info-container").style.display="none";
    document.getElementById("product-8-info-container").style.display="none";
    document.getElementById("product-container-close-button").style.display="none";
  

  
    
  }

  function gotoAnnotation19(e) {
    setTo(new THREE.Vector3(  -5.4, 1.0, -3.6) )
    setTarget(new THREE.Vector3( -5.4, 1.0, -3.6))
    
    setLerping(true)

    document.getElementById("qrimage").src="qr/p4-c1.png"
    document.getElementById("product-buttons-container").style.display="none";
    document.getElementById("main-buttons-container").style.display="block";
    document.getElementById("product-popup-container").style.visibility="visible";
    document.getElementById("product-atam-masa-info-container").style.display="none";
    document.getElementById("product-ana-masa-info-container").style.display="none";
    document.getElementById("product-1-info-container").style.display="none";
    document.getElementById("product-2-info-container").style.display="none";
    document.getElementById("product-3-info-container").style.display="none";
    document.getElementById("product-4-info-container").style.display="block";
    document.getElementById("product-5-info-container").style.display="none";
    document.getElementById("product-6-info-container").style.display="none";
    document.getElementById("product-7-info-container").style.display="none";
    document.getElementById("product-8-info-container").style.display="none";
    document.getElementById("product-container-close-button").style.display="none";
  

  
  }
 {/* //ilke// */}

 {/* //Halide// */}

  function gotoAnnotation20(e) {
    setTo(new THREE.Vector3(  -1.5, 1.0, -3.7) )
    setTarget(new THREE.Vector3( -1.5, 1.0, -3.7))
    
    setLerping(true)

    document.getElementById("qrimage").src="qr/p5-c1.png"
    document.getElementById("product-buttons-container").style.display="none";
    document.getElementById("main-buttons-container").style.display="block";
    document.getElementById("product-popup-container").style.visibility="visible";
    document.getElementById("product-atam-masa-info-container").style.display="none";
    document.getElementById("product-ana-masa-info-container").style.display="none";
    document.getElementById("product-1-info-container").style.display="none";
    document.getElementById("product-2-info-container").style.display="none";
    document.getElementById("product-3-info-container").style.display="none";
    document.getElementById("product-4-info-container").style.display="none";
    document.getElementById("product-5-info-container").style.display="block";
    document.getElementById("product-6-info-container").style.display="none";
    document.getElementById("product-7-info-container").style.display="none";
    document.getElementById("product-8-info-container").style.display="none";
    document.getElementById("product-container-close-button").style.display="none";
  

    
  }
{/* //Halide// */}

 {/* //araf// */}

 function gotoAnnotation21(e) {
  setTo(new THREE.Vector3(  2.03, 0.85, -3.8) )
  setTarget(new THREE.Vector3( 2.03, 0.85, -3.8))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p6-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="block";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";


  
}


function gotoAnnotation22(e) {
  setTo(new THREE.Vector3(  2.3, 0.95, -3.8) )
  setTarget(new THREE.Vector3( 2.3, 0.95, -3.8))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p6-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="block";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";

 
}

function gotoAnnotation23(e) {
  setTo(new THREE.Vector3(  2.67, 1.0, -3.8) )
  setTarget(new THREE.Vector3( 2.67, 1.0, -3.8))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p6-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="block";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";

 
}

function gotoAnnotation24(e) {
  setTo(new THREE.Vector3(  2.95, 1.0, -3.8) )
  setTarget(new THREE.Vector3( 2.95, 1.0, -3.8))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p6-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="block";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";

 
}

function gotoAnnotation25(e) {
  setTo(new THREE.Vector3(  2.47, 1.3, -4.2) )
  setTarget(new THREE.Vector3( 2.47, 1.3, -4.2))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p6-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="block";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";

 
}

{/* //araf// */}

{/* //latife// */}

function gotoAnnotation26(e) {
  setTo(new THREE.Vector3(  6.125, 1.0, -3.2) )
  setTarget(new THREE.Vector3( 6.125, 1.0, -3.2))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p8-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="block";
  document.getElementById("product-container-close-button").style.display="none";

 
}

function gotoAnnotation27(e) {
  setTo(new THREE.Vector3(  6.56, 1.0, -3.4) )
  setTarget(new THREE.Vector3( 6.56, 1.0, -3.4))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p8-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="block";
  document.getElementById("product-container-close-button").style.display="none";

 
}

function gotoAnnotation28(e) {
  setTo(new THREE.Vector3(  6.9, 1.0, -3.4) )
  setTarget(new THREE.Vector3( 6.9, 1.0, -3.4))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p8-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="block";
  document.getElementById("product-container-close-button").style.display="none";

 
}



function gotoAnnotation29(e) {
  setTo(new THREE.Vector3(  6.125, 1.2, -3.7) )
  setTarget(new THREE.Vector3( 6.125, 1.2, -3.7))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p8-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="block";
  document.getElementById("product-container-close-button").style.display="none";

 
}

function gotoAnnotation30(e) {
  setTo(new THREE.Vector3(  6.35, 1.1, -3.7) )
  setTarget(new THREE.Vector3( 6.35, 1.1, -3.7))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p8-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="block";
  document.getElementById("product-container-close-button").style.display="none";

 
}

function gotoAnnotation31(e) {
  setTo(new THREE.Vector3(  6.9, 1.0, -3.9) )
  setTarget(new THREE.Vector3( 6.9, 1.0, -3.9))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p8-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="block";
  document.getElementById("product-container-close-button").style.display="none";

 
}

function gotoAnnotation32(e) {
  setTo(new THREE.Vector3(  5.90, 1.3, -4.3) )
  setTarget(new THREE.Vector3( 5.90, 1.3, -4.3))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p8-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="block";
  document.getElementById("product-container-close-button").style.display="none";

 
}

function gotoAnnotation33(e) {
  setTo(new THREE.Vector3(  6.45, 1.2, -4.3) )
  setTarget(new THREE.Vector3( 6.45, 1.2, -4.3))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p8-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="block";
  document.getElementById("product-container-close-button").style.display="none";

 
}

function gotoAnnotation34(e) {
  setTo(new THREE.Vector3(  7.1, 1.1, -4.3) )
  setTarget(new THREE.Vector3( 7.1, 1.1, -4.3))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p8-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="block";
  document.getElementById("product-container-close-button").style.display="none";

 
}
{/* //latife// */}

{/* //cumhur// */}
function gotoAnnotation35(e) {
  setTo(new THREE.Vector3(  6.5, 1.0, 3.7) )
  setTarget(new THREE.Vector3( 6.5, 1.0, 3.7))
  
  setLerping(true)
 
  document.getElementById("qrimage").src="qr/p1-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="block";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";

}

function gotoAnnotation36(e) {
  setTo(new THREE.Vector3(  6.4, 1.0, 3.8) )
  setTarget(new THREE.Vector3( 6.4, 1.0, 3.8))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p1-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="block";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";

 
}
{/* //cumhur// */}

{/* //egemen// */}

function gotoAnnotation37(e) {
  setTo(new THREE.Vector3(  2.55, 1.0, 3.7) )
  setTarget(new THREE.Vector3( 2.55, 1.0, 3.7))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p2-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="block";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";

 
}

function gotoAnnotation38(e) {
  setTo(new THREE.Vector3(  2.6, 1.1, 3.75) )
  setTarget(new THREE.Vector3( 2.6, 1.1, 3.75))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p2-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="block";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";

 
}

function gotoAnnotation39(e) {
  setTo(new THREE.Vector3(  2.3, 1.0, 3.65) )
  setTarget(new THREE.Vector3( 2.3, 1.0, 3.65))
  
  setLerping(true)
 
  document.getElementById("qrimage").src="qr/p2-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="block";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";

}


{/* //egemen// */}
{/* //tulu// */}

function gotoAnnotation40(e) {
  setTo(new THREE.Vector3( -5.4, 1.1, 3.8) )
  setTarget(new THREE.Vector3( -5.4, 1.1, 3.8))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p7-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="block";
  document.getElementById("product-8-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";

 
}

function gotoAnnotation41(e) {
  setTo(new THREE.Vector3( -5.7, 1.1, 3.9) )
  setTarget(new THREE.Vector3( -5.7, 1.1, 3.9))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p7-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="block";
  document.getElementById("product-8-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";

 
}


{/* //tulu// */}


{/* //tablo// */}

function gotoAnnotation42(e) {
  setTo(new THREE.Vector3(8.46, 1.6, 1.6) )
    setTarget(new THREE.Vector3(8.46, 1.6, 2.2))
    
    setLerping(true)

    document.getElementById("qrimage").src="qr/p1-c1.png"
    document.getElementById("product-buttons-container").style.display="none";
    document.getElementById("main-buttons-container").style.display="block";
    document.getElementById("product-popup-container").style.visibility="visible";
    document.getElementById("product-atam-masa-info-container").style.display="none";
    document.getElementById("product-ana-masa-info-container").style.display="none";
    document.getElementById("product-1-info-container").style.display="block";
    document.getElementById("product-2-info-container").style.display="none";
    document.getElementById("product-3-info-container").style.display="none";
    document.getElementById("product-4-info-container").style.display="none";
    document.getElementById("product-5-info-container").style.display="none";
    document.getElementById("product-6-info-container").style.display="none";
    document.getElementById("product-7-info-container").style.display="none";
    document.getElementById("product-8-info-container").style.display="none";
    document.getElementById("product-container-close-button").style.display="none";
  

 
}

function gotoAnnotation43(e) {
  setTo(new THREE.Vector3(4.46, 1.6, 1.6) )
    setTarget(new THREE.Vector3(4.46, 1.6, 2.2))
    
    setLerping(true)

    document.getElementById("qrimage").src="qr/p2-c1.png"
    document.getElementById("product-buttons-container").style.display="none";
    document.getElementById("main-buttons-container").style.display="block";
    document.getElementById("product-popup-container").style.visibility="visible";
    document.getElementById("product-atam-masa-info-container").style.display="none";
    document.getElementById("product-ana-masa-info-container").style.display="none";
    document.getElementById("product-1-info-container").style.display="none";
    document.getElementById("product-2-info-container").style.display="block";
    document.getElementById("product-3-info-container").style.display="none";
    document.getElementById("product-4-info-container").style.display="none";
    document.getElementById("product-5-info-container").style.display="none";
    document.getElementById("product-6-info-container").style.display="none";
    document.getElementById("product-7-info-container").style.display="none";
    document.getElementById("product-8-info-container").style.display="none";
    document.getElementById("product-container-close-button").style.display="none";
  
 
}

function gotoAnnotation44(e) {
  setTo(new THREE.Vector3(0.46, 1.6, 1.6) )
    setTarget(new THREE.Vector3(0.46, 1.6, 2.2))
    
    setLerping(true)

    document.getElementById("qrimage").src="qr/p3-c1.png"
    document.getElementById("product-buttons-container").style.display="none";
    document.getElementById("main-buttons-container").style.display="block";
    document.getElementById("product-popup-container").style.visibility="visible";
    document.getElementById("product-atam-masa-info-container").style.display="none";
    document.getElementById("product-ana-masa-info-container").style.display="none";
    document.getElementById("product-1-info-container").style.display="none";
    document.getElementById("product-2-info-container").style.display="none";
    document.getElementById("product-3-info-container").style.display="block";
    document.getElementById("product-4-info-container").style.display="none";
    document.getElementById("product-5-info-container").style.display="none";
    document.getElementById("product-6-info-container").style.display="none";
    document.getElementById("product-7-info-container").style.display="none";
    document.getElementById("product-8-info-container").style.display="none";
    document.getElementById("product-container-close-button").style.display="none";
  

 
}

function gotoAnnotation45(e) {
  setTo(new THREE.Vector3(-3.46, 1.6, 1.6) )
    setTarget(new THREE.Vector3(-3.46, 1.6, 2.2))
    
    setLerping(true)

    document.getElementById("qrimage").src="qr/p7-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="block";
  document.getElementById("product-8-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";


 
}

function gotoAnnotation46(e) {
  setTo(new THREE.Vector3(-3.56, 1.6, -1.6) )
  setTarget(new THREE.Vector3(-3.56, 1.6, -2.2))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p4-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="block";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";


 
}

function gotoAnnotation47(e) {
  setTo(new THREE.Vector3(0.49, 1.6, -1.6) )
  setTarget(new THREE.Vector3(0.49, 1.6, -2.2))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p5-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="block";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";


 
}

function gotoAnnotation48(e) {
  setTo(new THREE.Vector3(4.48, 1.6, -1.6) )
  setTarget(new THREE.Vector3(4.48, 1.6, -2.2))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p6-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="block";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";


 
}

function gotoAnnotation49(e) {
  setTo(new THREE.Vector3(8.48, 1.6, -1.6) )
  setTarget(new THREE.Vector3(8.48, 1.6, -2.2))
  
  setLerping(true)

  document.getElementById("qrimage").src="qr/p8-c1.png"
  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="block";
  document.getElementById("product-container-close-button").style.display="none";


 
}

function gotoAnnotation50(e) {
  setTo(new THREE.Vector3(-2.5,1.5,0) )
  setTarget(new THREE.Vector3(-3.1,1.5,0))
  
  setLerping(true)

  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="none";
  document.getElementById("product-ana-masa-info-container").style.display="block";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";

 
}

function gotoAnnotation51(e) {
  setTo(new THREE.Vector3(2.5,1.5,0) )
  setTarget(new THREE.Vector3(4.5,1.5,0))
  
  setLerping(true)


  document.getElementById("product-buttons-container").style.display="none";
  document.getElementById("main-buttons-container").style.display="block";
  document.getElementById("product-popup-container").style.visibility="visible";
  document.getElementById("product-atam-masa-info-container").style.display="block";
  document.getElementById("product-ana-masa-info-container").style.display="none";
  document.getElementById("product-1-info-container").style.display="none";
  document.getElementById("product-2-info-container").style.display="none";
  document.getElementById("product-3-info-container").style.display="none";
  document.getElementById("product-4-info-container").style.display="none";
  document.getElementById("product-5-info-container").style.display="none";
  document.getElementById("product-6-info-container").style.display="none";
  document.getElementById("product-7-info-container").style.display="none";
  document.getElementById("product-8-info-container").style.display="none";
  document.getElementById("product-container-close-button").style.display="none";

 
}



  function updateMarker(e) {
   
    setTarget2(new THREE.Vector3(e.point.x, 0.1, e.point.z))
  
  }

  const [hovered, set] = useState(false)
  useEffect(() => {
    if (hovered) {
      document.body.style.cursor = `pointer`
      return () => (document.body.style.cursor = `auto`)
    }
  }, [hovered])

  
  
  return (
    
    <>
    
      <Canvas shadows dpr={[1, 2]} camera={{ position: [7,1.5,0],  fov: 50 }}  >
      <Suspense fallback={null}>
       <BakeShadows /> 
      <Environment
    
    background
        blur={0.5}
        Environment files="/aircraft_workshop_01_1k.hdr"
        rotation={[Math.PI / 4, Math.PI / 4, Math.PI / 2]} 
    
/>

{/* <Perf /> */}
        
<OrbitControls ref={ref} target={[6, 1.5, 0]}   makeDefault   minDistance={0.6} maxDistance={0.6}   enableZoom={false} minPolarAngle={Math.PI / 4} maxPolarAngle={Math.PI /1.0} />
        <Animate controls={ref} lerping={lerping} to={to} target={target} />
        <Animate2  target2={target2}/>

        <mesh  onClick={(e) => (e.stopPropagation(), gotoAnnotation(e))} onPointerMove={(e) => (e.stopPropagation(), updateMarker(e))} onPointerOver={(e) => set(true)} onPointerOut={() => set(false)}
      position-y={ 0.0 } position-x={1} position-z={0} rotation-x={ - Math.PI * 0.5 }  scale={ 10 }>
            <planeGeometry args={[1.5, 0.77]} />
            <meshStandardMaterial  transparent={true}
            opacity={0} color="red" />
        </mesh>

      

       

        <mesh position-y={ 2 }  ref={marker} name="marker"  rotation-x={ - Math.PI * 0.5 }>
          <planeGeometry args={[0.4, 0.4, 1, 1]} />
          <meshStandardMaterial
          
            transparent={true}
            opacity={1}
            depthWrite={false}
            map={texture}
          />
        </mesh>

     
        
     
   
        
    


        <Ground2  onClick={(e) => (e.stopPropagation(), gotoAnnotation28())}  />
        <Ground3  onClick={(e) => (e.stopPropagation(), gotoAnnotation27())} />
        <Ground4  onClick={(e) => (e.stopPropagation(), gotoAnnotation26())} />
        <Ground5 onClick={(e) => (e.stopPropagation(), gotoAnnotation31())}  />
        <Ground6  onClick={(e) => (e.stopPropagation(), gotoAnnotation30())} />
        <Ground7  onClick={(e) => (e.stopPropagation(), gotoAnnotation29())} />
        <Ground8  onClick={(e) => (e.stopPropagation(), gotoAnnotation34())} />
        <Ground9  onClick={(e) => (e.stopPropagation(), gotoAnnotation33())} />
        <Ground10  onClick={(e) => (e.stopPropagation(), gotoAnnotation32())} />
        <Ground11   onClick={(e) => (e.stopPropagation(), gotoAnnotation22())} />
        <Ground12   />
        <Ground13  onClick={(e) => (e.stopPropagation(), gotoAnnotation24())} />
        <Ground14  onClick={(e) => (e.stopPropagation(), gotoAnnotation23())} />
        <Ground15 onClick={(e) => (e.stopPropagation(), gotoAnnotation25())}   />
        <Ground16  onClick={(e) => (e.stopPropagation(), gotoAnnotation21())}  />
        <Ground17 onClick={(e) => (e.stopPropagation(), gotoAnnotation20())} />
        <Ground18 onClick={(e) => (e.stopPropagation(), gotoAnnotation19())}  />
        <Ground19 onClick={(e) => (e.stopPropagation(), gotoAnnotation18())}  />
        <Ground20  onClick={(e) => (e.stopPropagation(), gotoAnnotation41())} />
        <Ground21 onClick={(e) => (e.stopPropagation(), gotoAnnotation40())}  />
        <Ground22  onClick={(e) => (e.stopPropagation(), gotoAnnotation12())}/>
        <Ground23  onClick={(e) => (e.stopPropagation(), gotoAnnotation15())} />
        <Ground24    onClick={(e) => (e.stopPropagation(), gotoAnnotation14())}/>
        <Ground25 onClick={(e) => (e.stopPropagation(), gotoAnnotation17())}  />
        <Ground26  onClick={(e) => (e.stopPropagation(), gotoAnnotation16())} />
        <Ground27 onClick={(e) => (e.stopPropagation(), gotoAnnotation39())}  />
        <Ground28 onClick={(e) => (e.stopPropagation(), gotoAnnotation38())}   />
        <Ground29 onClick={(e) => (e.stopPropagation(), gotoAnnotation36())}   />
        <Ground30  onClick={(e) => (e.stopPropagation(), gotoAnnotation50())}  />
        <Ground31   onClick={(e) => (e.stopPropagation(), gotoAnnotation51())} />
        

        <Ground33  onClick={(e) => (e.stopPropagation(), gotoAnnotation42())} />
        <Ground34  onClick={(e) => (e.stopPropagation(), gotoAnnotation43())} />
        <Ground35 onClick={(e) => (e.stopPropagation(), gotoAnnotation44())}  />
        <Ground36  onClick={(e) => (e.stopPropagation(), gotoAnnotation45())} />
        <Ground37 onClick={(e) => (e.stopPropagation(), gotoAnnotation46())}   />
        <Ground38 onClick={(e) => (e.stopPropagation(), gotoAnnotation47())}   />
        <Ground39  onClick={(e) => (e.stopPropagation(), gotoAnnotation48())}  />
        <Ground40  onClick={(e) => (e.stopPropagation(), gotoAnnotation49())}  />
         <MainStage  />
          
      
      
        

        <Cloud position={[-1, 60, 100]} speed={0.4} opacity={1} segments={80} depth={2.5} width={100} />
        <Sky scale={1000} sunPosition={sunPosition} turbidity={0.1} />
       
        </Suspense>
       

       
       <spotLight castShadow intensity={1} angle={0.1} position={[200, 200, 0]} shadow-mapSize={[2048, 2048]} shadow-bias={-0.000001} />   

      </Canvas>
      
      <Loader />
      
       <div className="overlay">
        <button id="real-close-button"  onClick={(e) => (e.stopPropagation(), gotoAnnotation3())}>
          <b>close</b>
        </button>
      </div>

      <div id="overlay2"
      className="overlay2">
        <button id="button2"   onClick={(e) => (e.stopPropagation(), gotoAnnotation4())}>
          <b>soldan1b2</b>
        </button>
      </div> 

      <div className="overlay">
        <button id="button3"   onClick={(e) => (e.stopPropagation(), gotoAnnotation5())}>
          <b>soldan2b3</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button4"   onClick={(e) => (e.stopPropagation(), gotoAnnotation6())}>
          <b>soldan3b4</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button5"   onClick={(e) => (e.stopPropagation(), gotoAnnotation7())}>
          <b>soldan4b5</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button6"   onClick={(e) => (e.stopPropagation(), gotoAnnotation8())}>
          <b>sagdan1b6</b>
        </button>
        </div>
        <div className="overlay">
        <button id="button7"   onClick={(e) => (e.stopPropagation(), gotoAnnotation9())}>
          <b>sagdan2b7</b>
        </button>
        </div>

        
        <div className="overlay">
        <button id="button8"   onClick={(e) => (e.stopPropagation(), gotoAnnotation10())}>
          <b>sagdan3b8</b>
        </button>
        </div>

        <div className="overlay">
        <button id="button9"   onClick={(e) => (e.stopPropagation(), gotoAnnotation11())}>
          <b>sagdan4b9</b>
        </button>
        </div>
        
      

     
    
     

      <div className="overlay">
        <button id="button10"   onClick={(e) => (e.stopPropagation(), gotoAnnotation12())}>
          <b>100YMT_alt_solb10</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button11"   onClick={(e) => (e.stopPropagation(), gotoAnnotation13())}>
          <b>100YMT_alt_sagb11</b>
        </button>
      </div>
      
      <div className="overlay">
        <button id="button12"   onClick={(e) => (e.stopPropagation(), gotoAnnotation14())}>
          <b>100YMT_orta_solb12</b>
        </button>
      </div>

     <div className="overlay">
        <button id="button13"   onClick={(e) => (e.stopPropagation(), gotoAnnotation15())}>
          <b>100YMT_orta_sagb13</b>
        </button>
      </div>
    
      <div className="overlay">
        <button id="button14"   onClick={(e) => (e.stopPropagation(), gotoAnnotation16())}>
          <b>100YMT_ust_solb14</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button15"   onClick={(e) => (e.stopPropagation(), gotoAnnotation17())}>
          <b>100YMT_ust_sagb15</b>
        </button>
      </div> 


 
     <div className="overlay">
        <button id="button16"   onClick={(e) => (e.stopPropagation(), gotoAnnotation18())}>
          <b>ilke_tabakb16</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button17"   onClick={(e) => (e.stopPropagation(), gotoAnnotation19())}>
          <b>ilke_bardakb17</b>
        </button>
      </div>
     

     
       <div className="overlay">
        <button id="button18"   onClick={(e) => (e.stopPropagation(), gotoAnnotation20())}>
          <b>halideb18</b>
        </button>
      </div>  
      
      <div className="overlay">
        <button id="button19"   onClick={(e) => (e.stopPropagation(), gotoAnnotation21())}>
          <b>arafsolb19</b>
        </button>
      </div>  

      <div className="overlay">
        <button id="button20"   onClick={(e) => (e.stopPropagation(), gotoAnnotation22())}>
          <b>araf2b20</b>
        </button>
      </div>  

      <div className="overlay">
        <button id="button21"   onClick={(e) => (e.stopPropagation(), gotoAnnotation23())}>
          <b>araf3b21</b>
        </button>
      </div>  
      
      <div className="overlay">
        <button id="button22"   onClick={(e) => (e.stopPropagation(), gotoAnnotation24())}>
          <b>araf4b22</b>
        </button>
      </div>  

      <div className="overlay">
        <button id="button23"   onClick={(e) => (e.stopPropagation(), gotoAnnotation25())}>
          <b>arafustb23</b>
        </button>
      </div> 

      <div className="overlay">
        <button id="button24"   onClick={(e) => (e.stopPropagation(), gotoAnnotation26())}>
          <b>latifealt1b24</b>
        </button>
      </div> 

      <div className="overlay">
        <button id="button25"   onClick={(e) => (e.stopPropagation(), gotoAnnotation27())}>
          <b>latifealt2b25</b>
        </button>
      </div> 

      <div className="overlay">
        <button id="button26"   onClick={(e) => (e.stopPropagation(), gotoAnnotation28())}>
          <b>latifealt3b26</b>
        </button>
      </div> 

      <div className="overlay">
        <button id="button27"   onClick={(e) => (e.stopPropagation(), gotoAnnotation29())}>
          <b>latiforta1b27</b>
        </button>
      </div> 

      <div className="overlay">
        <button id="button28"   onClick={(e) => (e.stopPropagation(), gotoAnnotation30())}>
          <b>latiforta2b28</b>
        </button>
      </div> 

      <div className="overlay">
        <button id="button29"   onClick={(e) => (e.stopPropagation(), gotoAnnotation31())}>
          <b>latiforta3b29</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button30"   onClick={(e) => (e.stopPropagation(), gotoAnnotation32())}>
          <b>latifust1b30</b>
        </button>
      </div>


      <div className="overlay">
        <button id="button31"   onClick={(e) => (e.stopPropagation(), gotoAnnotation33())}>
          <b>latifust2b31</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button32"   onClick={(e) => (e.stopPropagation(), gotoAnnotation34())}>
          <b>latifust3b32</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button33"   onClick={(e) => (e.stopPropagation(), gotoAnnotation35())}>
          <b>cumhur1b33</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button34"   onClick={(e) => (e.stopPropagation(), gotoAnnotation36())}>
          <b>cumhur2b34</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button35"   onClick={(e) => (e.stopPropagation(), gotoAnnotation37())}>
          <b>egemen1b35</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button36"   onClick={(e) => (e.stopPropagation(), gotoAnnotation38())}>
          <b>egemen2b36</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button37"   onClick={(e) => (e.stopPropagation(), gotoAnnotation39())}>
          <b>egemen3b37</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button38"   onClick={(e) => (e.stopPropagation(), gotoAnnotation40())}>
          <b>tulu1b38</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button39"   onClick={(e) => (e.stopPropagation(), gotoAnnotation41())}>
          <b>tulu2b39</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button40"   onClick={(e) => (e.stopPropagation(), gotoAnnotation42())}>
          <b>cumhtablob40</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button41"   onClick={(e) => (e.stopPropagation(), gotoAnnotation43())}>
          <b>egetablob41</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button42"   onClick={(e) => (e.stopPropagation(), gotoAnnotation44())}>
          <b>100tablob42</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button43"   onClick={(e) => (e.stopPropagation(), gotoAnnotation45())}>
          <b>tulutablob43</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button44"   onClick={(e) => (e.stopPropagation(), gotoAnnotation46())}>
          <b>ilketablob44</b>
        </button>
      </div>


      <div className="overlay">
        <button id="button45"   onClick={(e) => (e.stopPropagation(), gotoAnnotation47())}>
          <b>halidetablob45</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button46"   onClick={(e) => (e.stopPropagation(), gotoAnnotation48())}>
          <b>araftablob46</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button47"   onClick={(e) => (e.stopPropagation(), gotoAnnotation49())}>
          <b>ltftablob47</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button48"   onClick={(e) => (e.stopPropagation(), gotoAnnotation50())}>
          <b>buyukb48</b>
        </button>
      </div>

      <div className="overlay">
        <button id="button49"   onClick={(e) => (e.stopPropagation(), gotoAnnotation51())}>
          <b>atab49</b>
        </button>
      </div>
    </>
  );
}



