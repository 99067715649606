/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground15(props) {
  const { nodes, materials } = useGLTF("/ArafYmt2.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(CC391611-EA5A-48B2-9080-07A3B341FA5C)"].geometry
        }
        material={materials["Material_5.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B63D8EEE-EC9B-46FF-B3E5-B3D2845F70FA)"].geometry
        }
        material={materials["Material_1.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(6806B6E1-DCB5-45BD-A06B-36FD9EACCFF9)002"].geometry
        }
        material={materials["Material_0.005"]}
      />
    </group>
  );
}

useGLTF.preload("/ArafYmt2.glb");
