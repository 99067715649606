/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground12(props) {
  const { nodes, materials } = useGLTF("/mumluk2.glb");
  return (
    <group  {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus2(96CBFDF1-2F8C-44B7-A281-C31F323C64D0)"].geometry
        }
        material={materials.Material_0}
      />
    </group>
  );
}

useGLTF.preload("/mumluk2.glb");
