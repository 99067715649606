/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground17(props) {
  const { nodes, materials } = useGLTF("/HalideKahveFincani.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala2(FC79B3FC-3A18-40CB-9510-3685228685FE)"]
            .geometry
        }
        material={materials["Material_1.010"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala2(C8500D60-1100-4697-BD13-87C8C74D78F1)"]
            .geometry
        }
        material={materials["Material_0.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala2(C721A028-49EC-4E9A-9590-8CA03149E352)"]
            .geometry
        }
        material={materials["Material_0.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala2(BD634180-CA31-49E6-A48E-AE65AFFC05BA)"]
            .geometry
        }
        material={materials["Material_0.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala2(6FB34F0E-D1AD-45B3-94BE-AB5AA3A26CCA)"]
            .geometry
        }
        material={materials["Material_0.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala2(6F651E98-66F1-4035-B349-3DC2CD83C63C)"]
            .geometry
        }
        material={materials["Material_1.010"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala2(6668BDFD-E92C-43FE-8FC8-F814DF0519FC)"]
            .geometry
        }
        material={materials["Material_1.010"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala2(31B3A248-12CD-4359-9BA1-D941AF55FA52)"]
            .geometry
        }
        material={materials["Material_1.010"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir6(A9A0A7E9-D7DE-4967-B0C7-4B61F8334262)"].geometry
        }
        material={materials["Material_0.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir6(93409F47-7148-4DA9-8365-B67BD9B0B4A0)"].geometry
        }
        material={materials["Material_1.010"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir6(8856E14E-73E7-42DE-AAB1-E45F59064F5C)"].geometry
        }
        material={materials["Material_0.011"]}
      />
    </group>
  );
}

useGLTF.preload("/HalideKahveFincani.glb");
