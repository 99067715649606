/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground10(props) {
  const { nodes, materials } = useGLTF("/suplatabak-1.glb");
  return (
    <group {...props} dispose={null}>
      <group
        position={[5.8922, 1.3707, -4.3021]}
        rotation={[0.4214, 0.4007, -0.1731]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["Birle�tir2(2A7DFF1D-D8F7-4022-BD97-494CBD19AA67)-mesh001"]
              .geometry
          }
          material={materials["Material_0.016"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["Birle�tir2(2A7DFF1D-D8F7-4022-BD97-494CBD19AA67)-mesh001_1"]
              .geometry
          }
          material={materials["Material_1.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["Birle�tir2(2A7DFF1D-D8F7-4022-BD97-494CBD19AA67)-mesh001_2"]
              .geometry
          }
          material={materials["Material_2.002"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/suplatabak-1.glb");
