/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground16(props) {
  const { nodes, materials } = useGLTF("/ArafYmt1.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(588EB514-9417-49B3-AA99-7786BD5FB8A1)"].geometry
        }
        material={materials.Material_7}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(2037DB28-1BE6-402E-A036-D389B25F4FA4)"].geometry
        }
        material={materials["Material_1.004"]}
      />
    </group>
  );
}

useGLTF.preload("/ArafYmt1.glb");
