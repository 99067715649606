/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground30(props) {
  const { nodes, materials } = useGLTF("/BuyukMasa.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane058.geometry}
        material={materials["Material.017"]}
        position={[-7.3896, 1.5813, 0]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Mirror25(5E9BAB6B-02C9-4025-B15B-74994034952F)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-7.6042, 1.3983, 1.0639]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Mirror24(207F82BF-220B-40B3-9E9E-E558840B4263)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-7.6042, 1.3977, 1.063]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Mirror23(17C8E42D-1AC0-4871-AD7D-FFC74310F797)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-7.6042, 1.3976, 1.066]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Mirror22(B0FC4945-D4E1-4740-8334-29179A6CA508)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-7.6041, 1.3986, 1.0604]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet94(7E364B2A-1EE6-456D-A649-A9CEDA394DB5)001"].geometry
        }
        material={materials["Material_2.004"]}
        position={[-7.6099, 1.3928, 0.8868]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet94(7C286113-8F9A-4F41-A284-DE98DD13CA48)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-7.6188, 1.3978, 1.005]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet94(3F55E80B-CE54-42E7-A24A-CFEEF40B2B93)001"].geometry
        }
        material={materials["Material_2.004"]}
        position={[-7.609, 1.3976, 1.1144]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet104(F6D9819B-A7D3-42CF-8D0B-8A366BEA64DB)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-7.5513, 1.3764, 0.9783]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet104(DD86CE5F-F3B3-499F-804C-CBDCC20D7241)001"].geometry
        }
        material={materials["Material_2.004"]}
        position={[-7.5385, 1.3735, 1.0678]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet104(D58CE582-D7A0-4049-B77C-90F11143EB5A)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-8.1607, 4.9029, -0.5151]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(963B99AA-2876-4BC1-A46D-61F90EEA0E83)004"].geometry
        }
        material={materials["Material_0.004"]}
        position={[-7.6275, 1.3957, 0.9582]}
        rotation={[-Math.PI, -Math.PI / 2, 0]}
        scale={[0.001, 0.001, 0.0005]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Body-MoveCopy3(243BC035-89C3-49EE-9F92-35845CD03C07)001"]
            .geometry
        }
        material={materials["Material_1.003"]}
        position={[-7.603, 1.398, 1.0905]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet108(70B8EA15-B218-4C2A-ACA8-C73B1C45F5CF)002"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-7.6065, 1.4872, 0.368]}
        rotation={[-1.8541, -0.962, -2.021]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet108(2ECD31B8-5FEE-4551-B666-4067C21A731D)002"].geometry
        }
        material={materials["Material_2.004"]}
        position={[-7.6042, 1.485, 0.4175]}
        rotation={[-1.8541, -0.962, -2.021]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(963B99AA-2876-4BC1-A46D-61F90EEA0E83)006"].geometry
        }
        material={materials["Material_0.004"]}
        position={[-7.6137, 1.4872, 0.3515]}
        rotation={[-2.8645, -1.5072, 0.2634]}
        scale={[0.001, 0.001, 0.0005]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Combine23(7FB77A60-391A-4A75-8215-85660CBCFC10)002"].geometry
        }
        material={materials["Material_2.004"]}
        position={[-7.5276, 1.4484, 0.4645]}
        rotation={[-1.8039, -0.8067, -1.9564]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Combine23(0216F9C3-5D53-48E0-8825-5038FED36DBF)002"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-7.5295, 1.4628, 0.3406]}
        rotation={[-1.8039, -0.8067, -1.9564]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Combine23(00AB2F9A-EC65-414E-92EB-205A4905D540)002"].geometry
        }
        material={materials["Material_2.004"]}
        position={[-7.5254, 1.4644, 0.3084]}
        rotation={[-1.8039, -0.8067, -1.9564]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet32(8EDA3EBC-3556-4DCC-A515-1D177D90B7A5)001"].geometry
        }
        material={materials["Material_2.004"]}
        position={[-4.9809, 0.2748, -3.4545]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet32(5DA8F710-6F4E-4D79-9309-96A3B16930E4)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-4.9809, 0.2748, -3.4545]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet32(0E669E41-2323-418C-9112-321E6B9D7BC7)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-4.9809, 0.2748, -3.4545]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet32(09765657-2F14-4D43-A058-1B1F09E28716)001"].geometry
        }
        material={materials["Material_2.004"]}
        position={[-4.9809, 0.2748, -3.4545]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale13(E43BC568-B495-4E28-9220-4A5A38E2CDAF)001"].geometry
        }
        material={materials["Material_2.004"]}
        position={[-6.7849, 1.1868, 0.4859]}
        rotation={[0, 1.0326, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale13(2BB084F1-0471-46D2-A7EF-B3AF496C7CD0)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-6.785, 1.1493, 0.486]}
        rotation={[0, 1.0326, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale13(28EC2A54-DA94-4315-8F53-FF6B6BA09487)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-6.7855, 1.1549, 0.4854]}
        rotation={[0, 1.0326, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet63(9B25C3E6-55C1-4FB6-AA25-94EFC113F0CD)001"].geometry
        }
        material={materials["Material_2.004"]}
        position={[-6.7967, 1.1901, 0.5643]}
        rotation={[0, 1.0326, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet51(DC7D802F-14D5-4314-A852-BFF5AD633191)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-6.8337, 1.1692, 0.516]}
        rotation={[0, 1.0326, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet51(55F6A16F-974E-457E-A59B-C5AC3AB4E044)001"].geometry
        }
        material={materials["Material_2.004"]}
        position={[-6.8499, 1.1901, 0.5336]}
        rotation={[0, 1.0326, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(B6CF421F-60E5-46D7-9B95-12054CB45183)001"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-6.7898, 1.1709, 0.5411]}
        rotation={[0, 1.0326, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(8831D603-C5A7-4D28-B347-3B3A3B4A305B)002"].geometry
        }
        material={materials["Material_2.004"]}
        position={[-7.0552, 1.2982, 0.5266]}
        rotation={[-0.0061, -0.0557, -1.5726]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(5AFB18C3-BFDF-4CDE-8D7B-6DA06C9E9F08)002"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-7.0809, 1.2984, 0.5257]}
        rotation={[-0.0061, -0.0557, -1.5726]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(4F71803A-9B62-47C3-B965-91C40DB73155)002"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-7.0721, 1.2984, 0.5262]}
        rotation={[-0.0061, -0.0557, -1.5726]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(BF3F56DB-D8DC-40E6-82A3-E0BC33EFF7A3)002"].geometry
        }
        material={materials["Material_2.004"]}
        position={[-5.2087, 0.455, -3.6482]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(93CF2658-8FFD-4E48-9F93-EE0B5FE17CFC)002"].geometry
        }
        material={materials["Material_2.004"]}
        position={[-5.2087, 0.455, -3.6482]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(861AD22C-BFC6-49A6-8AFC-8AFC11001164)002"].geometry
        }
        material={materials["Material_2.004"]}
        position={[-5.2087, 0.455, -3.6482]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(3D377015-252C-405D-B796-1DFCBE535FC6)002"].geometry
        }
        material={materials["Material_2.004"]}
        position={[-5.2087, 0.455, -3.6482]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(2A3446B2-9F4F-41C9-A858-335F7EDFB856)002"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-5.2087, 0.455, -3.6482]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(29A8C729-4E31-4813-9277-2C112A19AD49)002"].geometry
        }
        material={materials["Material_1.003"]}
        position={[-5.2087, 0.455, -3.6482]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet33(1EDC35C8-539C-4155-9FC2-E21B46A90344)002"].geometry
        }
        material={materials["Material_2.004"]}
        position={[-5.2087, 0.455, -3.6482]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(FBF8A060-AF32-4605-933C-FF848966B789)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-7.0374, 1.245, -0.1733]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(FB97E978-B7A2-4B76-8C4E-E85E909EE72E)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-6.9835, 1.2441, -0.1785]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(EBDFFBB6-7808-42A7-A452-AA574AFE274D)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-6.9471, 1.2451, -0.1934]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(E54DB15E-DA9F-4FF1-8ACE-1A0E7DBE26A8)001"].geometry
        }
        material={materials["Material_0.007"]}
        position={[-6.9991, 1.2381, -0.2136]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(E4EEBA07-728B-43B2-9005-9C7F70F66E10)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-6.9765, 1.2452, -0.1627]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(C29830BB-F6D3-47E1-89FF-1626FE1B9E80)001"].geometry
        }
        material={materials["Material_0.007"]}
        position={[-6.9992, 1.2442, -0.2149]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(AD389807-0C82-45AE-8F3C-B7F1C0CC1706)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-7.0498, 1.2448, -0.1911]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(AD019230-86E6-4880-9544-670C0ADFC86F)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-7.0144, 1.2434, -0.2485]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(AC392326-24B7-4CDF-AFB6-75F45FDBA22A)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-6.9977, 1.2452, -0.158]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(A7A79B02-432A-4C58-B17B-2BFBBE8F26A9)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-7.0391, 1.2443, -0.252]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(9606E050-4312-43F9-9B53-B0B198ED5A82)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-6.9481, 1.2447, -0.236]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(8E0D7521-64FD-4633-BD97-BDD992EC6A31)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-7.0214, 1.2442, -0.2644]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(788DE2F7-B001-430C-8E15-3BD8FBF3CF23)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-6.9434, 1.2449, -0.2148]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(73A9EDA0-F4A1-49B3-BAF8-4813B4BA6C4B)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-7.0002, 1.2443, -0.2691]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(5E8A4176-837F-43B3-A441-356DDCC2B0A3)001"].geometry
        }
        material={materials["Material_0.007"]}
        position={[-6.999, 1.2443, -0.2135]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(4DFF19CD-5907-4125-916E-FA315969D1CD)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-6.9788, 1.2444, -0.2654]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(46C69640-D38D-4516-8465-EC7A475AE475)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-7.0191, 1.2451, -0.1617]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(3BE66449-C659-4118-BFD5-E79485771D5D)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-7.0508, 1.2444, -0.2336]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(36848AF2-7DE8-461B-BD55-30D7F0C9D198)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-6.9605, 1.2445, -0.2537]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(2D1DAB18-8AD7-4B66-9456-52385A01D6A2)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-7.0546, 1.2446, -0.2123]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(125B08C2-510E-4CC9-9FE7-687FCAD5B200)001"].geometry
        }
        material={materials["Material_0.007"]}
        position={[-6.999, 1.2464, -0.2135]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(06960B09-56AB-43E5-BFAA-40DCF3CBE759)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-6.9588, 1.2452, -0.1751]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus45(0089BBA0-8C36-4BF3-A38F-36F40E86829E)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-6.9983, 1.2454, -0.2135]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus35(FACAC5F0-6B61-43B2-A3DD-A244CC1E6F5F)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-6.9869, 1.2866, -0.2349]}
        rotation={[Math.PI, -1.5603, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus35(D5EC7BD8-601E-4F23-941C-077F15EEA362)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-7.0228, 1.2866, -0.2134]}
        rotation={[Math.PI, -1.5603, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus35(B0940EDD-491D-4B05-982D-AF0E026D50A7)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-6.9744, 1.2866, -0.2142]}
        rotation={[Math.PI, -1.5603, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus35(AB7345DE-675E-4FAD-BF53-0DB75D8F7062)001"].geometry
        }
        material={materials["Material_1.006"]}
        position={[-6.9986, 1.2806, -0.2138]}
        rotation={[Math.PI, -1.5603, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus35(A63740F6-03DA-4E36-8D93-3BE7F58F48A1)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-7.0103, 1.2866, -0.1927]}
        rotation={[Math.PI, -1.5603, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus35(79637D63-EB5A-4735-BB96-5CD915B0EAEA)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-6.9861, 1.2865, -0.1931]}
        rotation={[Math.PI, -1.5603, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus35(66D1FB07-8E30-435E-9F93-0918D7924C79)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-7.011, 1.2865, -0.2345]}
        rotation={[Math.PI, -1.5603, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus35(37DA0172-C47D-45E8-B10F-1536277B706F)001"].geometry
        }
        material={materials["Material_2.007"]}
        position={[-6.9988, 1.2857, -0.1703]}
        rotation={[Math.PI, -1.5603, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus35(096D63B7-FA0C-4386-A79C-B47DA28AD5ED)001"].geometry
        }
        material={materials["Material_0.007"]}
        position={[-6.9987, 1.2634, -0.2141]}
        rotation={[Math.PI, -1.5603, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(5528B6C3-E19D-49F4-B809-0A39B92CC40A)001"].geometry
        }
        material={materials.Material_3}
        position={[-7.0042, 1.2403, -0.2129]}
        rotation={[1.5004, -1.0462, 1.4045]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(F8253F50-7AE9-467C-99D2-8FECA9DCED42)002"].geometry
        }
        material={materials["Material_0.008"]}
        position={[-6.8907, 1.2578, -0.7552]}
        rotation={[-Math.PI, 1.5059, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(F8253F50-7AE9-467C-99D2-8FECA9DCED42)003"].geometry
        }
        material={materials["Material_0.008"]}
        position={[-6.7408, 1.1703, -0.4732]}
        rotation={[2.0199, 0.2523, -1.5827]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(E8CFAF35-E647-4C56-8E01-9E6CDC1396E7)002"].geometry
        }
        material={materials["Material_0.008"]}
        position={[-6.8889, 1.2604, -0.748]}
        rotation={[-Math.PI, 1.5059, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(E8CFAF35-E647-4C56-8E01-9E6CDC1396E7)003"].geometry
        }
        material={materials["Material_0.008"]}
        position={[-6.7406, 1.173, -0.4806]}
        rotation={[2.0199, 0.2523, -1.5827]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(DD178EFF-D85A-425A-B081-87A073FF8D52)002"].geometry
        }
        material={materials["Material_2.008"]}
        position={[-6.8906, 1.2608, -0.7545]}
        rotation={[-Math.PI, 1.5059, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(DD178EFF-D85A-425A-B081-87A073FF8D52)003"].geometry
        }
        material={materials["Material_2.008"]}
        position={[-6.7407, 1.1734, -0.474]}
        rotation={[2.0199, 0.2523, -1.5827]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(BE922FCD-8299-45A8-BD44-97EAB486A23F)002"].geometry
        }
        material={materials["Material_2.008"]}
        position={[-6.891, 1.263, -0.7552]}
        rotation={[-Math.PI, 1.5059, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(BE922FCD-8299-45A8-BD44-97EAB486A23F)003"].geometry
        }
        material={materials["Material_2.008"]}
        position={[-6.7406, 1.1757, -0.4732]}
        rotation={[2.0199, 0.2523, -1.5827]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(7FC52394-636C-4563-979D-A101176771C0)002"].geometry
        }
        material={materials["Material_0.008"]}
        position={[-6.8908, 1.2605, -0.7556]}
        rotation={[-Math.PI, 1.5059, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(7FC52394-636C-4563-979D-A101176771C0)003"].geometry
        }
        material={materials["Material_0.008"]}
        position={[-6.7408, 1.1731, -0.4729]}
        rotation={[2.0199, 0.2523, -1.5827]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(47AF48AA-C262-41EB-AF4A-C09726885DC5)002"].geometry
        }
        material={materials.Material_4}
        position={[-6.8905, 1.2621, -0.7548]}
        rotation={[-Math.PI, 1.5059, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(47AF48AA-C262-41EB-AF4A-C09726885DC5)003"].geometry
        }
        material={materials["Material_1.007"]}
        position={[-6.741, 1.1747, -0.4737]}
        rotation={[2.0199, 0.2523, -1.5827]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(26FEBC4E-00D4-4C00-8272-4BCC1ADC1FAC)002"].geometry
        }
        material={materials["Material_0.008"]}
        position={[-6.8865, 1.2604, -0.7509]}
        rotation={[-Math.PI, 1.5059, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet7(26FEBC4E-00D4-4C00-8272-4BCC1ADC1FAC)003"].geometry
        }
        material={materials["Material_0.008"]}
        position={[-6.7436, 1.173, -0.4785]}
        rotation={[2.0199, 0.2523, -1.5827]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(ECDB8127-6AC9-441F-A173-5CA0054E4D61)002"].geometry
        }
        material={materials["Material_2.008"]}
        position={[-6.8907, 1.313, -0.755]}
        rotation={[-Math.PI, 1.5059, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(ECDB8127-6AC9-441F-A173-5CA0054E4D61)003"].geometry
        }
        material={materials["Material_2.008"]}
        position={[-6.7411, 1.2258, -0.473]}
        rotation={[-Math.PI, 1.5059, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(8D19357D-71F1-4612-8CE2-EA04489A116F)002"].geometry
        }
        material={materials.Material_5}
        position={[-6.8904, 1.2983, -0.7551]}
        rotation={[-Math.PI, 1.5059, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(8D19357D-71F1-4612-8CE2-EA04489A116F)003"].geometry
        }
        material={materials["Material_3.001"]}
        position={[-6.7413, 1.2111, -0.473]}
        rotation={[-Math.PI, 1.5059, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(6FC691A8-C268-4DBA-9AB7-3EFEF49EFC2A)002"].geometry
        }
        material={materials["Material_2.008"]}
        position={[-6.8939, 1.2977, -0.8092]}
        rotation={[-Math.PI, 1.5059, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(6FC691A8-C268-4DBA-9AB7-3EFEF49EFC2A)003"].geometry
        }
        material={materials["Material_2.008"]}
        position={[-6.7348, 1.2104, -0.4192]}
        rotation={[-Math.PI, 1.5059, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(3191B63A-F082-45BA-A3BB-0DDE509A3EA3)002"].geometry
        }
        material={materials["Material_0.008"]}
        position={[-6.8918, 1.2853, -0.7746]}
        rotation={[-Math.PI, 1.5059, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet26(3191B63A-F082-45BA-A3BB-0DDE509A3EA3)003"].geometry
        }
        material={materials["Material_0.008"]}
        position={[-6.7389, 1.1982, -0.4536]}
        rotation={[-Math.PI, 1.5059, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(48380A9D-9100-4285-B715-43E5DC0BCD66)007"].geometry
        }
        material={materials.Material_6}
        position={[-6.8925, 1.2589, -0.7608]}
        rotation={[-Math.PI, 1.5059, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(48380A9D-9100-4285-B715-43E5DC0BCD66)008"].geometry
        }
        material={materials.Material_6}
        position={[-6.7405, 1.1715, -0.4673]}
        rotation={[2.0199, 0.2523, -1.5827]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale3(FB01A8AD-279E-422A-8F95-87717B705875)001"].geometry
        }
        material={materials["Material_1.008"]}
        position={[-7.5493, 1.4864, -0.7686]}
        rotation={[1.5403, 1.279, -1.5415]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale3(DEEAE153-ED53-43BA-B7D1-41A8D509C3B3)001"].geometry
        }
        material={materials["Material_2.009"]}
        position={[-7.5515, 1.5051, -0.9024]}
        rotation={[1.5403, 1.279, -1.5415]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale3(C4621676-7C51-4B47-B839-AF9FF910E86A)001"].geometry
        }
        material={materials["Material_2.009"]}
        position={[-7.5489, 1.4065, -0.8756]}
        rotation={[1.5403, 1.279, -1.5415]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale3(AE2C33A6-D5EE-4D7C-947E-FD1909161064)001"].geometry
        }
        material={materials["Material_2.009"]}
        position={[-7.5495, 1.5011, -0.7788]}
        rotation={[1.5403, 1.279, -1.5415]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale3(A4469CDE-9649-439F-A8D9-FC1D70CBC41E)001"].geometry
        }
        material={materials["Material_2.009"]}
        position={[-7.5489, 1.4875, -0.7735]}
        rotation={[1.5403, 1.279, -1.5415]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale3(5A686447-90E8-418B-8636-D215D453F48A)001"].geometry
        }
        material={materials["Material_1.008"]}
        position={[-7.5673, 1.4702, -0.8826]}
        rotation={[1.5403, 1.279, -1.5415]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale3(56B50409-A818-47DC-97B5-A0BCF4F7FDC2)001"].geometry
        }
        material={materials["Material_2.009"]}
        position={[-7.5494, 1.4977, -0.7781]}
        rotation={[1.5403, 1.279, -1.5415]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Scale3(1C7FC603-CC88-4E3C-B9C6-E2F74FA84F09)001"].geometry
        }
        material={materials["Material_2.009"]}
        position={[-7.5495, 1.4727, -0.7826]}
        rotation={[1.5403, 1.279, -1.5415]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["NONE(77B38BDE-5B4A-4363-8AC8-91EECF89045A)001"].geometry
        }
        material={materials["Material_2.009"]}
        position={[-7.6212, 1.3825, -0.3042]}
        rotation={[0, 1.0825, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["NONE(3EA78CB1-9FA1-4C03-86E4-A526420611C8)001"].geometry
        }
        material={materials["Material_2.009"]}
        position={[-7.6253, 1.3092, -0.2585]}
        rotation={[0, 1.0825, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["NONE(097F3677-65A7-4AB3-8E56-381F0EBE10AB)001"].geometry
        }
        material={materials["Material_1.008"]}
        position={[-7.6251, 1.365, -0.2665]}
        rotation={[0, 1.0825, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(FF034D21-7E2C-4190-84A5-45B68A51A9A3)001"].geometry
        }
        material={materials["Material_2.009"]}
        position={[-7.6596, 1.306, -0.1784]}
        rotation={[0, 1.0825, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(D3DFBFA6-518E-42BE-AED2-D91700651FC2)001"].geometry
        }
        material={materials["Material_2.009"]}
        position={[-7.67, 1.3055, -0.185]}
        rotation={[0, 1.0825, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(BF98DC17-ECE0-480B-A0B4-67BEE54D5DED)001"].geometry
        }
        material={materials["Material_2.009"]}
        position={[-7.6441, 1.3066, -0.1776]}
        rotation={[0, 1.0825, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(B8BD2CEF-F66C-4B5D-8099-B7CBE82E6A0D)001"].geometry
        }
        material={materials["Material_2.009"]}
        position={[-7.5856, 1.3049, -0.2032]}
        rotation={[0, 1.0825, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(B71897A1-3945-47B5-9E63-49C0ADC566D9)001"].geometry
        }
        material={materials["Material_1.008"]}
        position={[-7.6612, 1.3053, -0.1729]}
        rotation={[0, 1.0825, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(B3A62670-F9B3-48D0-A6BC-2554F6A29E76)001"].geometry
        }
        material={materials["Material_1.008"]}
        position={[-7.6205, 1.2996, -0.2313]}
        rotation={[0, 1.0825, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(8704CA57-FDDC-4B91-A758-DAD9707AD023)001"].geometry
        }
        material={materials["Material_2.009"]}
        position={[-7.6499, 1.3048, -0.1892]}
        rotation={[0, 1.0825, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(4416ED8D-C0E8-4253-85D8-9B04088F0684)001"].geometry
        }
        material={materials["Material_2.009"]}
        position={[-7.6388, 1.303, -0.2682]}
        rotation={[0, 1.0825, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(403EFAEF-159F-486A-B1FD-E166FE6CB393)001"].geometry
        }
        material={materials["Material_1.008"]}
        position={[-7.626, 1.296, -0.2538]}
        rotation={[0, 1.0825, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(3744CBED-E743-4847-87DE-2366A831B2C2)001"].geometry
        }
        material={materials["Material_2.009"]}
        position={[-7.6733, 1.3057, -0.1862]}
        rotation={[0, 1.0825, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(54026FD8-4EA8-4511-9F7A-E9C4C5B95185)001"].geometry
        }
        material={materials["Material_1.008"]}
        position={[-7.5563, 1.4609, -0.8572]}
        rotation={[1.5403, 1.279, -1.5415]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(48380A9D-9100-4285-B715-43E5DC0BCD66)005"].geometry
        }
        material={materials["Material_0.009"]}
        position={[-7.5661, 1.4689, -0.8882]}
        rotation={[1.5403, 1.279, -1.5415]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(388596BA-3FF4-42F1-9751-8B99461CC7F7)001"].geometry
        }
        material={materials["Material_0.009"]}
        position={[-7.6236, 1.2938, -0.259]}
        rotation={[0, 1.0825, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(07D018EB-6759-47E3-9A1D-CD77681E25B4)001"].geometry
        }
        material={materials["Material_1.008"]}
        position={[-7.5484, 1.4025, -0.8341]}
        rotation={[1.5403, 1.279, -1.5415]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Split_Line3(9F54D731-8191-40B0-8287-74B331F742FC)002"].geometry
        }
        material={materials["Material_3.002"]}
        position={[-7.208, 1.2582, 0.0643]}
        rotation={[0, -1.2025, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Split_Line3(63B65204-834F-45D6-A678-81EADD2FC27D)002"].geometry
        }
        material={materials["Material_0.010"]}
        position={[-7.2101, 1.2574, 0.0643]}
        rotation={[0, -1.2025, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Split_Line3(1B3A804F-6C7E-4E02-B091-8907B3B2254F)002"].geometry
        }
        material={materials["Material_1.009"]}
        position={[-7.2136, 1.2565, 0.0641]}
        rotation={[0, -1.2025, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(48380A9D-9100-4285-B715-43E5DC0BCD66)006"].geometry
        }
        material={materials["Material_4.001"]}
        position={[-7.2184, 1.2554, 0.0583]}
        rotation={[0, -1.2025, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala2(FC79B3FC-3A18-40CB-9510-368522868"].geometry
        }
        material={materials["Material_1.010"]}
        position={[-6.797, 1.1989, 0.1325]}
        rotation={[Math.PI, -1.524, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala2(C8500D60-1100-4697-BD13-87C8C74D7"].geometry
        }
        material={materials["Material_0.011"]}
        position={[-6.7953, 1.1435, 0.094]}
        rotation={[Math.PI, -1.524, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala2(C721A028-49EC-4E9A-9590-8CA03149E"].geometry
        }
        material={materials["Material_0.011"]}
        position={[-6.7952, 1.1974, 0.094]}
        rotation={[Math.PI, -1.524, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala2(BD634180-CA31-49E6-A48E-AE65AFFC0"].geometry
        }
        material={materials["Material_0.011"]}
        position={[-6.7955, 1.1751, 0.0989]}
        rotation={[Math.PI, -1.524, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala2(6FB34F0E-D1AD-45B3-94BE-AB5AA3A26"].geometry
        }
        material={materials["Material_0.011"]}
        position={[-6.7952, 1.148, 0.094]}
        rotation={[Math.PI, -1.524, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala2(6F651E98-66F1-4035-B349-3DC2CD83C"].geometry
        }
        material={materials["Material_1.010"]}
        position={[-6.795, 1.1507, 0.0939]}
        rotation={[Math.PI, -1.524, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala2(6668BDFD-E92C-43FE-8FC8-F814DF051"].geometry
        }
        material={materials["Material_1.010"]}
        position={[-6.7954, 1.2146, 0.0939]}
        rotation={[Math.PI, -1.524, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala2(31B3A248-12CD-4359-9BA1-D941AF55F"].geometry
        }
        material={materials["Material_1.010"]}
        position={[-6.7954, 1.144, 0.094]}
        rotation={[Math.PI, -1.524, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir6(A9A0A7E9-D7DE-4967-B0C7-4B61F8334262)001"].geometry
        }
        material={materials["Material_0.011"]}
        position={[-6.7953, 1.141, 0.0936]}
        rotation={[Math.PI, -1.524, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir6(93409F47-7148-4DA9-8365-B67BD9B0B4A0)001"].geometry
        }
        material={materials["Material_1.010"]}
        position={[-6.7967, 1.1491, 0.0931]}
        rotation={[Math.PI, -1.524, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir6(8856E14E-73E7-42DE-AAB1-E45F59064F5C)001"].geometry
        }
        material={materials["Material_0.011"]}
        position={[-6.7956, 1.1511, 0.0939]}
        rotation={[Math.PI, -1.524, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Table005.geometry}
        material={materials.Wood}
        position={[-7.3792, 0.046, 0.0012]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.7283}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Table004.geometry}
        material={materials.Wood}
        position={[-7.3792, 0.046, 0.0012]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.7283}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Table003.geometry}
        material={materials.Wood}
        position={[-7.3792, 0.046, 0.0012]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.7283}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Table002.geometry}
        material={materials.Wood}
        position={[-7.3792, 0.046, 0.0012]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.7283}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Table001.geometry}
        material={materials.Wood}
        position={[-7.3792, 0.046, 0.0012]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.7283}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Table.geometry}
        material={materials.Wood}
        position={[-7.3792, 0.046, 0.0012]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.7283}
      />
    </group>
  );
}

useGLTF.preload("/BuyukMasa.glb");
