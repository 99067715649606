/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground3(props) {
  const { nodes, materials } = useGLTF("/Tepsi.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(57E2F248-DFA4-4D93-A642-B35C24B5F318)"].geometry
        }
        material={materials["Material_0.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir2(3808FFB7-41D0-4B53-B35A-8F79A9C64FEA)"].geometry
        }
        material={materials["Material_1.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Layer_02(C33B5DB9-E938-4913-AD99-F43F36BDD715)"].geometry
        }
        material={materials["Material_1.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(71E265E6-30CB-433E-90B7-A1E6BF607786)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(A9651940-11B5-469A-A94B-E53EFEF6EC34)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(76DE29B8-69C1-4B77-8E64-2DA3CC1D150B)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(2913EC99-EEE0-4C9F-89C2-C50CDB161989)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D239D22E-1477-4224-AA46-D4BFA252A9CA)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(59612B4C-FB48-4D53-9CCC-E19F1C078606)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(85F61F73-AFF8-439B-9B32-774596BCAEB6)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(81FE8098-BB5D-478F-ABA4-CE8E882ED879)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6E1E5E0D-87B0-4004-8FED-6680720BDFE3)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(68F733AF-2B79-40E3-B772-EFF0BD98268B)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(1712E6B7-4495-4397-8B25-760D6C81FCF0)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(367498A3-4EAB-4E5D-AF94-836595B056C4)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(9B49E84F-098F-4BA5-AABD-211477DD6FD0)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(35CF597B-F192-447E-98AB-3EBFA0576E15)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(A44D1481-0E58-4086-A3D1-A8E432D8FE86)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(62365C23-8D1B-484D-82F4-7D58B6CCD6DA)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(5957A475-E803-40AB-A886-1C9384BE3D26)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(182F34EA-E612-4D23-92DE-015A1089D645)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(165B1868-2C29-4B09-A293-C07BA880F101)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(8EE9CD2A-0AA9-4D86-841B-9CE5FFA48787)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E3FF8503-F167-4520-AB79-FE2364861060)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(06E192E4-B662-4A3F-9982-9C4132F8740F)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E1732C7C-DB4F-42EA-80E8-E7743EBEAFE6)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(F95F0251-840B-4A33-BDB6-B2E0FE71DEC1)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6883E146-3FCC-422A-AC70-FF8516AB2AA8)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(974C412F-3F2E-47D3-A946-51FD6156707B)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(5A2EDA24-7429-4996-98D9-DEC86A0D0B66)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(F68491A7-4BF1-4A67-943B-E817A786810F)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(1BDF544C-642A-45FE-A712-5386D58EB423)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(ABDF8530-D695-4EEB-9ECB-685706BE4510)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(8E10ECF1-17F7-4C4A-977C-587C316BC5C7)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(00FCF76A-5855-468D-B850-40192838B68C)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(9E9B6F71-3286-4D13-A8ED-08840A834C75)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(F831CF4B-C400-48D4-BE13-9EA30E761726)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(F070B8F8-72DA-42CE-B79A-A8A530CA0E88)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D1ECFB04-9C13-476F-8362-86FBA4FBD2A2)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(A3BFADCF-196D-4DCE-A7CE-5D7FF863C4CA)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(32A2ECDF-4F7D-46C8-92A3-3CE930147CB8)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6923A1CF-9974-4139-BD03-859DA19F46CA)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(ACCA252A-4799-4393-A398-A59ABCB002F8)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6B15C895-1A2E-49C5-B8A8-66352CF0395C)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(858450B3-EE8F-4B43-BB5F-048AF08A9E50)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(8EF635DC-5A7D-4210-9806-601D03B3A375)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(10A37421-090E-4CBB-B2C9-54CEC8110242)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(2ECEF50D-692E-42A4-81F8-69D5178B9413)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(1C5EA1AD-BFF0-4899-AE84-A2DA699C3EEF)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(BE52B66B-EBF6-4C75-9647-354D243BAD04)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(2FD9F2CD-EB45-4D3A-9F75-4883749315B2)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(607EF21E-5A1B-4D91-9349-F452CE68CC5A)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(5488751A-F5B7-49B2-83FD-BC30447179A9)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(A1C276C8-C051-4C45-8A87-53C1B5388C8C)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D534053D-10EC-4B80-8B96-A243E0C903ED)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(4DBAE70E-6BF0-4CC2-B067-B09C82740DEC)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(69A112C9-75D4-4F2C-975D-BAB6908F0E77)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B0C347D6-35EA-415B-867F-3D2A6913BD2B)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(0B1A58E7-9C7E-4E9E-AF45-A37A9E0AB8CB)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(21858D95-A1DC-4BD8-8A2F-32A20240C628)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(BF40B675-D9E0-4E84-A03D-1264F293428B)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(26BA7A26-74FA-4B01-9ACD-751F76CA28D1)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(AB338C60-4D08-4CEE-BBD5-4EEB02DA3D78)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(052BFCF3-479B-4144-BDEB-8963982E4B1C)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(25E1AB6F-7BD2-46F8-9785-5F69954390A9)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(178A1FF5-4FFC-4AA3-A5B6-C86149EFECBC)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(231D1A85-72CD-4010-A2BC-123CD600699C)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(A6B82CF9-7C39-434A-97AC-44A3B8C9D31F)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(BDDB9C95-0AD5-4A96-A9D5-2295D1F8FF93)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(0085C563-F241-4316-A7A9-A5CD4C92D218)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(49235391-F45E-4FFD-90F4-B2C5B4C811E6)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(9961E552-1AFF-43E2-979E-F6BE289D11AF)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C5BF7535-EE9C-4CF7-A591-4C86B95B4E9D)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(BD7AE34D-91DF-4E26-A974-F892F8477BBF)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C87FDAC7-11E7-473D-8E25-91EE6573F1AB)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(079038AD-351A-4190-AD14-BCD3576C38DD)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(1061E900-893A-49B8-8BF4-652F19B975D8)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D6779C20-2AF8-4585-BF97-A6891D39CE35)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(0F4B969B-605B-4B9F-BB0B-BD4D3C50D342)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D51C4187-8312-4F1A-AEFE-EEEBC1EF487E)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7A135922-C2DC-430A-9733-877D485F0EE1)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C586A83A-5B0C-440A-8FDB-6569FA1C1BDE)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(A5B8621A-9A72-425A-AC41-D494E318602C)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(2484EBAE-D1A6-43B2-8F5B-9D789D1F80A2)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(04BF9B8B-BF60-43A5-B88D-A61464705376)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(3DF00415-3F33-404B-AE72-9AADCC166F4E)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(974D8C23-B91B-4260-8977-DBFA6B9B47A2)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B39CE553-4059-4C26-B4DF-8BA924ABB031)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(68E86CD8-7412-4853-A013-74FA42AEB71C)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(92BAFB1E-7EB9-4062-9CFF-A23F8A8C1B97)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(73EC4D38-C21F-4662-BA5C-C0A68F424728)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(3B2C3CB4-79AE-42AD-996F-FBA57DB50F48)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(447CCE35-C93B-4C70-85FC-29FA013FCE83)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(91614E27-D590-43B1-A189-CC626A1A64F5)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(12B80F16-3EA1-4E04-80F0-CD219F0A487E)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(2A08C6A4-7191-43B7-994B-8F1F62EFE5D3)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(8FA3EB77-1E2F-4FFB-AE34-AFD1C12B5590)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(066C7E2E-0B23-453A-A9E3-7B4848622AD1)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(8BBFDBEF-F3E9-4A5F-83DC-1BB24FEE13FB)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B4ACA00F-C6F7-49BF-95E4-5245B0661EED)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(EF776CA7-1993-489E-9BAC-0D8886ACF216)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(886001F7-22D7-4B09-B798-C07DF088BA3B)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(3A1D72F3-DF51-48A5-AE7F-27396FF1E402)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7D9F008C-A93F-4A72-B6EC-16B22F57941A)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6A7B57B6-038E-43BD-A259-F315C79BC1EB)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(3E80C704-A405-4F79-9518-A201632AC38E)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(49F2B2A5-165A-4B0B-B33E-063BE43BFC52)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B26CAB8D-AE7F-44E5-BDC4-9857007D261E)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(453FFBE6-02E6-4EB5-976F-DADCFC70F227)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7D61FB8E-3D89-4EF4-BDD2-92349D0EA4D8)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D64D5169-7756-4FB1-9BEE-C8F136BE4580)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(57E41F8E-B683-4CDB-AC10-0E9CA0512450)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(363C2344-377C-4445-8975-298D53CA3775)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B8C9229A-9D27-493D-A972-D1BCC760A001)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6BA51607-8460-4D1C-B1E4-EAB713D42FAD)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D06BEF04-16C7-43BC-B1A6-C928F54C8B27)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(3A4C282C-718D-4CA7-83D3-C6589F80514F)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B044D199-F9CC-476B-8F5F-F4E7E4F6D465)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(61B0A537-A3BB-411B-AAD6-D94EAA827617)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(95881790-0351-4E38-9C8D-6781E9571861)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B058DC4D-12E1-4136-AFFF-051971215C78)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7D383049-3B18-41EA-87D7-44C2CC157224)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(8E7A668C-5F14-40B5-AC2C-C2AA278BBD76)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(AEA04229-D6A4-4CC3-9519-CE287C69DAE2)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B460BEEF-6679-4D1C-AC89-110687D0720D)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(00A33DF5-4122-44BF-ADEC-2525D5C140F9)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D2DCEB38-38AE-42C8-8ED3-A3D5330E8354)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(A6CFB8AC-4B17-47B2-8FBF-FA34D31246CA)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(95876227-CBEB-48FC-9452-39464EB3DCEB)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(1ED2A960-B6D6-4423-9514-805A04236130)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(AA665F3E-A9D0-4DF4-A4FC-C23CD455AED4)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(AA729F7B-0FC5-417C-B455-1EB5AEB4F314)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(221F3134-3EEE-412C-B89B-5DB769C6E74F)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(3991DE0D-7EAD-43C4-A632-4CFBA16A3571)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(48F87B38-AC63-4BFF-9141-FC8BC5F0214C)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(63F58D70-040D-417E-8FFF-C11D8489E79B)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(3DC60554-1C22-47FA-B166-320EABBBEF1F)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(FB575A8B-409D-4F73-92F6-98F04248496A)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(1934CACF-5FD8-4E80-AED5-A13E660E4FA8)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(35B8CEDB-8D64-4459-9030-E798E85B0C03)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(055F27D3-215D-4E6D-B188-C7C45880ED58)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6136B54B-630A-4CBD-B74F-3647C4FFFAE6)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6E2FF502-AAB2-4883-8D48-E8B902B12C83)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(77A02FBA-75A2-4ED3-8CC9-37FC9F93B24C)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6588F1DA-F364-4037-8874-56B231527A9E)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B74296A9-3B91-4944-8B36-0D3B58E8ADC5)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B9CF68D6-E725-4188-A0BE-5960720A20DF)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(9421CF6B-89B3-4CE9-83B2-B4A354A49DE9)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(2BB9ADEF-3410-452D-867A-BA6B4ACDD094)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(80B859CD-1C61-4A45-B567-AA04B356C3D8)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7059F830-CBD6-4EAE-AF0E-E3A05CAE1F58)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(A9D1E709-66DD-4CF2-B3EE-716357E524CB)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(1E535027-E9BC-4B54-A8F4-56465E265F57)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E4FBAAB8-7D36-47D2-BBF9-EE81F530B28A)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(39B07821-E279-47E0-8FD1-DBB067D4FDE9)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(F1DF576F-469A-49C9-974A-469A40644F5C)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B40E50F8-805D-4E89-9033-951141499091)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D90D6016-6584-4C1D-B077-A84117D6ED87)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(554F1F9D-168F-40F5-8CD0-4E840A089DEE)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C28196B5-9730-4469-B16B-6A6564FDD061)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(5BC47A01-5616-4FBA-93D9-31EE2880B394)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(8D2C6184-2FE5-4D88-BB28-AC2F27CB10F3)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(3188D62D-9FC3-4F7B-A50D-E8136EC336F1)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(BB1A8303-5609-42BE-985B-CC236FF6F2DF)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(45295F30-15E2-4A0F-B949-0F0E235A76D0)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D4F812C2-EC7C-4234-86EF-D2A5F14BE064)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(2B91F27A-95B9-47C2-BE1F-95A280A26F02)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D770FE1B-1D1E-4C22-B37D-40AADDA49A38)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(003F1700-9796-4ABA-B9CB-3060EC5189EC)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(51B8FBE5-3BE9-4D2A-9E7A-B7BCF948C51D)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B43FC87F-5BE9-43C2-8CF6-47D9EB86104B)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(704A1E17-B0D2-4283-9284-212256AE0828)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(FA76002D-E640-4709-A5A6-295D7721E6AB)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(A0EF01A7-45E2-417B-8113-C5CC76A4298D)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D7FF30CB-E0C9-45CB-8CA1-45514C582F46)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E347F7AD-EB9B-4340-B644-664BBAAD39E0)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(AA100BBB-86DA-46CC-A41D-673DEF41F878)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(F959970C-820B-44DA-8F7B-7ED165E5CD3A)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(3935E6E9-C8B0-4478-8259-E6B1BB635DE6)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(74EF42F7-9658-4344-BFDB-E1A155313A09)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(93614367-8EAD-4803-9A2A-0B218A25C67F)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6B75C555-B159-480C-B18A-75CE695126EC)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(AD01D946-7B92-49BF-BF72-A11FDBA65A3B)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(07FC5FFE-2003-4EAE-831C-45B0A77BF04F)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(48DC93AC-F9D2-4986-AFEE-A4E7FCCF217F)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(488C1DD8-7029-4590-AE22-32E9FBC884D0)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(23AD9106-2562-4D35-8AA2-3D6856BC8242)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(8E403AAD-5BE2-43B4-BC15-1432204386E7)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(AFA251E8-D9BB-4B07-B712-19E044B885C9)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(196341E9-C48A-4447-9E37-72EB33BF6E48)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B4FA9897-ECCA-4BDC-8B0C-F246FD3E0681)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(2798F573-5B4C-4C4B-A670-ACABC9FF46D5)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E6372C7C-46A9-4FE3-AD76-988EB1A965D5)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(EA543598-49C1-49A8-8C8B-78C3F56DDA89)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(F61A1C3E-39C4-4B1A-9423-F011BEACFC6F)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(FC5887B2-9977-4294-828F-1A8DF9C0E6D0)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(DFC0B2CB-6DB8-433F-A400-D85E6CF6165B)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(2A29D704-C892-425F-8231-EE9453862C37)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D205B8B4-2A16-48EB-9CE4-A28D29D5AF0C)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(5637978C-3B66-4F19-BC63-05C00E00D5C5)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(36E14961-9A1A-44A5-B71F-952D92F239D1)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(877FC388-2A5D-482C-833F-686D84EAD38C)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(ACC94AA4-1471-4E1D-8703-19BD09BA6A02)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(0D251D95-DFDF-4D5F-B854-4FE00C1C3134)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6AA3B413-64B2-4906-AA61-ACD048F6A0C0)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(DCB91937-89ED-4852-A2E6-DC4AA3C407B2)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(0C5EAFDF-B337-4F7D-AF24-D67F6CCD89FF)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(0AFAE36D-C9FE-499F-82A6-E23185A9ECAD)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(4D479C16-5E27-4278-BB34-7339E053C758)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(5D710EB0-6836-42EF-8D61-836AC13D3846)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(835317D5-9B5B-47F8-9C6A-0E378B249B2B)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(CD1F0ED1-CABF-41ED-A651-D302EC437E7C)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(3E78AA87-8D6C-4D68-926A-AC85176CF6B6)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6012E9BB-B6B4-4F62-9CCD-9AD41FAB4126)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(822E3104-B0A4-4579-8056-78F2172EB265)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B07E49C4-2903-4B7E-A77B-67B6D6A362A8)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(DBE12305-1C18-47C8-BEF6-3CF2B241F612)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(CB5CEA98-7A48-4BCB-814C-3F5B8BC03DFE)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7AB46E66-E0B6-4D14-A618-A2F51E09202A)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(DD8E4B18-CA0E-4C1A-A754-26761BA24E9D)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7E547648-E827-4CBA-BA43-D2A00D001E75)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(783FB433-18AE-41BD-A46D-250133B8DCE1)"].geometry
        }
        material={materials["Material_2.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(FD01EC1E-67A7-4093-9097-5AA7DA8972BE)"].geometry
        }
        material={materials["Material_2.003"]}
      />
    </group>
  );
}

useGLTF.preload("/Tepsi.glb");
