/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground26(props) {
  const { nodes, materials } = useGLTF("/ymt1.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet108(70B8EA15-B218-4C2A-ACA8-C73B1C45F5CF)"].geometry
        }
        material={materials["Material_1.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet108(2ECD31B8-5FEE-4551-B666-4067C21A731D)"].geometry
        }
        material={materials["Material_2.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(963B99AA-2876-4BC1-A46D-61F90EEA0E83)001"].geometry
        }
        material={materials["Material_0.004"]}
        position={[-1.3472, 1.2014, 4.3707]}
        rotation={[0.5487, 0.189, -3.0585]}
        scale={[0.001, 0.001, 0.0005]}
      />
    </group>
  );
}

useGLTF.preload("/ymt1.glb");
