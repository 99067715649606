/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground9(props) {
  const { nodes, materials } = useGLTF("/GondolTabak.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[6.4584, 1.2463, -4.3526]}>
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["Birle�tir10(4C7BD1CA-0DFF-415E-BA8C-BDFB3A4E9952)-mesh"]
              .geometry
          }
          material={materials["Material_0.013"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["Birle�tir10(4C7BD1CA-0DFF-415E-BA8C-BDFB3A4E9952)-mesh_1"]
              .geometry
          }
          material={materials["Material_1.012"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/GondolTabak.glb");
