/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground21(props) {
  const { nodes, materials } = useGLTF("/tulufincan.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Split_Line1(E67FFE1E-74A4-4B51-AA8A-96DCCAFF62C9)"].geometry
        }
        material={materials["Material_1.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Split_Line1(C3641DC8-0424-42B9-B3DF-D6DC8C0F035F)"].geometry
        }
        material={materials["Material_3.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Split_Line1(AE81CC54-7899-451C-8023-D5314C78A91D)"].geometry
        }
        material={materials["Material_3.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Split_Line1(AE40AECF-2A88-49DF-8E30-3CE72E7918B8)"].geometry
        }
        material={materials["Material_3.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Split_Line1(45806FCA-482C-4A9B-814F-F3328471B973)"].geometry
        }
        material={materials["Material_1.021"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Split_Line1(282D0E46-D4A7-41D7-8EC4-D0066A462E83)"].geometry
        }
        material={materials["Material_1.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(963B99AA-2876-4BC1-A46D-61F90EEA0E83)002"].geometry
        }
        material={materials["Material_4.001"]}
        position={[-5.4146, 1.1186, 3.8159]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={[0.001, 0.001, 0.0005]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["CirPattern1(60F9A503-9555-4C26-B61B-FEDF5E0C84A1)"].geometry
        }
        material={materials["Material_2.010"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["CirPattern1(5CA6318A-33F5-45BD-824A-EF9CBDE3E9F5)"].geometry
        }
        material={materials["Material_1.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["CirPattern1(2EABB9C3-27F7-42DF-AEC7-AA80EC10A4AE)"].geometry
        }
        material={materials["Material_3.002"]}
      />
    </group>
  );
}

useGLTF.preload("/tulufincan.glb");
