/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground2(props) {
  const { nodes, materials } = useGLTF("/CatalKasik.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Y�zSil4(E0C48E55-6C2C-4079-BA2D-9E8BA2AB01A9)"].geometry
        }
        material={materials["Material_2.006"]}
        position={[0.3612, 0, 0.6235]}
        rotation={[0, 0.092, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Y�zSil4(D5121E08-21C8-4CC4-AC36-C4F24D66344E)"].geometry
        }
        material={materials["Material_1.005"]}
        position={[0.3612, 0, 0.6235]}
        rotation={[0, 0.092, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Y�zSil4(C3BD654E-523F-449D-8DD2-C0C597AE6820)"].geometry
        }
        material={materials["Material_1.005"]}
        position={[0.3612, 0, 0.6235]}
        rotation={[0, 0.092, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Y�zSil4(71F9C4C4-8A5D-457A-8494-521EBDEA3CA7)"].geometry
        }
        material={materials["Material_1.005"]}
        position={[0.3612, 0, 0.6235]}
        rotation={[0, 0.092, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus33(759CA8BF-DCDE-47AF-9688-4A85A6F40941)"].geometry
        }
        material={materials["Material_2.006"]}
        position={[0.3612, 0, 0.6235]}
        rotation={[0, 0.092, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus33(280A44CB-DC59-4A3A-9088-61A4A5BFEDF3)"].geometry
        }
        material={materials["Material_1.005"]}
        position={[0.3612, 0, 0.6235]}
        rotation={[0, 0.092, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus33(05FAEB39-36DF-4582-ACFD-FD345296116D)"].geometry
        }
        material={materials["Material_1.005"]}
        position={[0.3612, 0, 0.6235]}
        rotation={[0, 0.092, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus2(F38AAE79-AED9-4627-B9D4-FFCEDEB003B4)"].geometry
        }
        material={materials["Material_1.005"]}
        position={[0.3612, 0, 0.6235]}
        rotation={[0, 0.092, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus2(AFF06B3B-70F0-4119-A41C-5236E3A40C59)"].geometry
        }
        material={materials["Material_1.005"]}
        position={[0.3612, 0, 0.6235]}
        rotation={[0, 0.092, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus2(69F66079-A962-47DA-8123-8FBDDF42239D)"].geometry
        }
        material={materials["Material_1.005"]}
        position={[0.3612, 0, 0.6235]}
        rotation={[0, 0.092, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus2(4D0932F8-1EB5-4674-80AF-6550C2279CB2)"].geometry
        }
        material={materials["Material_2.006"]}
        position={[0.3612, 0, 0.6235]}
        rotation={[0, 0.092, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(ECE96E59-0DF3-4D06-AA7E-7A7F052D041C)"].geometry
        }
        material={materials["Material_0.006"]}
        position={[0.3612, 0, 0.6235]}
        rotation={[0, 0.092, 0]}
      />
    </group>
  );
}

useGLTF.preload("/CatalKasik.glb");
