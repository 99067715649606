/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground8(props) {
  const { nodes, materials } = useGLTF("/Pecetelik.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(F0C0198D-CCC4-45B7-9E69-49A6E1D6FD60)"].geometry
        }
        material={materials["Material_1.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(EFE3AA5F-B659-490F-8601-12B085D2ACA5)"].geometry
        }
        material={materials["Material_1.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(ECECA846-C4D4-43C1-81F1-BF20CC886D47)"].geometry
        }
        material={materials["Material_1.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(DC83F303-462D-449B-BB65-6EEF656DA638)"].geometry
        }
        material={materials["Material_1.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(D429E3EB-823B-462A-ADFF-DF905F761E3D)"].geometry
        }
        material={materials["Material_1.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(BDC48D5A-71EA-43C1-B93D-E1255728D8F4)"].geometry
        }
        material={materials["Material_1.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(BC58AAEB-4259-4AC4-9661-81D28A34E697)"].geometry
        }
        material={materials["Material_1.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(ACE177E3-FCD1-4E63-931C-A23C275F6244)"].geometry
        }
        material={materials["Material_1.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(A96CE782-05C1-46CD-A794-878FA65A33D9)"].geometry
        }
        material={materials["Material_1.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(981E545A-1F8B-417B-90E0-AC83B9762704)"].geometry
        }
        material={materials["Material_1.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(90C294B5-8265-4445-BB00-71A75D1C07B0)"].geometry
        }
        material={materials["Material_1.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(8CB84923-6161-4CAF-ACB7-7E2012392616)"].geometry
        }
        material={materials["Material_1.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(86378323-0923-4FED-89EF-F09A51C49697)"].geometry
        }
        material={materials["Material_1.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(73412D09-3A54-401E-A076-8CFA1C068E4D)"].geometry
        }
        material={materials["Material_1.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(70ED4184-2655-47AF-BA0C-9D7E309F174D)"].geometry
        }
        material={materials["Material_1.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(561EEB07-9138-41A2-89EA-4E6B2157A012)"].geometry
        }
        material={materials["Material_0.015"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(440AF060-C465-42CC-95DD-7E4504EB4E90)"].geometry
        }
        material={materials["Material_1.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(13DF218E-80AB-4DDD-A964-220413E6D319)"].geometry
        }
        material={materials["Material_1.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus82(04BADF31-0363-4992-8CF6-5BF199504CD9)"].geometry
        }
        material={materials["Material_1.014"]}
      />
    </group>
  );
}

useGLTF.preload("/Pecetelik.glb");
