/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground18(props) {
  const { nodes, materials } = useGLTF("/PastaCayTabagi4.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["NONE(77B38BDE-5B4A-4363-8AC8-91EECF89045A)"].geometry}
        material={materials["Material_2.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["NONE(3EA78CB1-9FA1-4C03-86E4-A526420611C8)"].geometry}
        material={materials["Material_2.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["NONE(097F3677-65A7-4AB3-8E56-381F0EBE10AB)"].geometry}
        material={materials["Material_1.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(FF034D21-7E2C-4190-84A5-45B68A51A9A3)"].geometry
        }
        material={materials["Material_2.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(D3DFBFA6-518E-42BE-AED2-D91700651FC2)"].geometry
        }
        material={materials["Material_2.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(BF98DC17-ECE0-480B-A0B4-67BEE54D5DED)"].geometry
        }
        material={materials["Material_2.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(B8BD2CEF-F66C-4B5D-8099-B7CBE82E6A0D)"].geometry
        }
        material={materials["Material_2.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(B71897A1-3945-47B5-9E63-49C0ADC566D9)"].geometry
        }
        material={materials["Material_1.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(B3A62670-F9B3-48D0-A6BC-2554F6A29E76)"].geometry
        }
        material={materials["Material_1.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(8704CA57-FDDC-4B91-A758-DAD9707AD023)"].geometry
        }
        material={materials["Material_2.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(4416ED8D-C0E8-4253-85D8-9B04088F0684)"].geometry
        }
        material={materials["Material_2.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(403EFAEF-159F-486A-B1FD-E166FE6CB393)"].geometry
        }
        material={materials["Material_1.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet31(3744CBED-E743-4847-87DE-2366A831B2C2)"].geometry
        }
        material={materials["Material_2.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Default(388596BA-3FF4-42F1-9751-8B99461CC7F7)"].geometry
        }
        material={materials["Material_0.009"]}
      />
    </group>
  );
}

useGLTF.preload("/PastaCayTabagi4.glb");
