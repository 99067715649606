/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground4(props) {
  const { nodes, materials } = useGLTF("/AyakliTepsi.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(FFDF669A-F3FF-4BA8-945E-BAD945466D42)"].geometry
        }
        material={materials["Material_2.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(F383B548-AC4A-4A1E-B1FA-B94D69FBEB4F)"].geometry
        }
        material={materials["Material_1.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(F021499C-08F4-41E7-BE6F-8E841441BFA8)"].geometry
        }
        material={materials["Material_2.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(F0152A7A-CBD2-473B-88D0-597E0B9FD658)"].geometry
        }
        material={materials["Material_1.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(E26E19C5-B803-47CE-A2C2-E4FDC34DF6FE)"].geometry
        }
        material={materials["Material_1.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(DA02EB0C-6437-49E9-9837-39B9E154F7D3)"].geometry
        }
        material={materials["Material_2.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(C9999242-3A1D-4D30-97AC-813E33B74B03)"].geometry
        }
        material={materials["Material_1.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(C0807D36-9F14-46C0-93CC-0F8CDB2FC33B)"].geometry
        }
        material={materials["Material_2.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(BE96C43F-BE40-40C9-A89D-5CDF278AEB0A)"].geometry
        }
        material={materials["Material_2.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(B556781B-A400-410A-B6C0-CB624F7B8B37)"].geometry
        }
        material={materials["Material_1.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(B2388106-F219-44AA-8FFA-DD6A596F46A6)"].geometry
        }
        material={materials["Material_1.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(A67B3F78-2547-4780-884F-C84FA7D38DB7)"].geometry
        }
        material={materials["Material_1.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(A57BB94E-E7D5-4444-B5EB-537B00508054)"].geometry
        }
        material={materials["Material_2.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(9CA66720-BCA1-49A5-AA84-E1EDDC42495A)"].geometry
        }
        material={materials["Material_2.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(981A2F9B-7DC0-4777-A85B-2A42CDE23083)"].geometry
        }
        material={materials["Material_2.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(90AB68E2-4BAD-4498-B5CE-F11160080E49)"].geometry
        }
        material={materials["Material_1.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(8BBF8C51-97F7-4D64-9889-63E094BF4178)"].geometry
        }
        material={materials["Material_1.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(80E70EBD-17F2-44D8-9B32-E8D9A71C5CE0)"].geometry
        }
        material={materials["Material_1.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(7FE29FFC-B4A3-407E-B9DA-8443B8DBEBF5)"].geometry
        }
        material={materials["Material_2.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(6E17A953-33EF-4A12-ADB2-AD631F8F6577)"].geometry
        }
        material={materials["Material_1.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(655B5BE6-229B-43B9-9DCF-B187D76256A7)"].geometry
        }
        material={materials["Material_2.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(649AF3C9-2C90-4FBE-B597-CB3ED5658A05)"].geometry
        }
        material={materials["Material_2.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(63BAF4E8-DDBF-4025-9C8A-A90D84E7B80A)"].geometry
        }
        material={materials["Material_1.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(5D0927D4-B949-4F8C-9416-FCBD5D705020)"].geometry
        }
        material={materials["Material_1.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(52D4A9B1-ECA8-4917-9AF7-6C0D7BA061AD)"].geometry
        }
        material={materials["Material_1.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(4A08D9DB-F4AB-4A67-8A56-49BC61B3E307)"].geometry
        }
        material={materials["Material_1.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(38E54095-1751-49CA-B565-184A5F72C608)"].geometry
        }
        material={materials["Material_2.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(3365F4C8-7FC0-4B1C-98EE-C0F2F934DF1D)"].geometry
        }
        material={materials["Material_2.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(31FEF688-2457-4F94-A1AA-C10A4C939996)"].geometry
        }
        material={materials["Material_1.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(2F73CAC3-0513-44EF-87C6-98C08F88412C)"].geometry
        }
        material={materials["Material_2.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(24826729-85F8-4EE9-AA18-0AF5F71FE851)"].geometry
        }
        material={materials["Material_1.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(1F15937B-D023-4986-BDE5-99694F61D6B4)"].geometry
        }
        material={materials["Material_2.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(1C6A6BB5-4AB9-4C8A-A1D9-B99491E233D1)"].geometry
        }
        material={materials["Material_2.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(0FA76A2B-E57C-43BE-9239-1C3A45E9636F)"].geometry
        }
        material={materials["Material_2.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir32(0480DE2E-342D-4442-9661-9ED845ED8DEA)"].geometry
        }
        material={materials["Material_2.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Layer_02(C33B5DB9-E938-4913-AD99-F43F36BDD715)-mesh001"]
            .geometry
        }
        material={materials["Material_0.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Layer_02(C33B5DB9-E938-4913-AD99-F43F36BDD715)-mesh001_1"]
            .geometry
        }
        material={materials["Material_2.013"]}
      />
    </group>
  );
}

useGLTF.preload("/AyakliTepsi.glb");
