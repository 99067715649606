/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground6(props) {
  const { nodes, materials } = useGLTF("/KekStandi.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Radyus5(F42225C3-D367-457D-AA6C-FE4472F0D406)"].geometry
        }
        material={materials["Material_0.012"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(FFB8B0BE-F4D0-46D9-88BF-047A975222AF)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(FF8018E7-E6AE-4867-9020-05806CE628D8)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(FD25C836-5B28-4D04-949A-948E67258021)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(FB6A4CC4-4B79-46D1-8410-87066BF37CD9)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(FAB7461C-8682-4E49-8E7F-0928B60DE625)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(F88CF69D-5B1D-4D0D-BC80-7CD7DC7E9A52)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(F87FC04E-B85B-47B9-8E29-D0F401C66BC5)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(F7A650BD-23CB-430C-AB1B-114DB4DCF831)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(F60FA288-85A1-492F-8468-DFBDB697AC62)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(F60430B6-9AED-4175-A279-F886DAF8A067)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(F402A69C-E2E1-4832-B7B4-B1DE7488B519)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(F3B9DA30-F0A3-47B5-80BD-BB509D43EB8F)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(F3A80ABA-4E79-4CC2-8271-801C89995419)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(F38E544D-3CEA-40D7-A9F9-E8E931CD26B3)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(EE2893E5-C044-4C43-ADFE-B9230A61C581)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(EC7D1A50-99CB-4809-8B7A-231D20DB102B)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(EC7969B6-1BF6-4965-BD3E-0D39E33FC37C)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(EB5F77D8-B337-423D-8F2F-C98464DB86FA)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(E7C00C81-3A32-41A2-BD48-DE4AB0E808AB)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(E5935D09-9B7E-49DF-8739-E7CAE4D74D19)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(E4E00AE2-B23F-435F-9A10-CF814D36FB3C)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(E4CB6703-9707-425F-908B-6FFC0FB42396)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(E3C4577B-4E1D-49A4-8FCD-3888087F9F2F)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(E025FD7F-7316-4D71-8D03-AFD0F29BAFC4)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(DAF2DCE2-F4CA-4667-B44E-7279D296C8EF)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(DADEFA4B-B76B-4125-845D-67A4BF64E538)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(D9F83F3E-60D6-4F3F-A1D3-F24CA752AF0F)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(D808DE60-A70E-47BB-A89E-8D60F922AFB3)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(D7739166-C89E-4EB9-9E2F-669FCB048574)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(D3BB8353-AD1A-46DF-AD34-E704B7876399)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(D3ACAE65-59F7-4779-8DA8-99ADD1A7684B)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(CD8189A6-D169-4D44-B0CF-C77FD8BEC51B)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(CA3FDF0A-817C-4E90-B425-59CF9A16D494)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(C9508CB7-032B-43AD-8CF8-1A0CFDDA53F6)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(C93A56E7-3A83-4EAB-B6A8-7F5F110F0A03)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(C70DE581-8257-4AAB-BEC8-07760665D9BA)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(C5DDAC5B-27D7-4087-8FE1-F2831A89DBD1)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(C2A52C32-8607-4A20-AE62-2156B7DDC6B3)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(BD3A1FCC-CB0E-49E6-B04E-BB5D54976057)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(BD0491E8-7166-40D2-B851-FB23B9997BF6)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(BC0B46BB-ECEF-40F7-9E5B-A2A396211BDD)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B82E6C7E-1517-483E-A3F2-08B9D5289EF2)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B741C77B-1ED6-4F26-9D35-8F167DB1DD3C)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B67540DB-2880-4784-873A-3382553BDFD4)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B6050740-1335-4309-992E-5240D19CAD63)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B447E590-E4D6-4DEE-BB64-18CA7E83E6E5)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B1C1A436-4B07-445A-8F5F-DEFAFAE4149C)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B10478BB-44A3-4698-996B-50BFCB9EBBB8)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B0FEF356-59F8-4ADC-B05D-6279F1378338)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B06C1947-92D4-4725-890E-9DC09226744C)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(B00A4398-F351-4E88-A28E-EF5C73E563E1)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(AE65DDF3-1DD0-42AD-A213-62C844634574)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(AE07D9D3-6FCB-4138-80A9-0DF4C5D03019)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(AD502295-F7D4-41E7-AFA4-B90202E313DD)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(AB8AE811-C496-4C98-9038-FA183DA696F3)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(AB894669-2D4A-4AE9-8CA3-2784C2E5B341)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(A7D6CFBD-51D1-4277-80D3-FE2D2774452C)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(A617CC74-6E5D-4AF5-88FF-86B1849C0820)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(A541C931-68EA-4E46-999D-550A617EC294)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(A213B773-749E-4685-8F36-FEB68AB0E8FD)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(A08A9EAC-C0BA-4E3D-881F-6197213317CC)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(A058D275-E3FA-4518-B854-FD826FA1F8DD)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(9BA9161A-9372-48F2-967B-79D6A8D7C2E0)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(996FD289-D0A1-4EE3-B89E-D29E223D7546)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(990543CC-4B10-457B-93B3-34D2A9EDD5DE)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(968CE753-F8C1-40D1-A914-5BBF0B5AA063)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(92C9202B-5DBB-49E8-B275-1491A10A1CFD)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(90B93271-F514-4B8A-82D2-D83012D59DA1)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(9008A3E9-B9A1-4338-92D0-726638A223A6)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(89CA7D12-CF7C-43F5-9B29-EDB71DC06912)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(8966FB2E-3BF7-412C-A256-41EE87EE686C)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(88A38DA9-C3CA-419E-B905-B98626983309)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(87C22762-9E01-4C45-90F6-490959ED968F)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(7C0B0D9E-9066-4A65-A547-12B2EB847453)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(79F04002-1221-4E10-83B2-4A5CB9A2DCB8)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(77D10E3B-B847-43C6-B144-B1925B7918A4)"]
            .geometry
        }
        material={materials["Material_0.012"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(76D85474-F521-4C27-941D-94E4F3519F33)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(76169719-C74A-4CAB-8D5C-9339F746AA95)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(76088D9B-CB47-470C-852F-B65D56DF0130)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(74E6E6C9-F28C-45DA-A448-4AA2BB1E609C)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(725931F7-1256-414A-A8EA-17B54E63FC22)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(725058D9-D6AD-4C6D-9996-751D5C64A6BA)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(6EF06707-C28D-47A7-AFA7-E63FACDD9F27)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(6D05F382-F43D-4A39-A9F0-1C80838BD655)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(6BB9BA05-1C02-4EFD-97AA-DADE44FFCCFF)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(69DDBA15-9797-45D1-926B-C9094A62A9BA)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(681DA72C-1499-4D49-9854-618D7684ED89)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(67E68F5A-1F0A-4226-8D02-96602F540C7C)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(67E641AD-3F8A-4905-BB40-672B93662A87)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(6758ACA4-E201-47E0-9FD3-A2438F671D3E)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(65C1CA6B-F5BE-4F9E-B89C-86C4B73590D2)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(65BCCB28-9BEB-475F-8FFF-9744A2D1BE91)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(6547E8A5-563D-41B6-8577-E317BA0196F3)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(647EC6D9-3F48-4CB2-AFE1-9702F09D0A5F)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(61BBC253-BED2-428A-818E-4256520DAC36)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(60E3FA2F-DCFB-4E9F-8EDD-BFE8684EEC16)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(6063F898-CB56-49E0-B19E-343706F9344D)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(5DC72CAA-489A-4642-880D-5E8C8830FADE)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(5D442929-C545-4E05-98FF-7F47D975838F)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(5B0FA5A9-48F3-4A6B-AEDE-CEFDACE1B567)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(59621F86-B69A-4178-A5FC-A915FDF4C953)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(558A3240-D9F0-4147-8429-6450AD2346FF)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(5471E68C-D899-4BC8-AE31-F32CD3AAD42C)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(5342275B-A5AA-4D42-A9F1-B9A84955E975)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(508BDF56-867D-4570-A8C3-322A277DCDF5)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(4DD7F6D7-6E25-4754-9C30-B90B0ED5111D)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(4DD294B0-CAFF-4B37-B81D-0E1BD0694F8C)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(4CB8287C-7C55-4685-A6AE-F5EA3E2C6592)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(4C841EB3-3400-4240-B2AD-6F49179BA36F)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(4909EAC3-8AC1-4A2F-AADC-2CA06ABA62A4)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(4651445D-26FE-4B9A-A549-DCC647A0476D)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(45753CB5-BBCD-47FF-8767-0253D59A03E6)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(43206BA9-7FCB-4EA5-BF03-8F964BD25BDF)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(42AF01DE-AFA5-42EF-BCA7-4EF7D409113F)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(426AD4D9-267A-4D6A-BE4A-53AF89654E89)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(414D6FD4-3CD6-4EFD-BB02-15B8FCBF0E5F)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(4092CC71-82E5-4B12-B8AA-6BBCD080FA9A)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(3EE3FFFF-4CDA-4165-BD1F-98DCD91D8936)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(3E4FA5A5-78CD-445F-8405-1E16F1343AD3)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(3C6E0F43-6D57-499F-89B6-281B02802AF8)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(3C002042-3737-46AA-969D-BE52D572CEA0)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(3947CDD7-CAE5-4DEC-BA36-6D022FEE84F3)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(35C3F65D-E124-4B34-B9D2-796CB55D410A)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(34638BCD-D786-4B2F-BD92-D7093DDE08D0)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(34264DA1-ABDF-48AF-A197-3055CFB312C0)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(338AB265-4323-4F8D-AA40-915F7A8B79C0)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(32A0101C-8AF3-4363-B821-A770C9BA8E56)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(3280376B-7E92-4113-9FB6-8C1BA6800C0C)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(2FD336CC-9736-4F43-B2B4-43909692FA5F)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(2B1C95B6-52F2-4FBF-864C-E7CACEC41665)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(2AB990AB-D231-484F-94D6-9BE5D0207B69)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(29BF5612-0DC2-46DD-87D9-5F26FDE57BE7)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(27FF319A-0080-4A58-B4D8-1FB3BE4336B1)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(271996D3-4EF5-4E6E-BC45-BA47CBE341D4)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(26F1C36E-57BB-41E6-AF81-C41873C1BB80)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(24F69D76-B0EF-4D76-AB07-3608D7D88FE9)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(24B11500-A6B7-408D-AEB2-CBCA97F68C62)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(22F41B5D-6DDC-43C4-B4A4-495FE7679424)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(210CB983-2152-4F3E-8E1B-61D435843B95)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(20B10967-D739-44F3-91A5-AF11D004D811)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(1EE75A1F-1849-4A6C-920C-0B39F030F4AF)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(17D58EE0-C960-46A6-ABFD-987E31BBB8A3)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(1764F9DD-BD6F-4C9C-A3AA-361E476AE5C7)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(15DD86EB-13E3-4C80-B133-EF9C4382B819)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(156D3C9F-C888-4DB8-8692-5E1965E6583C)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(13F7D5C6-F375-4D1E-A4DA-C4636AE02409)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(12E60577-9644-406F-9B31-364BB9E212D6)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(1183F193-331E-4E28-BB6B-C895A5C8A5A1)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(118197F2-F2EF-45BE-B49B-B207E7A808BD)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(0E6586FF-C2DD-401E-B4B4-F03AA179A06A)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(08A8F88F-4E0D-4220-9E99-2C4CC497605E)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(080954F3-0AF0-4D5B-B683-F203DCC3A317)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(068B2D51-1162-45FC-9C6F-DBA9230D016B)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(0514FA7C-D1E2-4216-9070-CD9F24191911)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(04230616-55FC-47C3-9AC2-F45146A029D9)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(02449AA7-079F-46AC-BF01-93464AC19C87)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["G�vde-Ta��Kopyala7(00FE2B44-FF49-4B76-9C87-614FB81DA1F2)"]
            .geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["D�nd�r2(209AB329-14EA-46BC-8BC2-8A7F3A08F082)"].geometry
        }
        material={materials["Material_0.012"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["D�nd�r1(40EA021B-6036-426C-A80F-2A53397C08D1)"].geometry
        }
        material={materials["Material_0.012"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(FE8EEEFF-5F9E-4762-959A-4B3EAC303FB6)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(FE2E5ECB-A395-40C8-84F4-3B0A385B9CCC)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(FCD8CE3F-27D9-440F-BB67-9F6B4DAEC49E)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(FC281527-79B5-431B-8464-9CB3873F7069)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(FBAEC10A-F3AA-4E26-B514-789B185AE463)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(F9F9864E-3244-4A1F-B275-8BFEDDA0832E)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(F9A0C037-0E69-4529-B8B5-6C58AE53AEE1)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(F6039A41-9892-4E20-B2D2-801008BEDD34)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(F197D9EF-00FE-4DA2-83D2-72047EBF1B77)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(F0CF75B6-3062-468E-9594-DF016CF5D095)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(EFCA813F-EAE6-4BB6-A755-81D06884D8B7)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(EEE9771B-F3E3-4A81-8257-3C6FDF9C31EB)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(ED88880A-7BBC-4B85-9155-2B0B82B9161B)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(ED31ED1F-890B-4578-8C3B-AA7A5C54F57E)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(ED2FEBC3-240A-4206-9110-038138CF189F)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(EC7DCE7C-417D-4894-9813-791E2F42FAE2)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(EC33DA92-29D3-42C5-94F4-D8CE7A82BB61)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(EA87755E-42C7-427F-9A72-0C518BEF2269)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E9CB35D0-1798-4F04-968B-F5E05A9812E1)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E8D807E4-0D0F-4B04-8787-1730DEC705A8)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E5E28EAE-F815-4A1D-A054-F56BB2A398A4)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E2E39C30-2708-4F5B-8EDE-2319516BF017)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E0F4EBA2-D0F5-45B4-8108-F1D5E8CB088C)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E0A4BAB9-D5D6-47AA-8B10-AF6B0B6B9C48)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(E02132F5-3526-4336-A42B-AA74B7AA8A9E)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(DFBE3A88-3569-4C0D-9456-75C9C029FCBA)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(DF0FEDAF-8C15-44A6-B6FC-340281293C11)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(DD5EB998-C304-4B1B-BC18-D52436B5728A)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(DB4DB503-1FCB-4EFA-9503-EB26C89E08D9)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(DAF636D6-635F-46E3-AB4B-2A527EB94470)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D498B9D4-0956-4A02-8DC9-C974AD3D1C5B)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D4403DB6-A8A4-43F4-B37C-A720ECE44CB1)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(D0416C46-8FDD-4FCE-A12D-2A632A8B4A0D)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(CDCA10C2-DC9B-4A7E-934B-3B83E319D965)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(CDC51892-080D-4CE4-83AF-1F8F77318C6E)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(CBD1EFC1-A837-49EA-A0F9-B5C574497894)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(CA68650E-83D3-4550-9240-0F8DB99D33BF)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C824F660-63A6-4278-A762-B340EB1CD3CE)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C7C8E8E2-8B28-4466-B1D9-7C064403A732)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C6F9A7CE-89B3-440B-BCC5-29939A9DB19D)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C6CEDE12-ABB5-40D9-961C-E5C36DAD9480)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C6AF202C-793F-410C-8131-415168268203)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C65AA5EF-48C5-4D5C-9850-64E212107A8B)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C594665A-7CC7-4A05-8C87-1BD5277978E5)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C57B50A8-08C3-49A0-A27D-10484A815F05)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C4BF2B74-6BCE-4499-9B43-0B89018E5AF9)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(C4A4B397-2691-40B9-B463-10C439357ED8)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(BE509062-AFBF-4874-A2A2-29592B3F7D54)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(BBDC13D4-D3FA-4D44-830F-AD329CFC1248)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(BB3769FF-A807-4E7E-AFFE-21E26A3A2229)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B63692B4-28CF-415F-BD51-161EEBCF55BA)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B3BE53F7-CFEE-4095-9C2E-9B2108B4D2F7)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B23628E8-62E4-4130-BAB1-E3476FBAB5AA)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B1374676-C96B-4DBE-B054-514C0E9B45B6)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(B0771807-DA60-41EF-9ADA-D36C7DAE2797)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(AF93B00D-14DA-4F1C-BC8C-A1C447A3511C)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(ADEB5637-E080-40C3-AD1F-ECCB74C6792B)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(AC8BA4EB-E868-4FB8-B8A1-12E46267FD04)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(AC3D0365-16F3-4695-BE08-6E26AE00470B)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(AB1B94D3-4762-47F1-86B4-0EC382F1C7CC)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(A8184014-465E-4115-B5BE-6FC57D826DD9)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(A617EC08-81F8-4990-9637-106DC26D91D0)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(A58F117A-4659-4ADD-95D0-14DD44D14D6B)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(9DE599E1-7CFA-40E0-AB64-9C934A259A3B)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(9DC05779-9D0B-4C85-B5C8-EF639DB2FA63)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(9CDCF3A6-8814-4D10-93A1-41E6776A44DA)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(9825F525-CECB-4365-A7F5-C846D777A397)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(97E6BB8A-472D-4B98-95DF-A6B15C6E7E7F)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(97A2C239-968D-4ADE-B08D-947E40D5E94E)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(9788064C-198D-4149-9843-1283FEBE38FF)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(96DE0CFA-CE18-4E6D-81C6-A743D1D17F5D)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(96063551-5B46-43A3-A29F-1B5E0F95A272)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(93CC825E-B255-4EB2-A37B-3CF334A0A61B)"].geometry
        }
        material={materials["Material_0.012"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(92D38805-7F35-4C1D-BB56-8437537B0AC8)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(905814B3-5251-47AA-924C-4E8AEA910A26)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(90018D32-F770-4E92-9331-703CA7855466)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(8AD57CE5-6FD9-430C-9880-EFD5A4CB304E)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(884BC440-FB38-4FDF-A368-4DBE87D5F5BB)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(85690BD2-2D8A-4B6F-9C30-786BA165C7D3)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(80FA77A4-729A-4904-BA5F-40D55E0AB658)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7FB295FB-0B6C-452E-9262-66B0DDAE6095)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7F560C20-E700-44C7-A8E3-7E25D2E78DE7)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7F4FF1BA-9767-44FF-8313-357CEC5044B4)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7F1957FA-1B0C-41D0-8F35-49AD2CE57CFA)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7EA8E6C5-0C82-48E3-9964-D64E736720EB)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7C873E96-9ADA-4D59-B258-C1131E6F7FEE)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7ACF37D7-22EE-488E-AF3D-BA7B85C55AA6)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7A60620E-B5BF-4438-89EE-EE01DB6E245C)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7A600864-4027-4CD8-88A3-ED6A093D7661)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7847B453-AAB7-49FE-9328-4FE9324BB7CE)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(75E274C3-1562-4BB7-9EEA-C55349FDC8F3)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(710CF3F5-2C5E-425D-84A1-7E160D704AE7)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(7018FA15-C988-4A91-9EA2-1DE446EDB2D7)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6EBBDACA-EAAB-4248-81B7-D86962AC24FD)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6E1703CD-6A0B-42CD-8827-1ED999A6835F)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6DDEF424-223A-4ED1-BD37-0FD1E75B239F)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(67DAC46A-02FA-4DDE-8347-B796255B81BF)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(654805AF-C405-4DC5-A289-F9014C2125B1)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(64873429-C654-4098-BD0C-2C85B681DA53)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(636C987C-D0B0-418D-B74F-D341768C2F93)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(6305F1F5-94E0-4202-ACAF-ACF5B4F21F96)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(617DB0EF-DC65-4546-B89F-A9760FC9432F)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(608EE3F7-0753-45F2-931B-86DCB52BB118)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(5CBD0813-A9DD-440E-998A-5D88F26AE388)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(5A7912C9-A677-485F-BD34-1B35FE63C68B)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(58F50368-D813-40DC-A254-E2A1713DD128)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(58038A52-9F08-4AE3-96AD-522AB260A3BA)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(57E36721-3170-41DA-A749-9130A7DF604D)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(56F2BD32-51CB-4321-94D0-EF55726A3494)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(5603B455-0BA9-4FEE-BCA6-E6AD7D0CB01F)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(53072285-B534-4CDB-B861-CC44BFA12974)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(529DE5C1-C02E-44D9-8B04-56A874932EFC)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(51E5DAC6-2091-4D96-BF08-629148B24A40)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(51CACBF9-56AA-4847-B7FE-F8F7DEBC4DFB)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(511F9212-50E4-4F67-9D32-216008D346E4)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(48EFF6DC-1E3F-4630-9260-978DFC0CA165)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(48C2A74F-93BA-481A-9C75-165DBE69E575)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(481F2094-193D-4F5C-A0F9-26A0357A6B4F)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(477D4999-6C03-47EA-A401-D72BF69C8AEE)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(454958E9-F961-4B99-A1A3-5C77410061CE)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(45007197-F8AB-4F65-9A18-FE2F8A92820A)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(4473AAD9-870D-4F1B-BDA2-E1F27878E14E)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(43287E64-0952-4C09-BECD-E2F671ECA96F)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(41F3A411-1AD5-401C-A2B3-15EA1B5EFA53)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(407E7FFE-CF2E-4A59-85BD-CF60D0491619)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(3F08D7D3-D964-4EC1-AFFD-F3F074703907)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(3E93FDC2-12E7-49CD-AC48-BC4CE00811BB)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(3E443E09-EAED-4891-89DD-5366CECE38A9)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(3AC8C3EC-CE5D-456C-963D-3B3EACCF62F0)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(39383EC6-2051-4A3F-B179-77C91D7CA443)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(38C06B96-A9B0-4117-9218-CE1CD62B1DB4)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(36B941B6-DA8A-48A8-8439-2FBCDA8A0D06)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(358B1457-BDC8-479C-A651-12CE2100F470)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(348B58EE-AD39-45F7-A312-6D928154F7B6)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(347DA58B-561A-4787-A195-F15561299F25)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(2A6DC66E-2DF6-45D3-8715-AD091CF7158B)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(2A60CE94-DB75-4882-9230-5AA806D0F2BC)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(25993D90-E7BC-4D7A-996B-90F78231653F)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(250FD97B-245D-4436-9373-3462C6AE0B52)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(1FFAB004-2CED-4450-B713-688B5B24F003)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(1D644BC6-83B5-4098-B999-FF99876FCC89)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(1C6B61DC-1143-4476-9DC3-4D5A3A629B8D)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(1B6B5984-618A-46C8-9853-C05289B91564)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(199BA0F1-1A29-4F43-853F-DFCD989C8957)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(199062D5-2135-4951-A240-039086304CF3)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(18CF5D8F-218A-4052-8A52-E2E456FFB689)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(17FD5804-DB07-44ED-ABC8-4F36055DDC64)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(144E061E-1BF2-459D-A750-0344AD0B1EEE)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(12FB1BDD-E8C3-49EA-8E66-D2E9D295EB12)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(11D38F1A-BE25-4491-967F-A7291814540B)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(10633D66-5BA7-4258-AC0E-B62EE976B744)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(0DC72141-0673-4F4B-80DC-19B727764909)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(0C09AF94-CD0E-4D8B-90D6-3CC5F86D280C)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(0ADEDA52-540E-48A1-B617-4340E360C762)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(0690C294-1C73-41C1-B78E-3ABC8809E86D)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(031CF3B9-7486-43FB-AB9D-B1A61FBD1F94)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Birle�tir1(01D0218D-91E6-4CBE-B8DD-7E232CC08E8C)"].geometry
        }
        material={materials["Material_1.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["Ay�r3(86A3B0D0-0C78-4ED3-A59E-5AEDA9E5ED4C)"].geometry}
        material={materials["Material_0.012"]}
      />
    </group>
  );
}

useGLTF.preload("/KekStandi.glb");
