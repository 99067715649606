/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Ground25(props) {
  const { nodes, materials } = useGLTF("/ymt2.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet32(8EDA3EBC-3556-4DCC-A515-1D177D90B7A5)"].geometry
        }
        material={materials["Material_2.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet32(5DA8F710-6F4E-4D79-9309-96A3B16930E4)"].geometry
        }
        material={materials["Material_1.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet32(0E669E41-2323-418C-9112-321E6B9D7BC7)"].geometry
        }
        material={materials["Material_1.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["Fillet32(09765657-2F14-4D43-A058-1B1F09E28716)"].geometry
        }
        material={materials["Material_2.004"]}
      />
    </group>
  );
}

useGLTF.preload("/ymt2.glb");
